<template>
  <div id="category-initial">
    <div class="test-cat" id="contenedor-categoria">
      <div
        :class="{ active: active == cat._id, disabled: cat.isActive == 0 }"
        :key="'catBas#' + cat.name + '-' + index"
        @click="cat.isActive == 0 ? false : $emit('selectCat', cat._id)"

        class="cat"
        v-for="(cat, index) in list"
        id="initial-category"
      >
        <div
          :class="{'filter-brightness': (getVendor.opacityButtons && getVendor.opacityButtons === 1 ) }"
          :style="{
          'background-image': 'url(' + cat.image + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
          class="logo"
        >
          <div :class="{'no-image': cat.image === undefined}" v-if="cat.image === undefined">
            <p
              :class="[cat.image && 'white']"
              class="text bold center size-14 ellipsis-cat"
              style="width:max-content; filter: opacity(1);"
            >{{ cat.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: ['categories', 'active'],
  data () {
    return {
      list: []
    };
  },
  mounted () {
    const order = _.orderBy(this.categories, 'order', 'asc');
    const filter = _.filter(order, (o) => {
      return !o.parentId;
    });
    this.list = filter;
  },
  methods: {
    scrolling (dir) {
      if (dir === 'left') {
        let content = document.getElementById('contenedor-categoria')
          .scrollLeft;
        const cont = content;
        const move = 200;
        content = cont - move;
        document.getElementById('contenedor-categoria').scrollLeft = content;
      } else {
        let content = document.getElementById('contenedor-categoria')
          .scrollLeft;
        const cont = content;
        const move = 200;
        content = cont + move;
        document.getElementById('contenedor-categoria').scrollLeft = content;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getVendor'
    ])
  },
  watch: {
    categories () {
      const order = _.orderBy(this.categories, 'order', 'asc');
      const filter = _.filter(order, (o) => {
        return !o.parentId;
      });
      this.list = filter;
    }
  }
};
</script>
<style lang="scss">
#category-initial {
  .filter-brightness {
    // filter: brightness(50%);
  }
  position: relative;
  width: 100%;
  padding-top: 15px;
  .test-cat {
    display: flex;
    gap: 1rem;
    padding: 10px;
    position: relative;
    overflow: auto;
    flex-direction: column;
    // scroll-behavior: smooth;
    animation: fadeSlow 1s;
    &.center {
      justify-content: center;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    .cat {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: pointer;
      position: relative;
      filter: opacity(1);
      &.active {
        // .text{
        //     color: var(--buttonBackground);
        // }
        // .logo{
        //     box-shadow: 0px 2px 6px #0000009C;
        //     border: 3.5px solid #000;
        // }
        .margin {
          animation: fadeSlow 0.1s;
          position: absolute;
          width: 115px;
          height: 115px;
          border-radius: 100%;
          box-shadow: 0px 2px 6px #0000009c;
          border: 3.5px solid #000;
        }
      }
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      .logo {
        display: flex;
        width: 98%;
        height: 140px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        #icon-rest {
          height: 30px;
          width: 30px;
        }
        .no-image {
          display: flex;
          width: 100%;
          height: 30px;
          align-self: flex-end;
          justify-content: center;
          align-items: center;
          background: var(--buttonBackground);
          border-radius: 0 0px 10px 10px;
          p {
            color: var(--buttonTextColor);
          }
        }
      }
    }
  }
}
</style>
