<template>
    <div class="mode-carta">
    <b-message id="messDelete" :active.sync="messDelete">
      <div class="cont-info">
      <span class="text bold size-20" style="display: flex;align-items: center;"><div class="x-red"><i id="red-x" class="fas fa-times"></i></div> Producto eliminado</span>
      <span class="text size-15">Se eliminó {{messDeletePro.name}} al pedido</span>
       </div>
    </b-message>
    <b-message id="messUpdate" :active.sync="messUpdate">
      <div class="cont-info">
      <span class="text bold size-20" style="display: flex;align-items: center;"> Producto actualizado</span>
      <span class="text size-15">Se actualizó {{messUpdatePro.name}} al pedido</span>
       </div>
    </b-message>
      <div class="categorias">
        <span class="text size-21 margin-right-10 segoeBold bold">Categorías</span>
        <b-select class="select-carta" placeholder="Categorias" icon-pack="fas" v-model="categoryPosition" @input="scrollPosition(categoryPosition)">
          <option value="null"> Seleccionar</option>
            <option v-for="(cat,catId) in cate" :value="'category '+cat.name" :key="'cat_' + catId">{{cat.name}}</option>
            <optgroup v-for="(cat,catIdg) in cateSub" :key="'catGroup_' + catIdg" :label="cat.name">
              <option v-for="(catSub,catId) in cat.subCategories" :key="'optionGroup_' + catId" :value="'subCat_'+ cat.name +'_'+ catSub.name">{{catSub.name}}</option>
            </optgroup>
        </b-select>
      </div>
      <div class="list-products">
        <div class="products">
          <div class="categoryCont" :id="'category ' + h" v-for="(produc,h) in products"  :key="'cat_'+ h">
            <div class="categorySimple" v-if="isSub(produc)" >
              <span class="text bold segoeBold size-24 category-title margin-bot-10 ">{{h}}</span>
              <div class="cont-products">
                <div class="producto" v-for=" (prod, i) in produc" :key="'pro_'+ prod._id + '_' + i">
                  <div class="info-prod">
                      <span class="text bold margin-bot-5 ellipse-pro size-18 segoebold" style="text-transform: uppercase;" :class="{'noImagePromo': prod.image == undefined}">
                        {{prod.name}}
                        <img src="@/assets/icons/2x1.svg" class="img-deal-title" v-show="isDeals(prod.deal, '2x1') && prod.image == undefined" >
                        <img src="@/assets/icons/3x2.svg" class="img-deal-title" v-show="isDeals(prod.deal, '3x2') && prod.image == undefined"  width="30" height="10">
                      </span>
                      <span class="text margin-bot-5 ellipse-pro small" :class="{'noImage': prod.image == undefined}">{{prod.description}}</span>
                      <div class="pricesCont margin-bot-10" :class="{'noImage': prod.image == undefined}">
                      <div class="price-adjust">
                        <p class="text semi-bold priceComplete">{{ getPrice(prod) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
                        <p v-if="validateDescount(prod)" class="text semi-bold discountText">{{ prod.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
                        <p v-if="validateDescount(prod)" class="text semi-bold discountPercent">-{{prod.percentOff}}%</p>
                      </div>
                      <div v-show="prod.image == undefined && productInCar(prod)" class="btn-add" @click="$emit('showItemModal', prod)" style="margin-right: -17px;">
                        <span class="text semi-bold size-20 margin-right-10">Agregar</span>
                        <i style="background: var(--buttonBackground);" class="icon-mks icon_add_menu"></i>
                      </div>
                        <div class="btn-minus-plus" v-show=" prod.image == undefined && !productInCar(prod)">
                          <span id="minus" v-show="productQuantity(prod) == 1" @click="deleteProductCart(prod._id, i, prod)"><i id="icon-menos"  class="fa fa-minus"></i></span>
                          <span id="minus" v-show="productQuantity(prod) > 1" @click="updateCart(prod, 'min', i)"><i id="icon-menos" class="fa fa-minus"></i></span>
                          <span id="quantity" class="text semi-bold size-18">{{ productQuantity(prod)}}</span>
                          <span id="plus" @click="updateCart(prod, 'max', i)"><i id="icon-mas" class="fa fa-plus"></i></span>
                        </div>
                      </div>
                  </div>
                  <div class="img-add" v-if="prod.image !== undefined">
                    <div style="position:relative">
                    <img src="@/assets/icons/2x1.svg" class="img-deal" v-show="isDeals(prod.deal, '2x1')"  width="20" height="10">
                    <img src="@/assets/icons/3x2.svg" class="img-deal" v-show="isDeals(prod.deal, '3x2')"  width="30" height="10">
                    <img class="img-product" :src="prod.image">
                    </div>
                    <div class="btn-add" v-show="productInCar(prod)" @click="$emit('showItemModal', prod)"><span class="text semi-bold size-20 margin-right-10">Agregar</span> <i style="background: var(--buttonBackground);" class="icon-mks icon_add_menu"></i></div>
                      <div class="btn-minus-plus" v-show="prod.image !== undefined && !productInCar(prod)">
                        <span id="minus" v-show="productQuantity(prod) == 1" @click="deleteProductCart(prod._id, i, prod)"><i id="icon-menos"  class="fa fa-minus"></i></span>
                        <span id="minus" v-show="productQuantity(prod) > 1" @click="updateCart(prod, 'min', i)"><i id="icon-menos" class="fa fa-minus"></i></span>
                        <span id="quantity" class="text semi-bold size-18">{{ productQuantity(prod)}}</span>
                        <span id="plus" @click="updateCart(prod, 'max', i)"><i id="icon-mas" class="fa fa-plus"></i></span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="categorySimple" v-else>
              <span class="text bold segoe size-24 margin-bot-10 category-title noBorder ">{{h}}</span>
              <div class="categorySimple" :id="'subCat_'+ h +'_'+ id" v-for="(subCat,id) in produc" :key="'subcat_'+id">
                  <span class="text segoe size-20 margin-bot-10 category-title-sub ">{{id}}</span>
              <div class="cont-products">
                <div class="producto noBorderLast" v-for=" (prod, i) in subCat" :key="'proSub_'+ prod._id + '_' + i">
                  <div class="info-prod">
                      <span class="text margin-bot-5 ellipse-pro size-19 segoeBold bold" style="text-transform: uppercase;" :class="{'noImagePromo': prod.image == undefined}">
                        {{prod.name}}
                        <img src="@/assets/icons/2x1.svg" class="img-deal-title" v-show="isDeals(prod.deal, '2x1') && prod.image == undefined" >
                        <img src="@/assets/icons/3x2.svg" class="img-deal-title" v-show="isDeals(prod.deal, '3x2') && prod.image == undefined"  width="30" height="10">
                      </span>
                      <span class="text margin-bot-5 ellipse-pro small" :class="{'noImage': prod.image == undefined}">{{prod.description}}</span>
                      <div class="pricesCont margin-bot-10" :class="{'noImage': prod.image == undefined}">
                      <div class="price-adjust">
                        <p class="text semi-bold priceComplete">{{ getPrice(prod) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
                        <p v-if="validateDescount(prod)" class="text semi-bold discountText">{{ prod.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
                        <p v-if="validateDescount(prod)" class="text semi-bold discountPercent">-{{prod.percentOff}}%</p>
                      </div>
                      <div v-show="prod.image == undefined && productInCar(prod)" class="btn-add" @click="$emit('showItemModal', prod)" style="margin-right: -17px;"><span class="text semi-bold size-20 margin-right-10">Agregar</span> <i style="background: var(--buttonBackground);" class="icon-mks icon_add_menu"></i></div>
                        <div class="btn-minus-plus" v-show=" prod.image == undefined && !productInCar(prod)">
                          <span id="minus" v-show="productQuantity(prod) == 1" @click="deleteProductCart(prod._id, i, prod)"><i id="icon-menos"  class="fa fa-minus"></i></span>
                          <span id="minus" v-show="productQuantity(prod) > 1" @click="updateCart(prod, 'min', i)"><i id="icon-menos" class="fa fa-minus"></i></span>
                          <span id="quantity" class="text semi-bold size-18">{{ productQuantity(prod)}}</span>
                          <span id="plus" @click="updateCart(prod, 'max', i)"><i id="icon-mas" class="fa fa-plus"></i></span>
                        </div>
                      </div>
                  </div>
                  <div class="img-add" v-if="prod.image !== undefined">
                    <div style="position:relative">
                    <img src="@/assets/icons/2x1.svg" class="img-deal" v-show="isDeals(prod.deal, '2x1')"  width="20" height="10">
                    <img src="@/assets/icons/3x2.svg" class="img-deal" v-show="isDeals(prod.deal, '3x2')"  width="30" height="10">
                    <img class="img-product" :src="prod.image">
                    </div>
                    <div class="btn-add" v-show="productInCar(prod)" @click="$emit('showItemModal', prod)"><span class="text semi-bold size-20 margin-right-10">Agregar</span> <i style="background: var(--buttonBackground);" class="icon-mks icon_add_menu"></i></div>
                      <div class="btn-minus-plus" v-show="!productInCar(prod)">
                        <span id="minus" v-show="productQuantity(prod) == 1" @click="deleteProductCart(prod._id, i, prod)"><i id="icon-menos"  class="fa fa-minus"></i></span>
                        <span id="minus" v-show="productQuantity(prod) > 1" @click="updateCart(prod, 'min', i)"><i id="icon-menos" class="fa fa-minus"></i></span>
                        <span id="quantity" class="text semi-bold size-18">{{ productQuantity(prod)}}</span>
                        <span id="plus" @click="updateCart(prod, 'max', i)"><i id="icon-mas" class="fa fa-plus"></i></span>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-top" @click="scrollReset()">
        <i id="icon-arrow-top" class="fas fa-arrow-up "></i>
      </div>
    </div>
</template>
<script>
import LocalStorage from 'store';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  data () {
    return {
      categoryPosition: null,
      loading: false,
      messDeletePro: [],
      messDelete: false,
      messUpdatePro: [],
      messUpdate: false,
      categories: null,
      products: null,
      cate: [],
      cateSub: []
    };
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart'
    ])
  },
  mounted () {
    this.getCategories();
    this.getProductModeMenu();
  },
  methods: {
    getCategories () {
      this.$http.get('marketplace/client/menu/' + this.getVendor._id + '/categories').then((data) => {
        this.categories = data.data.data;
        this.divideCategory();
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: err,
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    getProductModeMenu () {
      this.$http.get('/marketplace/client/products/menu/' + this.getVendor._id).then((data) => {
        this.products = data.data.data.products;
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    divideCategory () {
      const thes = this;
      this.categories.map(item => {
        if (item.subCategories === undefined) {
          thes.cate.push(item);
        } else {
          thes.cateSub.push(item);
        }
      });
    },
    isSub (prod) {
      if (prod[0] !== undefined) {
        if (prod[0].name !== undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getPrice (p) {
      if (p.percentOff) {
        const descountPrice = p.price - (p.price * p.percentOff / 100);
        return descountPrice;
      } else {
        return p.price;
      }
    },
    validateDescount (p) {
      if (p.percentOff && p.percentOff > 0) {
        return true;
      } else {
        return false;
      }
    },
    scrollPosition (id) {
      if (id === 'null') {
        this.scrollReset();
      } else {
        if (id.indexOf('category') === 0) {
          const move = document.getElementById(id).offsetTop;
          document.getElementById('app').scrollTop = move - 90;
        } else if (id.indexOf('subCat') === 0) {
          const move = document.getElementById(id).offsetTop;
          document.getElementById('app').scrollTop = move - 90;
        }
      }
    },
    scrollReset () {
      document.getElementById('app').scrollTop = 0;
    },
    isDeals (data, type) {
      let show = false;
      if (data !== undefined) {
        Object.values(data).map(item => {
          if (item.type === type) {
            show = true;
          }
        });
      }
      return show;
    },
    productInCar (pro) {
      const ver = _.find(this.getCart.products, { _id: pro._id });
      if (ver !== undefined) {
        return false;
      } else {
        return true;
      }
    },
    productQuantity (pro) {
      const ver = _.find(this.getCart.products, { _id: pro._id });
      if (ver !== undefined) {
        return ver.quantity;
      } else {
        return true;
      }
    },
    updateCart (product, type, index) {
      this.messUpdatePro = product;
      const thes = this;
      const prodCart = _.find(this.getCart.products, { _id: product._id });
      const pos = _.findIndex(this.getCart.products, { _id: product._id });
      if (this.validateProductPromo(prodCart) || type === 'min') {
        this.loading = true;
        if (type === 'max') {
          prodCart.quantity += 1;
        }
        if (type === 'min') {
          prodCart.quantity -= 1;
        }
        prodCart.index = pos;
        this.$http.put('marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/item/' + prodCart._id, prodCart).then(({ data }) => {
          this.$store.commit('setCart', data.data);
          this.messUpdate = true;
          setTimeout(function () {
            thes.messUpdate = false;
            thes.messUpdatePro = [];
          }, 3000);
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 1000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No se pueden agregar más productos de promoción al carrito',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 ||
             product.deal.length === 0 ||
             (maxPromo2x1 === undefined && product.deal[0].type === '2x1') ||
             (maxPromo3x2 === undefined && product.deal[0].type === '3x2')
      ) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 1);
        const total3x2 = Math.floor(promo3x2 / 1);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    deleteProductCart (id, p, product) {
      this.loading = true;
      this.messDeletePro = product;
      const pos = _.findIndex(this.getCart.products, { _id: id });
      const thes = this;
      const data = {};
      data.index = pos;
      this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/item/' + id, data).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
        this.messDelete = true;
        setTimeout(function () {
          thes.messDelete = false;
          thes.messDeletePro = [];
        }, 3000);
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.text{
    &.category-title{
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #CFCDCD;
        padding-bottom: 10px;
        &.noBorder{
          border-bottom: inherit;
        }
    }
    &.category-title-sub{
        text-align: inherit;
        border-bottom: 1px solid #CDD500;
        width: 100%;
        padding-bottom: 10px;
    }
    &.ellipse-pro{
        text-overflow: ellipsis;
        overflow: hidden;
        width: 210px;
        cursor: pointer;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
}
    &.noImage{
      width: 300px;
    }
    &.noImagePromo{
      width: 100%;
    }

}
.img-add{
    .img-deal{
        position: absolute;
        left: -12px;
        top: -5px;
    }
}
.img-deal-title{
    float: right;
    right: 0px;
}
.img-product{
    height: 70px;
    width: 80px;
    margin-bottom: 10px;
}
.icon-mks{
    height: 23px;
    min-width: 23px;
}
.price-adjust{
     display: flex;
    align-items: center;
}
.pricesCont{
  &.noImage{
    width: 100%;
  }
}
.list-products .products .categoryCont .cont-products .producto{
  min-width: 300px !important;
}
.cont-products{
      justify-content: center;
}
.categorySimple{
      width: 100%;
    display: flex;
    flex-direction: column;
}
.btn-minus-plus{
    width: 90px;
    display: flex;
    align-items: center;
    margin-right: -10px;
    #minus{
    min-width: 30px;
    min-height: 30px;
    background: var(--buttonBackground);
    border-radius: 5px 0 0 5px;
    position: relative;
      #icon-menos{
        height: 12px;
        width: 12px;
        position: absolute;
        color: var(--buttonTextColor);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    #quantity{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F3F3F3;
    position: relative;
    }
    #plus{
    min-width: 30px;
    min-height: 30px;
    background: var(--buttonBackground);
    border-radius: 0 5px 5px 0;
    position: relative;
    #icon-mas{
        height: 12px;
        width: 12px;
        color: var(--buttonTextColor);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    }
}
</style>
