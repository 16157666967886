<template>
  <div id="category-advanced">
    <div class="category-cont">
      <div class="sub-category" :style="{'background-image': 'url('+ cat.image +')','background-size':'cover'}" :class="{'active': active == cat._id , 'disabled': cat.isActive == 0}" v-for="(cat, index) in list" :key="'catInt#' + cat.name + '-' + index" @click="(cat.isActive == 0) ? false : $emit('selectCat', cat._id) ">
        <p class="text bold center size-22 ellipsis-cat">{{cat.name}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['categories', 'active'],
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.list = this.categories;
  },
  watch: {
    categories () {
      const order = _.orderBy(this.categories, 'order', 'asc');
      this.list = order;
    }
  }
};
</script>
<style lang="scss">
    #category-advanced{
        width: 100%;
        .category-cont{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 10px;
            position: relative;
            overflow: auto;
            scroll-behavior: smooth;
            -webkit-animation: fadeSlow 1s;
            animation: fadeSlow 1s;
            .sub-category{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 16px;
                border: 2px solid #d3d3d3;
                height: 200px;
                width: 180px;
                border-radius: 5px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
                margin-bottom: 20px;
                 margin-right: 20px;
                cursor: pointer;
                &.active{
                    border-color: var(--buttonBackground);
                    background: #DDDDDD;
                }
                 &.disabled{
                opacity: .4;
                cursor:not-allowed;
                }
            }
        }
    }
</style>
