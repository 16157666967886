<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <div id="queryOrder" v-if="!loading">
      <CustomVar :vendor="config"></CustomVar>
      <div class="orderInfo" v-if="!invalid">
        <div class="orderNote thanks-note">
          <h2 class="text segoeBold" style="font-size: 24px">
            GRACIAS. TU PEDIDO ESTA EN PROCESO
          </h2>
        </div>
        <div class="orderNote show-cellphone">
          <h2
            class="text segoeBold"
            style="font-size: 30px; margin-top: 15px; text-align: center"
          >
            ORDEN
            {{ orderData.stateOrder ? orderData.stateOrder.toUpperCase() : "" }}
          </h2>
        </div>
        <div class="orderNote show-cellphone change-width">
          <h2
            class="text segoeBold font-10"
            style="font-size: 18px; margin-top: 15px; text-align: center"
          >
            En unos minutos recibirás un correo de confirmado con el tiempo
            estimado de entrega
          </h2>
        </div>
        <div class="orderNote show-cellphone">
          <p
            class="text segoe font-10"
            style="font-size: 16px; margin-top: 15px"
          >
            <span style="border-bottom: 1px solid black">Número de Orden</span>
            : #{{ orderInfo.orderId }}
            <span style="border-bottom: 1px solid black">Fecha:</span>
            {{ orderData.realDate }}
          </p>
        </div>
        <div class="orderInfoPc">
          <img
            class="mb-5"
            src="@/assets/icons/delivery-bike.svg"
            width="60"
            height="50"
          />
          <i class="mb-5 far fa-check-circle check-design"></i>
          <p class="text segoe">
            <strong class="text segoeBold">Número de orden: </strong>
            {{ orderInfo.orderId }}
          </p>
          <p class="text segoe">
            <strong class="text segoeBold">Fecha: </strong>
            {{ orderData.realDate }}
          </p>
          <p class="text segoe">
            <strong class="text segoeBold">Email: </strong>
            {{ orderData.email }}
          </p>
          <p class="text segoe">
            <strong class="text segoeBold">Total: </strong>
            {{
              orderData.realTotal
                | currency("$ ", 0, { thousandsSeparator: "," })
            }}
          </p>
          <p class="segoe">
            <strong class="text segoeBold">Metodo de Pago: </strong
            >{{ orderData.formatPayment }}
          </p>
        </div>
        <div class="segment">
          <b-steps
            v-model="activeStep"
            icon-pack="fas"
            :has-navigation="false"
            type="is-success"
          >
            <b-step-item
              :clickable="false"
              v-if="activeStep !== 5"
              label="Pedido recibido"
              icon-pack="icon-mks grayPrevius"
              icon="icon_order1"
            >
              <p class="text segoeBold" style="font-size: 20px">
                El pedido fue recibido de manera exitosa, se encuentra a la
                espera de confirmación
              </p></b-step-item
            >
            <b-step-item
              :clickable="false"
              v-if="activeStep !== 5"
              label="Pedido confirmado"
              icon-pack="icon-mks grayPrevius"
              icon="icon_order2"
            >
              <p class="text segoeBold" style="font-size: 20px">
                Pedido confirmado, se encuentra a la espera para ser procesada
              </p></b-step-item
            >
            <b-step-item
              :clickable="false"
              v-if="activeStep !== 5"
              label="Pedido en proceso"
              class="icon-big"
              icon-pack="icon-mks grayPrevius"
              icon="icon_order3"
              ><p class="text segoeBold" style="font-size: 20px">
                El pedido se encuentra en proceso, el restaurante ya esta
                haciendo todos los preparativos para que el pedido pueda ser
                enviado
              </p></b-step-item
            >
            <b-step-item
              :clickable="false"
              v-if="activeStep !== 5"
              label="Pedido Enviado"
              icon-pack="icon-mks grayPrevius"
              icon="icon_order4"
            >
              <p class="text segoeBold" style="font-size: 20px">
                El pedido ya fue enviado y esta en camino para su entrega
              </p>
            </b-step-item>
            <b-step-item
              :clickable="false"
              v-if="activeStep !== 5"
              label="Pedido Entregado"
              icon-pack="icon-mks grayPrevius"
              icon="icon_order5"
            >
              <p class="text segoeBold" style="font-size: 20px">
                El pedido ya llego a su destino y fue entragado
              </p></b-step-item
            >
            <b-step-item
              type="is-danger"
              :clickable="false"
              v-if="activeStep == 5"
              icon-pack="fas"
              label="Pedido Cancelado"
              icon="fas fa-ban"
            >
              <p class="text normal center">
                {{ orderInfo.cancelReason }}
              </p></b-step-item
            >
          </b-steps>
        </div>
        <div class="order-details">
          <div class="black-label">
            <p class="hide-cellphone">
              <strong
                class="order-details-title text segoeBold"
                style="font-size: 23px"
                >{{
                  orderData.formatPayment
                    ? orderData.formatPayment.toUpperCase()
                    : ""
                }}</strong
              >
            </p>
          </div>
          <div class="order-details-text hide-cellphone">
            <h2
              class="text segoeBold"
              style="font-size: 21px; margin-bottom: 10px"
            >
              Detalles del pedido
            </h2>
            <p
              class="text segoeBold hide-cellphone test"
              style="font-size: 20px"
            >
              <span
                ><i
                  class="far fa-calendar"
                  style="width: 35px !important; height: 35px !important"
                >
                </i
              ></span>
              Fecha de envio:
              {{
                orderData.isProgramming
                  ? orderData.realDateWithHour
                  : orderData.realDateWithoutHour
              }}
            </p>
            <p
              class="text segoeBold hide-cellphone test"
              style="font-size: 20px"
            >
              En unos minutos recibirás un correo de confirmado con el tiempo
              estimado de entrega
            </p>
          </div>
          <section id="products-order">
            <table class="table-products">
              <thead>
                <tr class="table-header source-sans-pro-bold">
                  <th
                    class="text segoeBold text-header"
                    style="font-size: 23px; padding-left: 10px"
                  >
                    Producto
                  </th>
                  <th
                    class="has-text-centered text segoeBold text-header pl-15"
                    style="font-size: 23px"
                  >
                    Cantidad
                  </th>
                  <th
                    class="has-text-right text segoeBold text-header"
                    style="font-size: 23px; padding-right: 20px"
                  >
                    PRECIO
                  </th>
                </tr>
              </thead>
              <tbody class="scroll-product">
                <tr
                  class="row"
                  v-for="(product, index) in orderData.products"
                  :key="index"
                >
                  <td class="first-col">
                    <div v-if="product.image" class="img-product">
                      <img :src="product.image" />
                    </div>
                    <div v-else class="img-product">
                      <b-icon pack="mdi" icon="camera-image"></b-icon>
                    </div>
                    <div>
                      <span class="text segoeBold size-14 font-11">{{
                        product.name
                      }}</span>
                      <div
                        class="ContProductOptions"
                        v-if="product.options != undefined"
                      >
                        <div
                          class="contOptions"
                          v-for="(spec, index) in product.options.products"
                          :key="index"
                        >
                          <span class="text segoeBold size-12 font-11">{{
                            spec.name
                          }}</span>
                          <span
                            class="text segoe size-10 font-11"
                            v-for="(item, key) in spec.items"
                            :key="key"
                            >• {{ item.item }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="has-text-centered" style="width: min-content">
                    <span class="text segoe font-13">{{
                      product.quantity
                    }}</span>
                  </td>
                  <td class="has-text-right">
                    <span
                      class="text segoeBold font-13"
                      style="padding-right: 20px"
                      >{{
                        product.totalPrice
                          | currency("$ ", 0, { thousandsSeparator: "," })
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <div class="gray-line hide-cellphone"></div>
          <div
            class="columns no-margin hide-cellphone"
            style="margin-bottom: calc(1.5rem - 1.3rem) !important"
          >
            <div
              class="column is-11 reduce-width-2"
              style="padding: 0px !important"
            >
              <p class="ml mt-25 text segoeBold" style="font-size: 30px">
                Subtotal:
              </p>
            </div>
            <div class="column is-1" style="padding: 0px !important">
              <p
                v-if="orderData.tip == 0 || orderData.tip == undefined"
                class="mt text segoeBold font-18"
                style="font-size: 20px"
              >
                {{
                  orderData.totalPriceOld
                    | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
              <p
                v-if="orderData.tip > 0 || orderData.tip !== undefined"
                class="mt text segoeBold font-18"
                style="font-size: 20px"
              >
                {{
                  orderData.totalPriceOld
                    | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
          </div>
          <div class="subtotal-div show-cellphone2">
            <div class="columns no-margin show-cellphone">
              <div
                class="column is-10 reduce-width-3"
                style="padding: 0px !important"
              >
                <p class="ml text segoeBold" style="font-size: 20px">
                  Subtotal:
                </p>
              </div>
              <div class="column" style="padding: 0px !important">
                <p
                  class="ml text segoeBold font-13"
                  style="font-size: 14px; margin-top: 7px"
                  v-if="orderData.tip == 0 || orderData.tip == undefined"
                >
                  {{
                    orderData.totalPriceOld
                      | currency("$ ", 0, { thousandsSeparator: "," })
                  }}
                </p>
                <p
                  class="ml text segoeBold font-13"
                  style="font-size: 14px; margin-top: 7px"
                  v-if="orderData.tip > 0 || orderData.tip !== undefined"
                >
                  {{
                    orderData.totalPriceOld
                      | currency("$ ", 0, { thousandsSeparator: "," })
                  }}
                </p>
              </div>
            </div>
            <div class="columns no-margin show-cellphone">
              <div
                class="column pr-40"
                style="padding: 0px !important; padding-right: 50px"
              >
                <p
                  class="ml text segoeBold"
                  style="font-size: 16px; margin-top: 5px"
                >
                  Descuento:
                </p>
              </div>
              <div
                class="column"
                style="
                  padding: 0px !important;
                  display: flex;
                  justify-content: center;
                "
              >
                <p
                  class="ml text segoeBold font-13"
                  style="font-size: 16px; margin-top: 5px; padding-right: 7px"
                >
                  {{ orderData.discountValue ? orderData.discountValue : 0 }}%
                </p>
              </div>
              <div
                class="column pr-21"
                style="
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 29px;
                "
              >
                <p
                  class="ml text segoeBold font-13"
                  style="font-size: 14px; margin-top: 8px"
                  v-if="orderData.discountCode"
                >
                  -{{
                    orderData.discountCode
                      | currency("$ ", 0, { thousandsSeparator: "," })
                  }}
                </p>
                <p
                  class="ml text segoeBold font-13"
                  style="font-size: 14px; margin-top: 8px"
                  v-else
                >
                  -{{ 0 | currency("$ ", 0, { thousandsSeparator: "," }) }}
                </p>
              </div>
            </div>
            <div class="columns no-margin show-cellphone">
              <div
                class="column is-10 reduce-width-3 reduce-width-6"
                style="padding: 0px !important"
              >
                <p
                  class="ml text segoeBold"
                  style="font-size: 16px; margin-top: 5px"
                >
                  Envio:
                </p>
              </div>
              <div class="column" style="padding: 0px !important">
                <p
                  class="ml text segoeBold"
                  style="font-size: 14px; margin-top: 7px"
                >
                  {{
                    orderData.delivery
                      ? orderData.delivery
                      : 0 | currency("$ ", 0, { thousandsSeparator: "," })
                  }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="columns no-margin hide-cellphone"
            style="margin-bottom: calc(1.5rem - 1.3rem) !important"
          >
            <div
              class="column is-5"
              style="padding: 0px !important; width: 42% !important"
            >
              <p class="ml text segoe" style="font-size: 25px">Descuento:</p>
            </div>
            <div
              class="column is-6 less-width pr-7"
              style="padding: 0px !important; padding-left: 40px !important"
            >
              <p class="ml text segoe" style="font-size: 20px">
                {{ orderData.discountValue ? orderData.discountValue : 0 }}%
              </p>
            </div>
            <div
              class="column"
              style="padding: 0px !important; padding-right: 1px !important"
            >
              <p
                class="text segoeBold font-18"
                style="font-size: 20px"
                v-if="orderData.discountCode"
              >
                -{{
                  orderData.discountCode
                    | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
              <p class="text segoeBold font-18" style="font-size: 20px" v-else>
                -{{ 0 | currency("$ ", 0, { thousandsSeparator: "," }) }}
              </p>
            </div>
          </div>
          <div class="columns no-margin hide-cellphone">
            <div
              class="column is-11 reduce-width-2"
              style="padding: 0px !important"
            >
              <p class="ml text segoe" style="font-size: 25px">Envio:</p>
            </div>
            <div class="column is-1" style="padding: 0px !important">
              <p class="text segoeBold font-18" style="font-size: 20px">
                {{
                  orderData.delivery
                    ? orderData.delivery
                    : 0 | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
          </div>
          <div class="gray-line hide-cellphone"></div>
          <div class="columns no-margin hide-cellphone">
            <div
              class="column is-10 reduce-width"
              style="padding: 0px !important"
            >
              <p class="ml mt text segoeBold" style="font-size: 35px">TOTAL:</p>
            </div>
            <div
              class="column"
              style="padding: 0px !important; padding-right: 10px !important"
            >
              <p class="ml mt text segoeBold font-19" style="font-size: 26px">
                {{
                  orderData.realTotal
                    | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
          </div>
          <div class="columns no-margin show-cellphone">
            <div
              class="column is-10 reduce-width-4"
              style="padding: 0px !important"
            >
              <p
                class="ml mt-25 text segoeBold font-25"
                style="font-size: 30px"
              >
                TOTAL:
              </p>
            </div>
            <div class="column" style="padding: 0px !important">
              <p
                class="ml text segoeBold font-18-new"
                style="
                  font-size: 20px;
                  margin-top: 32px;
                  padding-right: 15px !important;
                "
              >
                {{
                  orderData.realTotal
                    | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
          </div>
          <div class="columns no-margin show-cellphone">
            <div
              class="column is-10 reduce-width-3"
              style="padding: 0px !important"
            >
              <p
                class="ml text segoe"
                style="font-size: 20px; padding-bottom: 30px; margin-top: 22px"
              >
                Método de Pago:
              </p>
            </div>
            <div class="column" style="padding: 0px !important">
              <p class="ml mt-25 mt-29 text segoeBold" style="font-size: 15px">
                {{ orderData.formatPayment }}
              </p>
            </div>
          </div>
          <div class="gray-line hide-cellphone"></div>
          <div class="columns no-margin hide-cellphone">
            <div class="column" style="padding: 0px !important">
              <h1 class="ml mt text segoe" style="font-size: 30px">Nota:</h1>
              <p
                class="ml text segoe"
                style="font-size: 23px; padding-right: 5px"
              >
                {{ orderData.comments }}
              </p>
            </div>
          </div>
          <div class="columns no-margin show-cellphone show-borders">
            <div class="column" style="padding: 0px !important">
              <h1
                class="ml text segoeBold font-19"
                style="font-size: 25px; padding-bottom: 10px"
              >
                Nota:
              </h1>
              <p
                class="ml text segoe"
                style="
                  font-size: 18px;
                  padding-right: 5px;
                  padding-bottom: 30px;
                "
              >
                {{ orderData.comments }}
              </p>
            </div>
          </div>
          <div
            class="columns mt no-margin hide-cellphone"
            style="margin-left: 0px !important; margin-rigth: 0px !important"
          >
            <div class="column address-info" style="padding: 0px !important">
              <span class="ml text segoeBold font-16" style="font-size: 25px"
                ><strong>Dirección de facturación</strong></span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.name }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.address }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >Bogotá</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.phone }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.email }}</span
              >
            </div>
            <div class="column address-info" style="padding: 0px !important">
              <span class="ml text segoeBold font-16" style="font-size: 25px"
                ><strong>Dirección de envío</strong></span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.name }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.address }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >Bogotá</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.phone }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.email }}</span
              >
            </div>
          </div>
          <div
            class="columns no-margin show-cellphone"
            style="margin-left: 0px !important; margin-rigth: 0px !important"
          >
            <div class="column address-info" style="padding: 0px !important">
              <span class="ml text segoeBold font-16" style="font-size: 25px"
                ><strong>Dirección de facturación</strong></span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.name }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.address }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >Bogotá</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.phone }}</span
              >
              <span
                class="ml text segoe font-15"
                style="
                  font-size: 20px;
                  padding-right: 5px;
                  padding-bottom: 30px;
                "
                >{{ orderData.email }}</span
              >
            </div>
            <div class="column address-info" style="padding: 0px !important">
              <span class="ml text segoeBold font-16" style="font-size: 25px"
                ><strong>Dirección de envío</strong></span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.name }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.address }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >Bogotá</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
                >{{ orderData.phone }}</span
              >
              <span
                class="ml text segoe font-15"
                style="font-size: 20px; padding-right: 5px"
              >
                {{ orderData.email }}</span
              >
            </div>
          </div>
        </div>
        <div class="button-store-back hide-cellphone">
          <button class="button-store-back-design" @click="goRestaurant">
            <span> Seguir Pedido</span>
          </button>
        </div>
        <div class="button-store-back show-cellphone">
          <button class="button-store-back-design" @click="goRestaurant">
            <span>Seguir Pedido</span>
          </button>
        </div>
        <span
          @click="goRestaurant"
          class="show-cellphone text segoe goback-cellphone"
          >Regresar a la tienda</span
        >
      </div>
      <div class="invalid-order" v-if="invalid">
        <i id="alert" class="fas fa-exclamation-circle"></i>
        <p class="text bold red">Codigo de order ID incorrecto</p>
        <p class="text">Por favor verifica tu order ID y intenta de nuevo</p>
        <div @click="$router.push('/')" class="cont-alert">
          <i style="margin-right: 10px" class="fas fa-arrow-left"></i>
          <p class="text small cursor">Regresar a la pagina principal</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomVar from '@/components/CustomVars';
import moment from 'moment';
export default {
  name: 'queryOrder',
  components: { CustomVar },
  metaInfo () {
    return {
      title: 'Estado de pedido',
      link: [{ vmid: 'icon', rel: 'icon', href: '/favicon.ico' }]
    };
  },
  data () {
    return {
      orderStatus: [''],
      orderInfo: [],
      orderData: {},
      loading: false,
      activeStep: 0,
      invalid: null,
      config: {
        toolbarColor: '#151E26',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff'
      }
    };
  },
  mounted () {
    this.setConfig();
    const data = this.$route.params.id;
    this.getOrderStatus(data);
  },
  methods: {
    setConfig () {
      const vendor = this.$store.getters.getVendor;
      if (vendor.configuration !== undefined) {
        if (vendor.configuration.toolbarColor !== undefined) {
          this.config.toolbarColor = vendor.configuration.toolbarColor;
        }
        if (vendor.configuration.footerColor !== undefined) {
          this.config.footerColor = vendor.configuration.footerColor;
        }
        if (vendor.configuration.backCardProduct !== undefined) {
          this.config.backCardProduct = vendor.configuration.backCardProduct;
        }
        if (vendor.configuration.textColorDescriptionCard !== undefined) {
          this.config.textColorDescriptionCard = vendor.configuration.textColorDescriptionCard;
        }
        if (vendor.configuration.textColorTitleCard !== undefined) {
          this.config.textColorTitleCard = vendor.configuration.textColorTitleCard;
        }
        if (vendor.configuration.textColorPriceCard !== undefined) {
          this.config.textColorPriceCard = vendor.configuration.textColorPriceCard;
        }
        if (vendor.configuration.footerTextColor !== undefined) {
          this.config.footerTextColor = vendor.configuration.footerTextColor;
        }
        if (vendor.configuration.buttonColor !== undefined) {
          this.config.buttonColor = vendor.configuration.buttonColor;
        }
        if (vendor.configuration.buttonTextColor !== undefined) {
          this.config.buttonTextColor = vendor.configuration.buttonTextColor;
        }
      }
    },
    goRestaurant () {
      window.location.href = '/' + this.orderInfo.vendor.id + '/restaurant';
    },
    getOrderStatus (order) {
      this.loading = true;
      const data = {};
      data.id = order;
      this.$http
        .post('marketplace/client/order/status', data)
        .then(({ data }) => {
          this.orderInfo = data.data;
          this.orderData = data.data.order;
          const date = this.orderData.date.split('-');
          if (this.orderData.isProgramming === 1) {
            const dateDelivery = this.orderData.timeDelivery.split('-');
            const momentDateDelivery = moment(this.orderData.timeDelivery);
            const monthDelivery = momentDateDelivery.format('MMMM');
            const hourDelivery = momentDateDelivery.format('hh A');
            this.orderData.realDateWithHour = `${dateDelivery[2].substr(
              0,
              2
            )} ${monthDelivery} ${dateDelivery[0]} a las ${hourDelivery}`;
          }
          const momentDate = moment(this.orderData.date);
          const month = momentDate.format('MMMM');
          this.orderData.realDate = `${date[2].substr(0, 2)} ${month} ${
            date[0]
          }`;

          this.orderData.realDateWithoutHour = `${date[2].substr(
            0,
            2
          )} ${month} ${date[0]}`;
          this.orderData.stateOrder = this.orderData.history[
            this.orderData.history.length - 1
          ].state;
          let total = this.orderData.totalPrice;
          if (this.orderData.delivery) {
            total += this.orderData.delivery;
          }
          this.orderData.realTotal = total;
          this.activeStep = data.data.stateId;
          this.loading = false;
          this.invalid = false;
        })
        .catch((err) => {
          this.loading = false;
          this.invalid = true;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(
              err.request.response,
              err.request.status
            ),
            position: 'is-top',
            type: 'is-danger'
          });
        });
    }
  }
};
</script>
<style lang="scss">
.b-steps .steps .step-items .step-item.is-success.is-previous .step-marker {
  background-color: white;
  border-color: #23d160;
  color: #23d160;
}
.b-steps
  .steps
  .step-items
  .step-item.is-success.is-previous
  .step-marker
  .icon
  .icon-mks.grayPrevius {
  background-color: #23d160;
}

.icon {
  height: 3.5rem !important;
  width: 6.5rem !important;
}

.icon-mks.grayPrevius {
  width: 19rem !important;
  height: 6rem !important;
}

.b-steps
  .steps
  .step-items
  .step-item.is-success.is-active
  .step-marker
  .icon
  .icon-mks.grayPrevius {
  background: #23d160;
}
.b-steps .steps .step-items .step-item .step-marker {
  background: white;
  border-color: #b5b5b5;
}
.b-steps .steps .step-items .step-item .step-marker .icon {
  color: #b5b5b5;
}

.b-steps .steps .step-items .step-item .step-marker .icon *,
.b-steps .steps .step-items .step-item .step-marker .icon *:before {
  font-size: 2rem;
}
#queryOrder {
  animation: fade 2s ease-in-out;
  #icon-logo-query {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 30px;
  }
  .cursor {
    cursor: pointer;
  }
  .orderNote {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .segoe {
    font-family: "Segoe UI";
    font-size: 17px;
  }
  .mb-5 {
    margin-bottom: 10px;
  }
  .b-steps .steps .step-items .step-item .step-marker {
    height: 5rem;
    width: 5rem;
  }
  .b-steps .steps .step-items .step-item:not(:first-child)::before,
  .b-steps .steps .step-items .step-item:only-child::before {
    height: 0.2em;
    top: 2.5rem;
  }
  .b-steps .steps .step-items .step-item .step-details {
    text-align: center;
    z-index: 1;
    width: 130px;
  }
}

.black-label {
  background: #132b2e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  max-height: 85px;
  border-radius: 20px 20px 0px 0px;
  top: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
}
.order-details {
  max-width: 750px;
  min-width: 500px;
  height: auto;
  border-radius: 25px 25px 25px 25px;
  border: 0px solid;
  box-shadow: 0px 3px 6px #122a2d;
  padding-bottom: 1px;
}
.order-details-title {
  color: white !important;
}
.order-details-text {
  padding: 25px;
  display: flex;
  flex-direction: column;
}
.pa-top {
  padding-top: 10px;
}
.product-titles {
  display: flex;
  justify-content: space-around;
  padding-left: 25px;
}
.product-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 20px;
  background: #707070 0% 0% no-repeat padding-box;
  max-width: 1331px;
  max-width: 1150px;
  height: 120px;
  min-height: 80px;
  border: 1px solid #707070;
}
.text-product-detail {
  font-size: 20px;
  opacity: 1 !important;
  color: #122a2d;
}
.ml {
  margin-left: 15px;
}
.mt {
  margin-top: 30px;
}
.mt-25 {
  margin-top: 25px;
}
.ml-20 {
  margin-left: 20px;
}
.subtotal-text {
  font-family: segoeUiBold;
  font-size: 35px;
  font-weight: bold;
  color: black;
}
.discount-text {
  font-family: "Segoe UI";
  font-size: 30px;
  color: black;
}
.note-text {
  font-family: "Segoe UI";
  font-size: 20px;
  color: black;
  padding-right: 5px;
}
.gray-line {
  border: 0.1px solid rgba(112, 112, 112, 0.4);
  margin-top: 20px;
  height: 1px;
}
.address-info {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(112, 112, 112, 0.4);
  border-radius: 0px 0px 23px 0px;
  border-left: none;
  border-bottom: none;
}
.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.margin-margin-columns {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.address-info:first-child {
  border-radius: 0px;
  border-right: 1px solid rgba(112, 112, 112, 0.4);
}
.button-store-back {
  display: flex;
  align-items: center;
  padding-bottom: 70px;
  padding-top: 50px;
  .button-store-back-design {
    cursor: pointer;
    height: 60px;
    width: 350px;
    min-width: 300px;
    border-radius: 15px;
    background-color: #122a2d;
    color: white;
    font-family: segoeUiBold;
    font-size: 22px;
    border: none;
    outline: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
  }
}
.check-design {
  color: #47bd42f7 !important;
  width: 35px !important;
  height: 35px !important;
}
.pr-7 {
  padding-right: 7px;
}
.reduce-width {
  width: 82.3% !important;
}
.reduce-width-2 {
  width: 87.66667% !important;
}
.less-width {
  width: 40.6% !important;
}
.font-19 {
  font-size: 24px !important;
}
.font-18 {
  font-size: 15px !important;
}

.show-cellphone {
  display: none !important;
}

.less-width {
  width: 44.7% !important;
}
.reduce-width {
  width: 83.6% !important;
}
#products-order {
  height: auto;
  .table-products {
    .table-header {
      border-top: none;
      border-bottom-style: none;
      border-bottom-width: 1px;
      th {
        margin: 5px 0;
        margin-right: 10px;
      }
    }
    .row {
      margin-left: 5px;
      margin-top: 5px;
      color: black;
      background-color: rgba(112, 112, 112, 0.4);
      width: 730px;
      max-width: 730px;
      min-width: 500px;
    }
    td {
      margin: 5px 0;
      margin-right: 10px;
      vertical-align: middle;
    }
    .first-col {
      display: flex;
      align-items: center;
      .img-product {
        width: 50px;
        min-width: 45px;
        height: 50px;
        min-height: 45px;
        background-color: #afafaf;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        .icon {
          width: 45px;
          height: 45px;
          i {
            color: #06111b;
          }
        }
      }
    }
    .adiction {
      padding-right: 5px;
    }
    .inline {
      display: flex;
      flex-flow: wrap;
    }
  }
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  table thead {
    flex: 0 0 auto;
    width: 100%;
  }
  table tbody {
    flex: 1 1 auto;
    display: block;
  }
  table tbody tr {
    width: 100%;
  }
  table thead,
  table tbody tr {
    display: table;
    table-layout: fixed;
  }
  .contOptions {
    display: flex;
    flex-direction: column;
  }
  .ContProductOptions {
    display: flex;
    flex-direction: column;
  }
  .mr {
    margin-right: 30px !important;
  }
}
.test {
  display: flex;
  justify-content: flex-start;
}
.font-27 {
  font-size: 27px !important;
}
.columns {
  margin-bottom: calc(1.5rem - 1.3rem);
}
.column.is-10,
.column.is-10-tablet {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 86.33333%;
}

@media (max-width: 770px) {
  .thanks-note {
    display: none !important;
  }
  .change-width {
    width: 400px;
  }
  .order-details {
    width: 550px;
    min-width: 300px;
    border-radius: 0px;
    border: none;
    box-shadow: none;
  }
  .reduce-width-3 {
    width: 81.33333% !important;
  }
  .reduce-width-4 {
    width: 80% !important;
  }
  .address-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 0px;
    border: 1px solid rgba(112, 112, 112, 0.4);
  }
  .font-16 {
    font-size: 16px !important;
  }
  .font-19 {
    font-size: 19px !important;
  }
  .font-18-new {
    font-size: 17px !important;
  }
  .font-15 {
    font-size: 13px !important;
  }
  .black-label {
    border-radius: 0px;
    margin-bottom: 30px;
    height: 10px;
  }
  .font-18 {
    font-size: 10px !important;
  }
  .table-products {
    .row {
      background-color: rgba(112, 112, 112, 0.4);
      width: 530px !important;
      max-width: 530px !important;
      min-width: 530px !important;
    }
  }
  .hide-cellphone {
    display: none !important;
  }
  .orderInfoPc {
    display: none !important;
  }
  .segment {
    display: none !important;
  }
  .show-cellphone {
    display: flex !important;
  }
  .orderNote {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
  .goback-cellphone {
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 19px !important;
    border-bottom: 1px solid black;
  }
  .show-borders {
    margin-top: 10px;
    border: 1px solid rgba(112, 112, 112, 0.4);
    border-bottom: none;
  }
  .button-store-back {
    padding-bottom: 30px !important;
    .button-store-back-design {
      cursor: pointer;
      height: 60px;
      width: 350px;
      min-width: 300px;
      border-radius: 15px;
      background-color: #122a2d;
      color: white;
      font-family: segoeUiBold;
      font-size: 22px;
      border: none;
      outline: none;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
    }
  }
  .show-cellphone2 {
    display: block;
  }
  .subtotal-div {
    margin-top: 20px !important;
    margin-left: 5px !important;
    background-color: rgba(112, 112, 112, 0.4);
    width: 530px !important;
    max-width: 530px !important;
    height: auto;
    min-width: 530px !important;
  }
}

@media (max-width: 500px) {
  .thanks-note {
    display: none !important;
  }
  .change-width {
    width: 300px;
  }
  .ml {
    margin-left: 5px;
  }
  .ml-13 {
    margin-left: 13px !important;
  }
  .pl-15 {
    padding-left: 15px !important;
  }
  .pr-30 {
    padding-right: 30px !important;
  }
  .pr-40 {
    padding-right: 40px !important;
  }
  .pr-21 {
    padding-right: 21px !important;
  }
  .order-details {
    width: 350px;
    min-width: 250px;
    border-radius: 0px;
    border: none;
  }
  .reduce-width-3 {
    width: 77.8% !important;
  }
  .reduce-width-6 {
    width: 78.9% !important;
  }
  .reduce-width-4 {
    width: 75% !important;
  }
  .font-18-new {
    font-size: 15px !important;
  }
  .address-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: inherit;
    border-radius: 0px;
    border: 1px solid rgba(112, 112, 112, 0.4);
  }
  .font-16 {
    font-size: 12px !important;
  }
  .font-25 {
    font-size: 25px !important;
  }
  .font-11 {
    font-size: 11px !important;
  }
  .font-13 {
    font-size: 13px !important;
  }
  .font-15 {
    font-size: 10px !important;
  }
  .black-label {
    border-radius: 0px;
    margin-bottom: 30px;
    height: 10px;
  }
  .font-18 {
    font-size: 10px !important;
  }
  .font-10 {
    font-size: 10px !important;
  }
  .table-products {
    .row {
      background-color: rgba(112, 112, 112, 0.4);
      width: 340px !important;
      max-width: 340px !important;
      min-width: 340px !important;
    }
    .table-header {
      border-top: none;
      border-bottom-style: none;
      border-bottom-width: 1px;
      th {
        margin: 5px 0;
        margin-right: 10px;
      }
    }
  }
  .text-header {
    font-size: 14px !important;
  }
  .hide-cellphone {
    display: none !important;
  }
  .orderInfoPc {
    display: none !important;
  }
  .segment {
    display: none !important;
  }
  .show-cellphone {
    display: flex !important;
  }
  .orderNote {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
  .goback-cellphone {
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 19px !important;
    border-bottom: 1px solid black;
  }

  .show-borders {
    border: 1px solid rgba(112, 112, 112, 0.4);
    border-bottom: none;
  }
  .button-store-back {
    padding-bottom: 30px !important;
    .button-store-back-design {
      cursor: pointer;
      height: 60px;
      width: 300px;
      min-width: 300px;
      border-radius: 15px;
      background-color: #122a2d;
      color: white;
      font-family: segoeUiBold;
      font-size: 22px;
      border: none;
      outline: none;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
    }
  }
  .show-cellphone2 {
    display: block;
  }
  .font-27 {
    font-size: 27px !important;
  }
  .mt-29 {
    margin-top: 29px !important;
  }
  .subtotal-div {
    margin-top: 20px;
    margin-left: 5px;
    background-color: rgba(112, 112, 112, 0.4);
    width: 340px !important;
    max-width: 340px !important;
    height: auto;
    min-width: 340px !important;
  }
}
</style>
