<template>
  <div id="menu-digital">
    <CustomVar :vendor="config" v-if="load"></CustomVar>
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>
    <div v-if="getVendor.menuDigital" style="display: flex; justify-content: center; align-items: center;">
      <div v-html="getVendor.menuDigital" id="menu-content" style="max-width: 75%;"></div>
      <button @click="topFunction" id="myBtn" title="Volver arriba"><i class="fa fa-arrow-up"></i></button>
      <button @click="sendWhatsapp" v-if="getVendor.whatsapp != undefined" id="whatsapp" title="Escribir por Whatsapp"><i class="fab fa-whatsapp"></i></button>
    </div>
  </div>
</template>
<script>
import CustomVar from '@/components/CustomVars';
import { mapGetters } from 'vuex';
export default {
  metaInfo () {
    return {
      title: this.getVendor.displayName,
      link: [
        { vmid: 'icon', rel: 'icon', href: this.getVendor.logo || '/favicon.ico' }
      ]
    };
  },
  name: 'Menu',
  components: { CustomVar },
  data () {
    return {
      loading: false,
      load: false,
      mobile: false,
      config: {
        toolbarColor: '#151E26',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff',
        textColorMap: '#808080',
        circleColorMap: '#132b2e'
      }
    };
  },
  mounted () {
    this.getInfoVendor(this.$route.params.id);
    document.getElementById('menu-digital').addEventListener('scroll', () => {
      this.scrollFunction();
    });
  },
  computed: {
    ...mapGetters([
      'getVendor'
    ])
  },
  methods: {
    getInfoVendor (id) {
      this.loading = true;
      this.$http.get('/marketplace/client/vendor/' + id).then(({ data }) => {
        this.$store.commit('setVendor', data.data);
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      }).finally(() => {
        this.load = true;
      });
    },
    scrollFunction () {
      const btnTop = document.getElementById('myBtn');
      if (document.getElementById('menu-digital').scrollTop > 240) {
        btnTop.style.display = 'block';
      } else {
        btnTop.style.display = 'none';
        const bn = document.getElementById('menu-digital');
        bn.classList.remove('top');
      }
    },
    topFunction () {
      document.getElementById('menu-digital').scrollTop = 0;
      const bn = document.getElementById('menu-digital');
      bn.classList.add('top');
    },
    sendWhatsapp () {
      const mobile = this.getVendor.whatsapp;
      if (this.$isMobile()) {
        window.open(`https://api.whatsapp.com/send?phone=${mobile}&text=hola`, '_blank');
      } else {
        window.open(`https://web.whatsapp.com/send?phone=${mobile}&text=hola`, '_blank');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  #menu-digital {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: absolute;
    top: 0px;
  }
  #myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 10px; /* Place the button at the bottom of the page */
    right: 65px; /* Place the button 30px from the right */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #000; /* Set a background color */
    color: #fff; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    z-index: 999;
    box-shadow: none;
  }
  #myBtn:hover {
    background-color: #000; /* Add a dark-grey background on hover */
  }
  #whatsapp {
    position: fixed; /* Fixed/sticky position */
    bottom: 10px; /* Place the button at the bottom of the page */
    right: 15px; /* Place the button 30px from the right */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: var(--buttonBackground); /* Set a background color */
    color: var(--buttonTextColor); /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    z-index: 999;
    box-shadow: none;
  }
  #whatsapp:hover {
    background-color: var(--buttonBackground); /* Add a dark-grey background on hover */
  }
  .top {
    transition: top 20s ease 10s;
  }
  @media(max-width: 1000px) {
    #menu-content {
      width: 100%!important;
      max-width: 100%!important;
    }
  }
</style>
