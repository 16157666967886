<template>
  <div>
    <CustomNavBar @query ="search = $event"></CustomNavBar>
    <OrderPage :searchProducts ="search"></OrderPage>
  </div>
</template>
<script>
import OrderPage from '@/components/templateBasic/orderPage';
import CustomNavBar from '@/components/templateBasic/navBar';
export default {
  components: { OrderPage, CustomNavBar },
  data () {
    return {
      search: null
    };
  }
};
</script>
