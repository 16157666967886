<template>
  <div id="order" style="background: white" v-if="load">
    <CustomVar :vendor="config" v-if="load"></CustomVar>
    <b-loading :active.sync="loading" :can-cancel="false" :is-full-page="true">
      <b-icon custom-class="fa-spin" icon="sync-alt" size="is-large"></b-icon>
    </b-loading>
    <div class="cont-info-check">
      <div class="info-people" id="infoTotal">
        <p class="text bold size-20 margin-bot-10 mobile" style="margin-top: 6px;">Finalizar pedido</p>
        <div class="contCamp">
          <b-field :message="errors.first('name')" :type="{ 'is-danger': errors.has('name') }">
            <input
              class="input-dec"
              expanded
              id="name"
              name="name"
              placeholder="Nombre completo"
              type="text"
              v-model="orderData.name"
              v-validate="'required'"
            />
          </b-field>
          <div class="rows">
            <b-field
              :message="errors.first('phone')"
              :type="{ 'is-danger': errors.has('phone') }"
              style="flex: 1"
            >
              <input
                class="input-dec"
                name="phone"
                placeholder="Celular"
                type="numeric"
                v-model="orderData.phone"
                v-validate="'required|numeric|min:7|max:14 '"
              />
            </b-field>
            <b-field
              :message="errors.first('email')"
              :type="{ 'is-danger': errors.has('email') }"
              style="flex: 1"
            >
              <input
                class="input-dec"
                name="email"
                placeholder="Correo electronico"
                type="email"
                v-model="orderData.email"
                v-validate="'required|email'"
              />
            </b-field>
          </div>
          <div class="rows">
            <b-field
              :message="errors.first('code')"
              :type="{ 'is-danger': errors.has('code') }"
              style="flex: 1; margin-top: 10px;"
            >
              <input
                class="input-dec"
                name="code"
                placeholder="Código"
                type="text"
                v-if="showFields"
                v-model="orderData.roomNumber"
                v-validate="'required'"
              />
            </b-field>
          </div>
          <b-field
            :message="
              errors.first('typeAddress') ||
              errors.first('numberType') ||
              errors.first('numberAdress') ||
              errors.first('numberHome')
            "
            :type="{
              'is-danger':
                errors.has('typeAddress') ||
                errors.has('numberType') ||
                errors.has('numberAdress') ||
                errors.has('numberHome'),
            }"
            style="flex: 1; margin-top: 1rem"
            v-if="getVendor.disableMap === 0"
          >
            <div class="newAddress">
              <div class="input-icons">
                <input
                  :value="this.orderData.address"
                  class="input-dec"
                  disabled
                  name="email"
                  placeholder="Correo electronico"
                  style="color: black"
                  type="email"
                  v-validate="'required|email'"
                />
                <div
                  @click="openCoverage()"
                  class="contEditCoverage"
                  style="position: absolute; right: 5px"
                >
                  <i class="icon-mks black icon_edit_map"></i>
                </div>
              </div>
              <gmap-map
                :center="center"
                :zoom="zoom"
                ref="gmap2"
                style="width: 100%; height: 400px; max-height: 450px"
                v-show="false"
              >
                <GmapMarker :key="index" :position="m.position" v-for="(m, index) in markers"></GmapMarker>
              </gmap-map>
            </div>
          </b-field>
          <!-- <div class="contCamp">
            <div
              style="display: flex; flex-direction: column;"
              v-if="(getVendor.minPriceForOrder == undefined || getVendor.minPriceForOrder == 0) || (getVendor.minPriceForOrder > 0 ) && getVendor.closed == 0"
            >
              <span class="text bold size-16 margin-bot-10">Tipo de entrega</span>
              <div
                :key="'metEnv#' + index"
                class="ContRadio"
                v-for="(option, index) in getVendor.deliveryType"
              >
                <span class="text segoe size-16" style="display: flex;">{{ option.type }}</span>
                <b-radio
                  :native-value="option.type"
                  @input="validate2()"
                  v-model="orderData.deliveryType"
                  v-validate="'required'"
                ></b-radio>
              </div>
              <div @click="preOrderValidate" class="btn-confirmar" v-if="showPay">
                <p class="text white normal center">
                  <i class="fa fa-check"></i>&nbsp;Realizar el pedido
                </p>
              </div>
            </div>
          </div>-->
          <b-field style="flex: 1" v-if="optionAdressShow">
            <input
              class="input-dec"
              placeholder="Inidicaciones Adicionales"
              v-model="addressFormat.options"
            />
          </b-field>
          <div class style="flex:1; margin-top:10px;" v-if="showFields">
            <Selector
              :custom="true"
              :options="deliveryTypes"
              :placeHolder="'Seleccionar el tipo de entrega'"
              :required="true"
              @value="(e) => (orderData.deliveryTypeCustom = e)"
            />
          </div>
          <b-field style="margin-top: 5px">
            <b-input
              maxlength="200"
              placeholder="Escribe los comentarios adicionales"
              type="textarea"
              v-model="orderData.comments"
            ></b-input>
          </b-field>
        </div>
        <div class="contCamp" v-if="getVendor.maxDeliveriesPerHour">
          <div
            @click="
              !openDaysDelivery
                ? (openDaysDelivery = true)
                : (openDaysDelivery = false)
            "
            class="ContRadio"
          >
            <span class="text segoe size-16">Programar mi pedido</span>
            <div v-show="!openDaysDelivery">
              <i class="fas fa-chevron-down"></i>
            </div>
            <div v-show="openDaysDelivery">
              <i class="fas fa-chevron-up"></i>
            </div>
          </div>
          <span class="text segoeBold size-15 margin-top-10" v-if="openDaysDelivery">Día</span>
          <div class="ContDaysDelivery" v-dragscroll.x="true" v-if="openDaysDelivery">
            <div
              :class="{ active: dayD == date.fecha }"
              :key="index + '-dateF'"
              @click="getAvailability(date.fecha)"
              class="itemDayDilevery"
              v-for="(date, index) in daysDelivery"
            >
              <span class="text segoeBold size-12 pointer">
                {{
                dateConvert(date.fecha)
                }}
              </span>
              <span class="text segoe size-10 pointer">{{ date.fecha }}</span>
            </div>
          </div>
          <span
            class="text segoeBold size-15 margin-top-10"
            v-if="Object.values(availability).length > 0 && openDaysDelivery"
          >Hora</span>
          <div
            class="ContDaysDelivery"
            v-dragscroll.x="true"
            v-if="Object.values(availability).length > 0 && openDaysDelivery"
          >
            <div
              :class="{ active: orderData.timeDelivery == avail.time }"
              :key="'Available#' + a"
              @click="!avail.isAvail ? null : selectTimeDelivery(avail.time)"
              class="itemDayDilevery hora"
              v-for="(avail, a) in availability"
            >
              <span class="text segoeBold size-10 pointer">
                {{
                new Date(avail.time) | moment("hh:mm A")
                }}
              </span>
              <span class="text segoeBold size-10 pointer" v-if="!avail.isAvail">No Disponible</span>
            </div>
          </div>
          <div v-if="showNotAvailability">
            <span class="notAvailability">* No hay disponibilidad para esta fecha</span>
          </div>
        </div>
        <div class="contCamp" v-if="getVendor.closed == 0 && sectionP == 1 && !showPay">
          <div class="payment-cont" id="paymentEndOrder">
            <span
              class="text bold segoe size-19 margin-bot-10 margin-top-10"
            >Selecciona un método de pago</span>
            <br />
            <span
              class="text bold segoe size-12 margin-bot-10"
            >Selecciona el método de pago que prefieras</span>
            <div class="tabs-cont">
              <b-tabs v-model="activeTab">
                <b-tab-item
                  :key="'metPay#' + index"
                  :label="option.payMethod"
                  v-for="(option, index) in getVendor.onlinePay"
                >
                  <div class="pay-cont" v-if="tabSelected.provider == 'contraentrega'">
                    <span class="text bold segoe size-16" style="display: flex">
                      <i class="icon-mks icon_cash_menu black" style="margin-right: 5px"></i>
                      Pago en efectivo
                    </span>
                    <b-radio
                      @input="changePayType(option)"
                      native-value="Efectivo"
                      v-model="orderData.formatPayment"
                    ></b-radio>
                  </div>
                  <div class="pay-cont" v-if="tabSelected.provider == 'contraentrega'">
                    <span class="text bold segoe size-16" style="display: flex">
                      <i class="icon-mks black icon_dataPhone" style="margin-right: 5px"></i>
                      Datáfono
                    </span>
                    <b-radio
                      @input="changePayType(tabSelected)"
                      native-value="Datáfono"
                      v-model="orderData.formatPayment"
                    ></b-radio>
                  </div>
                  <!-- Pin -->
                  <div class="pay-cont" v-if="tabSelected.provider == 'others'">
                    <span class="text bold segoe size-16" style="display: flex">
                      <i class="icon-mks black icon_dataPhone" style="margin-right: 5px"></i>
                      Pin
                    </span>
                    <b-radio :native-value="option" @input="validate()" v-model="methodPay"></b-radio>
                  </div>
                  <b-field
                    label="Pin de pago"
                    v-if="
                      typePayment == 'others' &&
                      pinPay != null &&
                      !showPay &&
                      option.provider == 'others'
                    "
                  >
                    <b-input
                      @input="validatePin"
                      autocomplete="off"
                      password-reveal
                      type="password"
                      v-model="pinAccess"
                    ></b-input>
                  </b-field>
                  <!-- Transferencia -->
                  <div class="pay-cont" v-if="option.provider == 'transferencia'">
                    <span class="text bold segoe size-16" style="display: flex">
                      <i class="fas fa-hand-holding-usd" style="margin-right: 5px"></i>
                      Transferencia
                    </span>
                    <b-radio
                      @input="changePayType(tabSelected)"
                      native-value="Transferencia"
                      v-model="orderData.formatPayment"
                    ></b-radio>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
          <payUCardCarta
            :order="orderData"
            @approvedTransactionCard="approvedTransaction($event)"
            @pendingTransactionCard="pendingTransaction"
            v-if="paymentType === 1 && typePayment == 'payU'"
          ></payUCardCarta>
          <payUPseCarta :order="orderData" v-if="paymentType === 2 && typePayment == 'payU'"></payUPseCarta>
          <MpCardCarta
            :order="orderData"
            @approvedTransactionCard="approvedTransaction($event)"
            @cancelTransactionCard="cancelTransaction"
            @pendingTransactionCard="pendingTransaction"
            v-if="paymentType === 1 && typePayment == 'mercadoPago'"
          ></MpCardCarta>
          <MpPseCarta :order="orderData" v-if="paymentType === 2 && typePayment == 'mercadoPago'"></MpPseCarta>
        </div>
        <!-- <div class="btnNext2 continue" v-if="paymentType == 3 || paymentType == 4"  @click="preOrderValidate">
          <span class="text bold size-16" style="color: var(--buttonTextColor);">Realizar el pedido</span>
        </div>-->
        <div
          @click="preOrderValidate"
          class="btnNext2 continue"
          v-if="
            orderData.providerTransaction == 'contraentrega' ||
            orderData.providerTransaction == 'transferencia' ||
            orderData.providerTransaction == 'habitacion'
          "
        >
          <span class="text bold size-16" style="color: var(--buttonTextColor)">Realizar el pedido</span>
        </div>
        <div @click="preOrderValidate" class="btnNext2 continue" v-if="showPay">
          <span class="text bold size-16" style="color: var(--buttonTextColor)">Realizar el pedido</span>
        </div>
      </div>
      <div class="check-in" id="ordenInfoCheck">
        <p class="text bold size-22 margin-bot-10 mobile">Mi orden</p>
        <div class="detail-car">
          <div class="detail-products" id="detailProductCont">
            <div :key="index" class="ProductCont" v-for="(products, index) in arrayProducts">
              <div class="contImgDescrip">
                <div
                  :style="{
                    'background-image': 'url(' + products.image + ')',
                    'background-size': 'cover',
                  }"
                  class="imgProduct"
                  v-if="products.image"
                ></div>
                <div class="contDescript">
                  <span class="text bold size-14 ellipsis">
                    {{
                    products.name
                    }}
                  </span>
                  <div :key="n" class="aditions" v-for="(adition, n) in products.options.products">
                    <p class="text bold size-12">{{ adition.name }}</p>
                    <div :key="i" class="item" v-for="(item, i) in adition.items">
                      <div class="contAdition">
                        <span class="text size-10">{{ item.item }}</span>
                        <span class="text size-10">
                          {{
                          item.price
                          | currency("$ ", 0, { thousandsSeparator: "," })
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="observation" v-if="products.options.observation">
                <span class="text bold size-12">Comentario adicional</span>
                <span class="text size-12">
                  {{
                  products.options.observation
                  }}
                </span>
              </div>
              <div class="contPrice">
                <span class="text bold size-18">
                  {{ products.quantity }} x
                  {{
                  products.totalPrice
                  | currency("$ ", 0, { thousandsSeparator: "," })
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="detail-products">
            <div class="CuponCont">
              <span class="text bold size-12">¿Tienes código de descuento?</span>
              <div>
                <input
                  :disabled="valueCup > 0"
                  @key.esc="orderData.couponCode = null"
                  class="inputcupNew"
                  id="cup"
                  maxlength="12"
                  name="cupon"
                  v-model="orderData.couponCode"
                />
              </div>
              <button
                :disabled="
                  orderData.couponCode == null ||
                  orderData.couponCode.length < 6 ||
                  valueCup > 0
                "
                @click="validateCoupon"
                class="btnCup"
                id="btnApply"
              >Aplicar</button>
            </div>
            <div class="cont-products">
              <p class="text size-16">Subtotal</p>
              <p class="text">
                {{
                orderData.totalPriceOld
                | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
            <div class="cont-products">
              <p class="text">Envio</p>
              <p class="text">
                {{
                orderData.delivery
                | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
            <div
              class="cont-products"
              style="margin-left: 10px"
              v-if="getVendor.messageInCheckout !== undefined"
            >
              <span class="text segoe size-10">• {{ getVendor.messageInCheckout }}</span>
            </div>
            <div class="cont-products">
              <div class="cont-tip">
                <div style="display: flex; align-items: center">
                  <!-- <input type="checkbox" v-model="cup" style="margin-right: 5px;" @click="showCup = !showCup" id="checkCup"> -->
                  <span :class="{ disabled: !cup }" class="text size-16">Cupón</span>
                </div>
                <div style="display: flex; align-items: center">
                  <span :class="{ disabled: !cup }" class="text segoe size-16">
                    -
                    {{
                    valueCup | currency("$ ", 0, { thousandsSeparator: "," })
                    }}
                  </span>
                  <!-- <input v-if="showCup" class="inputTip" style="text-transform: uppercase;" v-model="orderData.couponCode" maxLength="12" name="cupon" id="cup" @key.esc="orderData.couponCode = null">
                  <button v-if="showCup && (orderData.couponCode != null && orderData.couponCode.length >= 6)" id="btnApply" style="margin-left: 10px;" @click="validateCoupon">Aplicar</button>-->
                </div>
              </div>
            </div>
            <div class="cont-products" v-if="getVendor.tipPercent !== undefined">
              <div class="cont-tip">
                <div style="display: flex; align-items: center">
                  <input style="margin-right: 5px" type="checkbox" v-model="tip" />
                  <span :class="{ disabled: !tip }" class="text size-16">Propina</span>
                </div>
                <div style="display: flex; align-items: center">
                  <div @click="tipMount = true" class="animation-fade" v-show="tip && !tipMount">
                    <i class="far fa-edit"></i>
                  </div>
                  <div
                    @click="
                      (tipMount = false),
                        (orderData.tip = orderData.totalPrice * (10 / 100))
                    "
                    class="animation-fade"
                    v-show="tipMount"
                  >
                    <i class="fas fa-times"></i>
                  </div>
                  <span :class="{ disabled: !tip }" class="text segoe size-16" v-if="!tipMount">
                    {{
                    (orderData.totalPrice * (10 / 100))
                    | currency("$ ", 0, { thousandsSeparator: "," })
                    }}
                  </span>
                  <input
                    class="inputTip"
                    id="tp"
                    name="tip"
                    type="number"
                    v-if="tipMount"
                    v-model="orderData.tip"
                  />
                </div>
              </div>
            </div>
            <div class="cont-products">
              <p class="text bold">Total compra</p>
              <p class="text bold total">
                {{
                orderData.totalPrice > 0
                ? parseInt(orderData.totalPrice) +
                parseInt(orderData.delivery) +
                parseInt(orderData.tip)
                : 0 | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
          </div>
          <div
            @click="preOrderValidate"
            class="btn-confirmar"
            v-if="
              (getVendor.minPriceForOrder == undefined ||
                getVendor.minPriceForOrder == 0) &&
              getVendor.onlinePay == undefined
            "
          >
            <p class="text white normal center">
              <i class="fa fa-check"></i>&nbsp;Realizar el pedido
            </p>
          </div>
          <div
            @click="preOrderValidate"
            class="btn-confirmar"
            v-else-if="getVendor.onlinePay == undefined"
          >
            <p class="text white normal center">
              <i class="fa fa-check"></i>&nbsp;Realizar el pedido
            </p>
          </div>
        </div>
      </div>
      <div id="payContMobile">
        <h1 class="mobile" style="font-size: 20px; margin-bottom: 3px;">Mi Pago</h1>
        <div class="contCamp">
          <div class="payment-cont" id="paymentEndOrder">
            <div
              :key="index"
              class="ProductContMyPayment"
              v-for="(products, index) in arrayProducts"
            >
              <div class="product">
                <span class="text bold product-title">{{ products.name }}</span>
                <div class="contPrice">
                  <span class="text bold size-16">
                    {{ products.quantity }} x
                    {{
                    products.totalPrice
                    | currency("$ ", 0, { thousandsSeparator: "," })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="divisor"></div>
            <div
              class="cont-products"
              style="display: flex; flex-direction: row; padding-bottom: 13px"
            >
              <p class="text size-14" style="flex: 1">Subtotal</p>
              <p class="text size-14">
                {{
                orderData.totalPriceOld
                | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
            <div class="cont-products" style="padding-bottom: 13px">
              <div class="cont-tip" style="color: black">
                <div style="display: flex; align-items: center">
                  <span class="text size-14">Descuento</span>
                </div>
                <div style="display: flex; align-items: center">
                  <span class="text segoe size-14">
                    -
                    {{
                    valueCup | currency("$ ", 0, { thousandsSeparator: "," })
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="cont-products"
              style="display: flex; flex-direction: row; padding-bottom: 20px"
            >
              <p class="text" style="flex: 1">Envio</p>
              <p class="text">
                {{
                orderData.delivery
                | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
            <div
              class="cont-products"
              style="
                display: flex;
                flex-direction: row;
                padding-bottom: 10px;
                align-items: center;
              "
            >
              <p class="text size-16" style="flex: 1">Total compra</p>
              <p class="text bold size-21" style="font-family: 'OpenSansBold'">
                {{
                orderData.totalPrice > 0
                ? parseInt(orderData.totalPrice) +
                parseInt(orderData.delivery) +
                parseInt(orderData.tip)
                : 0 | currency("$ ", 0, { thousandsSeparator: "," })
                }}
              </p>
            </div>
            <div class="tabs-cont">
              <div id="tabs-payment">
                <div class="category-cont" id="medium-cont" v-dragscroll.x="true">
                  <div
                    :class="{ selected: option.id === tabSelected.id }"
                    :key="'metPay#' + index"
                    :label="option.payMethod"
                    @click="setTab(option)"
                    class="sub-category"
                    v-for="(option, index) in getVendor.onlinePay"
                  >
                    <p class="text center size-18">{{ option.payMethod }}</p>
                  </div>
                </div>
              </div>
              <div
                class="pay-cont paymentplatform"
                v-if="
                  tabSelected.provider == 'online'
                "
              >
                <nav>
                  <div
                    :class="{
                      selected: creditMethod,
                      'payInfo--cantUse': false,
                    }"
                    @click="creditMethod = true"
                    class="payInfo__tabs"
                  >
                    <div class="paymentsContainer">
                      <div
                        class="borderInside"
                        style="margin-top: 1px; margin-right: 2px; min-height: 58px; transform: none; padding-bottom: 13px; align-items: unset;"
                      >
                        <h1 style="font-size: 12px;">Tarjeta de crédito</h1>
                        <span
                          :class="{
                            cardSelected:
                              cardTypeInfo === 'visa' || cardTypeInfo === 'Visa'
                                ? true
                                : false,
                          }"
                          class="VisaIcon"
                        ></span>
                        <span
                          :class="{
                            cardSelected:
                              cardTypeInfo === 'master' ||
                              cardTypeInfo === 'MasterCard'
                                ? true
                                : false,
                          }"
                          class="masterCardIcon"
                        ></span>
                        <span
                          :class="{
                            cardSelected:
                              cardTypeInfo === 'diners' ||
                              cardTypeInfo === 'Diners'
                                ? true
                                : false,
                          }"
                          class="clubIcon"
                        ></span>
                        <span
                          :class="{
                            cardSelected:
                              cardTypeInfo === 'amex' || cardTypeInfo === 'Amex'
                                ? true
                                : false,
                          }"
                          class="americanExpressIcon"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="{
                      selected: !creditMethod,
                      'payInfo--cantUse': false,
                    }"
                    @click="creditMethod = false"
                    class="payInfo__tabs"
                  >
                    <div
                      class="borderInside"
                      style="margin-bottom: 5px; margin-left: 5px; min-height: 62px; transform: none; padding-bottom: 13px;"
                    >
                      <h1 style="font-size: 12px;">Tarjeta de débito</h1>
                      <span class="PSEIcon"></span>
                    </div>
                  </div>
                </nav>
                <Payment
                  :order="orderData"
                  :type="getVendor.paymentTypeDeliveryPse"
                  v-if="!creditMethod"
                />
                <PaymentCredit
                  :order="orderData"
                  :type="getVendor.paymentTypeDelivery"
                  @approvedTransactionCard="approvedTransaction($event)"
                  @cancelTransactionCard="cancelTransaction"
                  @pendingTransactionCard="pendingTransaction"
                  v-else />
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  padding-top: 40px;
                  padding-bottom: 50px;
                "
                v-if="tabSelected.provider == 'contraentrega'"
              >
                <div
                  class="pay-cont"
                  style="
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="tabSelected.provider == 'contraentrega'"
                >
                  <b-radio
                    @input="changePayType(tabSelected)"
                    native-value="Efectivo"
                    style="margin-bottom: 10px"
                    v-model="orderData.formatPayment"
                  ></b-radio>
                  <span class="text bold segoe size-16" style="display: flex">Pago en efectivo</span>
                </div>
                <div
                  class="pay-cont"
                  style="
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <b-radio
                    @input="changePayType(tabSelected)"
                    native-value="Datáfono"
                    style="margin-bottom: 10px"
                    v-model="orderData.formatPayment"
                  ></b-radio>
                  <span class="text bold segoe size-16" style="display: flex">Pago con tarjeta</span>
                </div>
              </div>

              <!-- Pin -->
              <div class="pay-cont" v-if="tabSelected.provider == 'others'">
                <span class="text bold segoe size-16" style="display: flex">
                  <i class="icon-mks black icon_dataPhone" style="margin-right: 5px"></i>
                  Pin
                </span>
                <b-radio :native-value="tabSelected" @input="validate()" v-model="methodPay"></b-radio>
              </div>
              <b-field
                label="Pin de pago"
                v-if="
                  tabSelected.payMethod == 'Pin' &&
                  pinPay != null &&
                  !showPay &&
                  tabSelected.payMethod == 'Pin'
                "
              >
                <b-input
                  @input="validatePin"
                  autocomplete="off"
                  password-reveal
                  type="password"
                  v-model="pinAccess"
                ></b-input>
              </b-field>
              <!-- Transferencia -->
              <div class="pay-cont" v-if="tabSelected.provider == 'transferencia'">
                <span class="text bold segoe size-16" style="display: flex">
                  <i class="fas fa-hand-holding-usd" style="margin-right: 5px"></i>
                  Transferencia
                </span>
                <b-radio
                  @input="changePayType()"
                  native-value="Transferencia"
                  v-model="orderData.formatPayment"
                ></b-radio>
              </div>
              <Payment
                :type="'habitation'"
                @setOrder="habitationOrder"
                v-if="tabSelected.provider === 'habitacion'"
              />
              <PaymentVariant
                :type="'datosEntrega'"
                @setOrder="habitationOrder"
                v-if="tabSelected.provider === 'datosEntrega'"
              />
            </div>
          </div>
          <payUCardCarta
            :order="orderData"
            @approvedTransactionCard="approvedTransaction($event)"
            @pendingTransactionCard="pendingTransaction"
            v-if="paymentType === 1 && typePayment == 'payU'"
          ></payUCardCarta>
          <payUPseCarta :order="orderData" v-if="paymentType === 2 && typePayment == 'payU'"></payUPseCarta>
          <MpCardCarta
            :order="orderData"
            @approvedTransactionCard="approvedTransaction($event)"
            @cancelTransactionCard="cancelTransaction"
            @pendingTransactionCard="pendingTransaction"
            v-if="paymentType === 1 && typePayment == 'mercadoPago'"
          ></MpCardCarta>
          <MpPseCarta :order="orderData" v-if="paymentType === 2 && typePayment == 'mercadoPago'"></MpPseCarta>
        </div>
      </div>
      <div id="successOrdertMobile">
        <p class="text bold size-23 margin-bot-10 mobile">Mi orden</p>
        <div class="flex-center">
          <img src="@/assets/image/successfullOrder.svg" />
          <span class="text bold size-18">¡Pedido realizado con éxito!</span>
          <span
            class="text segoe size-14"
            style="
              padding-top: 35px;
              text-align: center;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            El ID de tu orden es
            <strong>{{ finalOrderId }}</strong>, con este numero podrás revisa el estado de tu orden.
          </span>
          <div @click="returnGo()" class="btn-G" style="position: initial; margin-top: 50px;">
            <span class="text bold size-16" style="color: var(--buttonTextColor)">Ir a la tienda</span>
          </div>
        </div>
        <p class="text bold size-23 margin-bot-10 mobile"></p>
      </div>
    </div>
    <TransactionPending @clear="clearTime" v-if="showPending" v-model="showPending"></TransactionPending>
    <TransactionDeclined v-if="showDeclined" v-model="showDeclined"></TransactionDeclined>
    <TransactionApproved :idOrder="orderId" v-if="showApproved" v-model="showApproved"></TransactionApproved>
    <b-modal :active.sync="PopUpOpen" id="modal-pop" :can-cancel="false">
      <PopUpGaira @closeModal="PopUpOpen = $event"></PopUpGaira>
    </b-modal>
    <div
      :class="{ readyToSubmit: submitReady }"
      @click="MoveSection()"
      class="btnNext"
      v-if="sectionP == 2 && getVendor.closed == 0 && !showPay"
    >
      <span class="text bold size-16" style="color: var(--buttonTextColor)">Continuar</span>
    </div>
    <div @click="preOrderValidate" class="btnNext" v-if="showPay && sectionP == 3">
      <span class="text bold size-16" style="color: var(--buttonTextColor)">Realizar el pedido</span>
    </div>
    <div
      @click="preOrderValidate"
      class="btnNext"
      v-if="
        (orderData.providerTransaction == 'contraentrega' ||
          orderData.providerTransaction == 'transferencia' ||
          orderData.providerTransaction == 'habitacion') &&
        sectionP == 3
      "
    >
      <span class="text bold size-16" style="color: var(--buttonTextColor)">Realizar el pedido</span>
    </div>
    <div @click="closeSectionMobile()" class="btnClose" v-show="sectionP === 3">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div @click="returnGo()" class="btnBack" v-show="sectionP === 2 || sectionP === 4">
      <i class="fas fa-chevron-left"></i>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomVar from '@/components/CustomVars';
import TransactionPending from '@/components/transactionStatus/TransactionPending';
import TransactionDeclined from '@/components/transactionStatus/TransactionDeclined';
import TransactionApproved from '@/components/transactionStatus/TransactionApproved';
import LocalStorage from 'store';
import credentials from '@/helpers/pay.json';
import PopUpGaira from '@/components/PopUp/PopUpGaira';
import deliveryAddress from '@/components/templateFood/modals/deliveryAddress';
import Selector from '@/components/GeneralSelector.vue';
// import Pagos modeCarta
import payUCardCarta from '@/components/modeCarta/payU/paymentCard';
import payUPseCarta from '@/components/modeCarta/payU/paymentPse';
import MpCardCarta from '@/components/modeCarta/mercadoPago/paymentCard';
import MpPseCarta from '@/components/modeCarta/mercadoPago/paymentPse';
import moment from 'moment';
import _ from 'lodash';
import { gmapApi } from 'vue2-google-maps';
import Payment from '@/components/habitationpayment';
import PaymentVariant from '@/components/deliveryData';
import PaymentCredit from '@/components/categories/creditCardPayment.vue';
export default {
  metaInfo () {
    return {
      title: this.getVendor.displayName,
      link: [
        {
          vmid: 'icon',
          rel: 'icon',
          href: this.getVendor.logo || '/favicon.ico'
        }
      ]
    };
  },
  name: 'Order',
  components: {
    PaymentCredit,
    Payment,
    TransactionPending,
    TransactionDeclined,
    TransactionApproved,
    CustomVar,
    PopUpGaira,
    payUCardCarta,
    payUPseCarta,
    MpCardCarta,
    MpPseCarta,
    PaymentVariant,
    Selector
  },
  data () {
    return {
      deliveryTypes: null,
      showFields: false,
      cup: 0,
      showCup: false,
      loading: false,
      showPse: true,
      valueCup: 0,
      orderData: {
        vendor: {
          id: null,
          name: null,
          vendorId: null,
          logo: null
        },
        sessionId: null,
        name: null,
        phone: null,
        email: null,
        address: null,
        products: [],
        roomNumber: null,
        deliveryTypeCustom: null,
        stateId: 0,
        totalPrice: 0,
        totalPriceOld: 0,
        delivery: 0,
        discountCode: 0,
        couponCode: null,
        tip: 0,
        deliveryType: null,
        comments: null,
        timeDelivery: null,
        providerTransaction: null,
        formatPayment: null
      },
      addressFormat: {
        type: 'Calle',
        numberType: null,
        numberAdress: null,
        number: null,
        options: ''
      },
      street: [
        {
          name: 'Calle'
        },
        {
          name: 'Carrera'
        },
        {
          name: 'Avenida'
        },
        {
          name: 'Avenida Carrera'
        },
        {
          name: 'Avenida Calle'
        },
        {
          name: 'Circular'
        },
        {
          name: 'Circunvalar'
        },
        {
          name: 'Diagonal'
        },
        {
          name: 'Manzana'
        },
        {
          name: 'Transversal'
        },
        {
          name: 'Vía'
        }
      ],
      freeProducts: [],
      payProducts: [],
      normalProducts: [],
      arrayProducts: [],
      orderValid: null,
      delivery: 0,
      methodPay: null,
      origin: 'marketplace',
      pinPay: null,
      pinAccess: null,
      finalOrderId: null,
      showPay: false,
      load: false,
      showPending: false,
      showDeclined: false,
      showApproved: false,
      date: null,
      minDatetime: moment().toISOString(),
      availability: [],
      orderId: null,
      config: {
        toolbarColor: '#151E26',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff',
        textColorMap: '#808080',
        circleColorMap: '#132b2e'
      },
      modalTip: false,
      showNotAvailability: false,
      intervalPending: null,
      blocked: false,
      PopUpOpen: false,
      tip: true,
      tipMount: false,
      activeTab: 0,
      tabSelected: {},
      radioCard: null,
      radioTable: null,
      sectionP: 1,
      daysDelivery: [],
      dayD: null,
      openDaysDelivery: false,
      optionAdressShow: true,
      center: { lat: 0, lng: 0 },
      zoom: 18,
      markers: [],
      creditMethod: true
    };
  },
  mounted () {
    if (LocalStorage.get('couponCode') && LocalStorage.get('couponCodeData')) {
      LocalStorage.remove('couponCode');
      LocalStorage.remove('couponCodeData');
    }
    if (LocalStorage.get('showFields')) {
      this.showFields = LocalStorage.get('showFields');
    }
    if (this.tipStorage > 0) {
      this.orderData.tip = parseInt(this.tipStorage);
    } else {
      this.tip = false;
      this.tipMount = false;
      this.orderData.tip = 0;
    }
    this.$store.commit('setTypePayment', null);
    const trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        const transactionId = LocalStorage.get('processId');
        if (transactionId !== undefined) {
          this.blocked = true;
          this.validatePaymentId(transactionId);
        } else {
          this.blocked = false;
          const actual = window.location.href;
          const common = actual.replace('?source_utm=mp', '');
          window.location.href = common;
        }
      } else {
        this.showStatusTransaction(trans);
      }
    }
    this.getStatusPSE();
    this.orderData.address = LocalStorage.get('address_Coverage_client');
    for (let i = 0; i <= 20; i++) {
      this.daysDelivery.push({
        fecha: this.$moment().add(i, 'days').format('DD-MMM-YYYY')
      });
    }
    this.deliveryTypes = this.getVendor.deliveryTypesSelected;
    if (this.recogerTienda()) {
      this.orderData.address = 'Recoger en Restaurante';
      this.orderData.deliveryType = 'Recoger en Restaurante';
      this.optionAdressShow = false;
    }
    if (
      this.getVendor.deliveryPriceDistanceActive === 1 &&
      !this.recogerTienda()
    ) {
      const thes = this;
      setTimeout(function () {
        thes.getLatLong();
      }, 500);
    }
    this.getInfoVendor(this.$route.params.id);
  },
  created () {
    setTimeout(() => {
      this.sectionP = 1;
      this.MoveSection();
    }, 1000);
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      getCart: 'getCart',
      getVendor: 'getVendor',
      paymentType: 'paymentType',
      typePayment: 'typePayment',
      tipStorage: 'tipStorage',
      addressCoverage: 'addressCoverage',
      cardTypeInfo: 'getCardTypeInfo'
    }),
    deliveryType () {
      return this.orderData.deliveryType;
    },
    submitReady () {
      if (this.orderData.name && this.orderData.address && this.orderData.email && this.orderData.phone) {
        return true;
      }
      return false;
    }
  },
  methods: {
    habitationOrder (info) {
      this.orderData.sessionId = this.getCart.sessionId;
      this.orderData.vendor.id = this.getVendor._id;
      this.orderData.vendor.name = this.getVendor.displayName;
      this.orderData.vendor.logo = this.getVendor.logo;
      this.orderData.products = this.arrayProducts;
      this.orderData.delivery = this.delivery;
      this.orderData.origin = this.origin;
      const order = { ...this.orderData, hotelData: { ...info } };
      this.setNewOrder(order);
    },
    setTab (option) {
      this.tabSelected = option;
      this.methodPay = null;
      this.showPay = false;
      this.orderData.formatPayment = null;
      this.orderData.providerTransaction = null;
      this.$store.commit('setTypePayment', null);
      this.$store.commit('setPaymentType', null);
    },
    validatePaymentId (id) {
      this.loading = true;
      this.$http
        .post('/marketplace/payment/status', { processId: id })
        .then(({ data }) => {
          if (data.code === 200) {
            if (data.state === 'rejected') {
              LocalStorage.remove('processId');
              this.showDeclined = true;
            }
            if (data.state === 'approved') {
              this.obtainOrder(id);
            }
            if (data.state === 'in_progress' || data.state === 'pending') {
              this.showPending = true;
              const instance = this;
              this.intervalPending = setInterval(() => {
                instance.validatePaymentId(id);
              }, 10000);
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(
              err.request.response,
              err.request.status
            ),
            position: 'is-top',
            type: 'is-danger'
          });
        });
    },
    obtainOrder (id) {
      this.$http
        .get('/marketplace/payment/order/' + id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.orderId = data.orderId;
            this.showApproved = true;
            LocalStorage.remove('processId');
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Orden no existe!',
              position: 'is-top',
              type: 'is-danger'
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(
              err.request.response,
              err.request.status
            ),
            position: 'is-top',
            type: 'is-danger'
          });
        });
    },
    showStatusTransaction (transaction) {
      if (
        parseInt(transaction.polTransactionState) === 4 &&
        parseInt(transaction.polResponseCode) === 1
      ) {
        this.showApproved = true;
      }
      if (
        parseInt(transaction.polTransactionState) === 6 &&
        (parseInt(transaction.polResponseCode) === 4 ||
          parseInt(transaction.polResponseCode) === 5)
      ) {
        this.showDeclined = true;
      }
      if (
        (parseInt(transaction.polTransactionState) === 12 ||
          parseInt(transaction.polTransactionState) === 14) &&
        (parseInt(transaction.polResponseCode) === 9994 ||
          parseInt(transaction.polResponseCode) === 25)
      ) {
        this.showPending = true;
      }
    },
    clearTime () {
      clearInterval(this.intervalPending);
      this.intervalPending = null;
    },
    pendingTransaction () {
      this.showPending = true;
    },
    cancelTransaction () {
      this.showDeclined = true;
    },
    approvedTransaction (id) {
      this.orderId = id;
      this.showApproved = true;
    },
    validatePin () {
      console.log(this.pinPay);
      console.log(this.pinAccess);
      if (this.pinPay + '' === this.pinAccess + '') {
        this.showPay = true;
      }
    },
    getStatusPSE () {
      this.$http.get('marketplace/payment/banks/pse').then(({ data }) => {
        if (data.code === 200) {
          const pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.isProgramnig = 1;
            }
          } else {
            times.closed = 1;
            times.isProgramnig = 0;
          }
        }
      } else {
        times.closed = 1;
        times.isProgramnig = 0;
      }
      return times;
    },
    getInfoVendor (id) {
      this.loading = true;
      const thes = this;
      this.$http
        .get('/marketplace/client/vendor/' + id)
        .then(({ data }) => {
          this.isAvail(data.data);
          this.$store.commit('setVendor', data.data);
          this.loading = false;
          if (!this.blocked) {
            if (this.getCart.products) {
              this.dataTransform();
            } else {
              setTimeout(function () {
                if (thes.getCart.products) {
                  thes.dataTransform();
                } else {
                  setTimeout(function () {
                    if (thes.getCart.products) {
                      thes.dataTransform();
                    }
                  }, 500);
                }
              }, 500);
            }
          } else {
            this.load = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: err,
            position: 'is-top',
            type: 'is-danger'
          });
        })
        .finally(() => {
          if (this.getVendor.onlinePay !== undefined) {
            const mp = _.find(this.getVendor.onlinePay, {
              provider: 'mercadoPago'
            });
            if (mp !== undefined) {
              let accountId = 'precompro';
              if (this.getVendor.accountName !== undefined) {
                if (this.getVendor.accountName !== 0) {
                  accountId = this.getVendor.accountName;
                }
              }
              window.Mercadopago.setPublishableKey(
                credentials[process.env.NODE_ENV][accountId]
              );
            }
          }
        });
    },
    pushNormal (products) {
      Object.values(products).map((item) => {
        const arr = {};
        let totalPrice = 0;
        arr._id = item._id;
        arr.name = item.name;
        arr.price = item.price;
        arr.quantity = item.quantity;
        if (item.options !== undefined) {
          arr.options = item.options;
        }
        if (item.percentOff == null || item.percentOff === undefined) {
          totalPrice = item.price * item.quantity;
        } else {
          const discount = item.price - (item.price * item.percentOff) / 100;
          arr.discountPrice = discount;
          totalPrice = discount * item.quantity;
        }
        arr.totalPrice = totalPrice;
        arr.image = item.image;
        this.arrayProducts.push(arr);
      });
    },
    pushDeals (products, pay) {
      Object.values(products).map((item) => {
        let price = item.price;
        if (pay === false) {
          price = 0;
        }
        const arr = {};
        let totalPrice = 0;
        arr._id = item._id;
        arr.name = item.name;
        arr.price = price;
        arr.quantity = item.quantity;
        arr.deal = item.deal[0].name;
        totalPrice = price * item.quantity;
        arr.totalPrice = totalPrice;
        arr.image = item.image;
        if (item.options !== undefined) {
          arr.options = item.options;
        }
        this.arrayProducts.push(arr);
      });
    },
    dataTransform () {
      const copyTest = JSON.parse(JSON.stringify(this.getCart.products));
      this.implementDeals(copyTest);
      this.pushNormal(this.normalProducts);
      this.pushDeals(this.payProducts, true);
      this.pushDeals(this.freeProducts, false);
      this.totalPrice(this.arrayProducts);
      if (
        this.getVendor.payDeliveryBefore === undefined ||
        this.getVendor.payDeliveryBefore === 0
      ) {
        if (this.getVendor.deliveryPrice !== undefined) {
          if (this.getVendor.deliveryPrice.type === 'fix') {
            this.delivery = this.getVendor.deliveryPrice.price;
          } else if (this.getVendor.deliveryPrice.type === 'percent') {
            this.delivery =
              (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) /
              100;
          }
        }
      } else if (
        this.getVendor.payDeliveryBefore <= this.orderData.totalPrice
      ) {
        this.delivery = 0;
      } else if (
        this.getVendor.payDeliveryBefore >= this.orderData.totalPrice
      ) {
        if (this.getVendor.deliveryPrice.type === 'fix') {
          this.delivery = this.getVendor.deliveryPrice.price;
        } else if (this.getVendor.deliveryPrice.type === 'percent') {
          this.delivery =
            (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) /
            100;
        }
      }
      this.orderData.sessionId = this.getCart.sessionId;
      this.orderData.vendor.id = this.getVendor._id;
      this.orderData.vendor.vendorId = this.getVendor.accountName || 0;
      this.orderData.vendor.name = this.getVendor.displayName;
      this.orderData.vendor.logo = this.getVendor.logo;
      this.orderData.products = this.arrayProducts;
      this.orderData.delivery = this.delivery;
      this.orderData.origin = this.origin;
      if (
        LocalStorage.get('couponCode') !== undefined &&
        LocalStorage.get('couponCodeData') !== undefined
      ) {
        this.orderData.couponCode = window.atob(LocalStorage.get('couponCode'));
        this.valueCup = parseInt(
          window.atob(LocalStorage.get('couponCodeData'))
        );
        this.orderData.totalPriceOld = this.orderData.totalPrice;
        this.orderData.totalPrice = this.orderData.totalPrice - this.valueCup;
        if (this.orderData.totalPrice < 0) {
          this.orderData.totalPrice = 0;
        }
        this.orderData.discountCode = this.valueCup;
        setTimeout(() => {
          document.getElementById('checkCup').style.display = 'none';
        }, 100);
      }
      this.load = true;
    },
    totalPrice (products) {
      let total = 0;
      Object.values(products).map((item) => {
        total += item.totalPrice;
      });
      // this.totalPrice = total
      this.orderData.totalPrice = total;
      this.orderData.totalPriceOld = total;
    },
    preOrderValidate () {
      const instance = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          instance.orderData.sessionId = instance.getCart.sessionId;
          instance.orderData.vendor.id = instance.getVendor._id;
          instance.orderData.vendor.name = instance.getVendor.displayName;
          instance.orderData.vendor.logo = instance.getVendor.logo;
          instance.orderData.products = instance.arrayProducts;
          instance.orderData.delivery = instance.delivery;
          instance.orderData.origin = instance.origin;
          instance.setNewOrder(instance.orderData);
        }
      });
    },
    validate () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.methodPay = null;
          this.showPay = false;
          this.orderData.formatPayment = null;
          this.orderData.providerTransaction = null;
          this.$store.commit('setTypePayment', null);
          this.$store.commit('setPaymentType', null);
        } else {
          this.orderData.providerTransaction = this.methodPay.provider;
          this.$store.commit('setTypePayment', this.methodPay.provider);
          if (
            this.methodPay.provider === 'others' &&
            this.methodPay.pin !== undefined
          ) {
            this.pinPay = this.methodPay.pin;
          } else {
            this.pinPay = null;
            this.pinAccess = null;
            this.showPay = false;
          }
        }
      });
    },
    validate2 () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.orderData.deliveryType = null;
        } else {
          if (
            this.orderData.deliveryType === 'Domicilio' &&
            !this.deliveryconfirm()
          ) {
            this.openCoverage();
          }
        }
      });
    },
    validateCoupon () {
      this.loading = true;
      this.orderData.couponCode = this.orderData.couponCode.toUpperCase();
      this.$http
        .post('marketplace/promoCode/usePromoCodeForOrder', {
          vendorId: this.getVendor._id,
          promoCode: this.orderData.couponCode,
          costOrder: this.orderData.totalPrice
        })
        .then(({ data }) => {
          if (data.discount > 0) {
            this.$buefy.toast.open({
              duration: 3000,
              message: data.message,
              position: 'is-top',
              type: 'is-success'
            });
            // document.getElementById('checkCup').style.display = 'none';
            this.showCup = false;
            if (data.typeCode === 'percent') {
              this.valueCup = (this.orderData.totalPrice * data.discount) / 100;
              this.orderData.totalPriceOld = this.orderData.totalPrice;
              this.orderData.totalPrice =
                this.orderData.totalPrice - this.valueCup;
              this.orderData.discountCode = this.valueCup;
              LocalStorage.set('couponCodeData', window.btoa(this.valueCup));
            }
            if (data.typeCode === 'fixCar') {
              this.valueCup = data.discount;
              this.orderData.totalPriceOld = this.orderData.totalPrice;
              this.orderData.totalPrice =
                this.orderData.totalPrice - data.discount;
              if (this.orderData.totalPrice < 0) {
                this.orderData.totalPrice = 0;
              }
              this.orderData.discountCode = this.valueCup;
              LocalStorage.set('couponCodeData', window.btoa(data.discount));
            }
            if (data.typeCode === 'fixProduct') {
              this.valueCup = data.discount;
              this.orderData.totalPriceOld = this.orderData.totalPrice;
              this.orderData.totalPrice =
                this.orderData.totalPrice - data.discount;
              this.orderData.discountCode = this.valueCup;
              LocalStorage.set('couponCodeData', window.btoa(data.discount));
            }
            LocalStorage.set(
              'couponCode',
              window.btoa(this.orderData.couponCode)
            );
          } else {
            this.orderData.couponCode = null;
            this.$buefy.toast.open({
              duration: 3000,
              message: data.message,
              position: 'is-top',
              type: 'is-danger'
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$parent.listErrors(
              err.request.response,
              err.request.status
            ),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      this.loading = false;
    },
    setNewOrder (order) {
      this.loading = true;
      if (order.timeDelivery == null) {
        delete order.timeDelivery;
      }
      order.address = order.address + ' ' + this.addressFormat.options;
      order.products = this.arrayProducts;
      if (order.deliveryType === null || order.deliveryType === '') {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'El tipo de entrega es obligatorio',
          position: 'is-top',
          type: 'is-danger'
        });
      } else {
        this.$http
          .post('marketplace/client/order', order)
          .then(({ data }) => {
            if (data.cart) {
              this.$store.commit('setCart', []);
            }
            this.$buefy.toast.open({
              duration: 3000,
              message: data.message,
              position: 'is-top',
              type: 'is-success'
            });
            LocalStorage.remove('couponCode');
            LocalStorage.remove('couponCodeData');
            document.getElementById('successOrdertMobile').style.display =
              'flex';
            document.getElementById('payContMobile').style.display = 'none';
            this.finalOrderId = data.data.orderId;
            this.sectionP = 4;
            // this.$router.push('/' + data.data.orderId + '/orderQuery');
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(
                err.request.response,
                err.request.status
              ),
              position: 'is-top',
              type: 'is-danger'
            });
          });
      }
    },
    implementDeals (productsData) {
      let dosXuno = [];
      let tresXdos = [];
      const thes = this;
      _.forEach(productsData, function (pd) {
        if (Object.keys(pd.deal).length > 0) {
          if (pd.deal[0].type === '2x1') {
            dosXuno.push(pd);
          }
          if (pd.deal[0].type === '3x2') {
            tresXdos.push(pd);
          }
        } else {
          thes.normalProducts.push(pd);
        }
      });
      dosXuno = _.orderBy(dosXuno, ['price'], ['desc']);
      tresXdos = _.orderBy(tresXdos, ['price'], ['desc']);
      if (Object.keys(dosXuno).length > 0) {
        this.get2x1(dosXuno);
      }
      if (Object.keys(tresXdos).length > 0) {
        this.get3x2(tresXdos);
      }
    },
    get2x1 (dosXuno) {
      let quantityAll = 0;
      let flagQ = 0;
      const thes = this;
      _.forEach(dosXuno, function (item2x1) {
        quantityAll += item2x1.quantity;
      });
      let halfValue = Math.ceil(quantityAll / 2);
      _.forEach(dosXuno, function (item2x1) {
        if (halfValue > 0) {
          if (item2x1.quantity <= halfValue) {
            const itemToPush = JSON.parse(JSON.stringify(item2x1));
            thes.payProducts.push(itemToPush);
            halfValue = halfValue - itemToPush.quantity;
          } else {
            flagQ = item2x1.quantity - halfValue;
            const itemToPush2 = JSON.parse(JSON.stringify(item2x1));
            itemToPush2.quantity = halfValue;
            thes.payProducts.push(itemToPush2);
            const itemToPush3 = JSON.parse(JSON.stringify(item2x1));
            itemToPush3.quantity = flagQ;
            thes.freeProducts.push(itemToPush3);
            halfValue = 0;
          }
        } else {
          const itemToPush = JSON.parse(JSON.stringify(item2x1));
          thes.freeProducts.push(itemToPush);
        }
      });
    },
    get3x2 (tresXdos) {
      let quantityAll = 0;
      let flagQ = 0;
      const thes = this;
      _.forEach(tresXdos, function (item3x2) {
        quantityAll += item3x2.quantity;
      });
      let thirdValue = 0;
      if (quantityAll === 3 || quantityAll === 2) {
        thirdValue = 2;
      } else {
        thirdValue = Math.ceil((quantityAll / 3) * 2);
      }
      _.forEach(tresXdos, function (item3x2) {
        if (thirdValue > 0) {
          if (item3x2.quantity <= thirdValue) {
            const itemToPush = JSON.parse(JSON.stringify(item3x2));
            thes.payProducts.push(itemToPush);
            thirdValue = thirdValue - itemToPush.quantity;
          } else {
            flagQ = item3x2.quantity - thirdValue;
            const itemToPush2 = JSON.parse(JSON.stringify(item3x2));
            itemToPush2.quantity = thirdValue;
            thes.payProducts.push(itemToPush2);
            const itemToPush3 = JSON.parse(JSON.stringify(item3x2));
            itemToPush3.quantity = flagQ;
            thes.freeProducts.push(itemToPush3);
            thirdValue = 0;
          }
        } else {
          const itemToPush = JSON.parse(JSON.stringify(item3x2));
          thes.freeProducts.push(itemToPush);
        }
      });
    },
    // setTip(num){
    //   if(this.orderData.tip == num ){
    //     this.orderData.tip = 0
    //   } else {
    //     this.orderData.tip = num
    //   }
    // },
    getAvailability (date) {
      this.showNotAvailability = false;
      this.orderData.timeDelivery = null;
      if (date != null && date !== '') {
        this.dayD = date;
        const data = {};
        data.date = moment(date).format('YYYY-MM-DD');
        data.vendorId = this.getVendor._id;
        this.loading = true;
        this.$http
          .post('marketplace/client/search/availability', data)
          .then(({ data }) => {
            this.loading = false;
            if (Object.keys(data.data).length === 0) {
              this.showNotAvailability = true;
            }
            this.availability = data.data;
            for (let i = 0; i < this.availability.length; i++) {
              if (this.availability[i].isAvail === 1) {
                this.orderData.timeDelivery = this.availability[i].time;
                break;
              }
            }
          })
          .catch((err) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(
                err.request.response,
                err.request.status
              ),
              position: 'is-top',
              type: 'is-danger'
            });
            this.loading = false;
          });
      }
    },
    changePayTypeMP (type, num) {
      this.methodPay = type;
      this.validate();
      this.$store.commit('setPaymentType', num);
    },
    changePayType () {
      this.methodPay = this.tabSelected;
      // this.$store.commit('setPaymentType', type.provider);
      this.validate();
    },
    MoveSection () {
      if (this.sectionP === 1) {
        this.sectionP = 2;
        document.getElementById('ordenInfoCheck').style.display = 'none';
        document.getElementById('successOrdertMobile').style.display = 'none';
        document.getElementById('infoTotal').style.display = 'flex';
      } else if (this.sectionP === 2) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.sectionP = 3;
            document.getElementById('successOrdertMobile').style.display =
              'none';
            document.getElementById('infoTotal').style.display = 'none';
            document.getElementById('payContMobile').style.display = 'flex';
          }
        });
      }
    },
    closeSectionMobile () {
      if (this.sectionP === 3) {
        this.methodPay = null;
        this.showPay = false;
        this.orderData.formatPayment = null;
        this.orderData.providerTransaction = null;
        this.$store.commit('setTypePayment', null);
        this.$store.commit('setPaymentType', null);
        this.sectionP = 2;
        document.getElementById('payContMobile').style.display = 'none';
        document.getElementById('infoTotal').style.display = 'flex';
      } else if (this.sectionP === 2) {
        this.sectionP = 1;
        document.getElementById('infoTotal').style.display = 'none';
        document.getElementById('ordenInfoCheck').style.display = 'flex';
      }
    },
    dateConvert (date) {
      return this.$moment(date).lang('es').format('dddd');
    },
    selectTimeDelivery (time) {
      this.orderData.timeDelivery = time;
    },
    openCoverage () {
      const thes = this;
      this.$buefy.modal.open({
        parent: thes,
        component: deliveryAddress,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        customClass: 'modalCoverage',
        props: {
          idV: thes.$route.params.id
        },
        events: {
          setTienda (event) {
            thes.orderData.deliveryType = 'Recoger en Restaurante';
            thes.orderData.address = 'Recoger en Restaurante';
            thes.optionAdressShow = false;
          },
          setAddress (event) {
            thes.orderData.deliveryType = 'Domicilio';
            thes.orderData.address = event;
            thes.optionAdressShow = true;
            if (thes.getVendor.deliveryPriceDistanceActive === 1) {
              thes.getLatLong();
            }
          }
        }
      });
    },
    deliveryconfirm () {
      if (
        LocalStorage.get('center_Coverage_client') &&
        LocalStorage.get('address_Coverage_client')
      ) {
        return true;
      } else {
        return false;
      }
    },
    recogerTienda () {
      if (LocalStorage.get('tienda_Coverage_client')) {
        return true;
      } else {
        return false;
      }
    },
    getGeolocalization () {
      const instance = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const marker = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          instance.center = marker;
        });
      }
    },
    rad (x) {
      return (x * Math.PI) / 180;
    },
    getDistance (lat1, lat2, lng1, lng2) {
      const R = 6378137;
      const dLat = this.rad(lat2 - lat1);
      const dLong = this.rad(lng2 - lng1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(lat1)) *
        Math.cos(this.rad(lat2)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return (d * 0.001).toFixed(1);
    },
    calculateDelivery (d) {
      if (d < this.getVendor.maxDeliveryDistance) {
        const arr = this.getVendor.priceDeliveryDistance.sort(this.compare);
        for (let i = 0; i < arr.length; i++) {
          if (d < arr[i].distance) {
            this.orderData.delivery = arr[i].price;
            break;
          }
        }
      } else {
        this.orderData.delivery = this.getVendor.maxDeliveryDistance.price;
      }
    },
    compare (a, b) {
      const bandA = a.distance;
      const bandB = b.distance;
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    },
    getLatLong () {
      const thes = this;
      thes.getGeolocalization();
      const address1 = LocalStorage.get('center_Coverage_client');
      let address2 = thes.getVendor.addressRestaurant;
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ address: address2 }, (results, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const data = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          };
          address2 = data;
          const distancia = thes.getDistance(
            address1.lat,
            address2.lat,
            address1.lng,
            address2.lng
          );
          thes.calculateDelivery(distancia);
        }
      });
    },
    returnGo () {
      if (this.$route.path.split('/')[2] === 'restaurant') {
        this.home();
      }
      if (this.$route.path.split('/')[2] === 'order') {
        window.location.href =
          '/' + this.$route.path.split('/')[1] + '/restaurant';
      }
    }
  },
  watch: {
    deliveryType () {
      if (this.deliveryType === 'Recoger en Restaurante') {
        this.delivery = 0;
        this.orderData.delivery = this.delivery;
      } else {
        if (
          this.getVendor.payDeliveryBefore === undefined ||
          this.getVendor.payDeliveryBefore === 0
        ) {
          if (this.getVendor.deliveryPrice.type === 'fix') {
            this.delivery = this.getVendor.deliveryPrice.price;
          } else if (this.getVendor.deliveryPrice.type === 'percent') {
            this.delivery =
              (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) /
              100;
          }
        } else if (
          this.getVendor.payDeliveryBefore <= this.orderData.totalPrice
        ) {
          this.delivery = 0;
        } else if (
          this.getVendor.payDeliveryBefore >= this.orderData.totalPrice
        ) {
          if (this.getVendor.deliveryPrice.type === 'fix') {
            this.delivery = this.getVendor.deliveryPrice.price;
          } else if (this.getVendor.deliveryPrice.type === 'percent') {
            this.delivery =
              (this.orderData.totalPrice * this.getVendor.deliveryPrice.price) /
              100;
          }
        }
        this.orderData.delivery = this.delivery;
      }
    },
    getVendor () {
      if (Object.keys(this.getVendor).length > 0) {
        if (this.getVendor.configuration !== undefined) {
          this.showCustom = false;
          if (this.getVendor.configuration.toolbarColor !== undefined) {
            this.config.toolbarColor =
              this.getVendor.configuration.toolbarColor;
          }
          if (this.getVendor.configuration.backCardProduct !== undefined) {
            this.config.backCardProduct = this.getVendor.configuration.backCardProduct;
          }
          if (this.getVendor.configuration.textColorDescriptionCard !== undefined) {
            this.config.textColorDescriptionCard = this.getVendor.configuration.textColorDescriptionCard;
          }
          if (this.getVendor.configuration.textColorPriceCard !== undefined) {
            this.config.textColorPriceCard = this.getVendor.configuration.textColorPriceCard;
          }
          if (this.getVendor.configuration.footerColor !== undefined) {
            this.config.footerColor = this.getVendor.configuration.footerColor;
          }
          if (this.getVendor.configuration.footerTextColor !== undefined) {
            this.config.footerTextColor =
              this.getVendor.configuration.footerTextColor;
          }
          if (this.getVendor.configuration.buttonColor !== undefined) {
            this.config.buttonColor = this.getVendor.configuration.buttonColor;
          }
          if (this.getVendor.configuration.buttonTextColor !== undefined) {
            this.config.buttonTextColor =
              this.getVendor.configuration.buttonTextColor;
          }
          if (this.getVendor.configuration.textColorMap !== undefined) {
            this.config.textColorMap =
              this.getVendor.configuration.textColorMap;
          }
          if (this.getVendor.configuration.circleColorMap !== undefined) {
            this.config.circleColorMap =
              this.getVendor.configuration.circleColorMap;
          }
          this.showCustom = true;
        }
      }
    },
    typePayment () {
      this.orderData.address =
        this.orderData.address + ' ' + this.addressFormat.options;
    },
    tip () {
      if (!this.tip) {
        this.$store.commit('setTipStorage', 0);
        this.tipMount = false;
        this.orderData.tip = 0;
      } else {
        this.$store.commit(
          'setTipStorage',
          this.orderData.totalPrice * (10 / 100)
        );
        this.tipMount = false;
        this.orderData.tip = this.orderData.totalPrice * (10 / 100);
      }
    },
    activeTab () {
      this.methodPay = null;
      this.showPay = false;
      this.orderData.formatPayment = null;
      this.orderData.providerTransaction = null;
      this.$store.commit('setTypePayment', null);
      this.$store.commit('setPaymentType', null);
    }
  }
};
</script>
<style lang="scss">
#tabs-payment {
  width: 100%;
  position: relative;
  .category-cont {
    display: flex;
    -webkit-box-pack: center;
    padding: 10px 0;
    margin: unset;
    position: relative;
    // overflow: auto;
    flex-direction: column;
    gap: 0.4rem;
    // scroll-behavior: smooth;
    animation: fadeSlow 0.5s;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #324045;
      letter-spacing: 0.7px;
    }
    .sub-category {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 16px;
      min-width: 49%;
      height: 40px;
      border-radius: 6px;
      border: solid 1px #324045;
      color: #324045;
      cursor: pointer;
      &.selected {
        background-color: var(--buttonBackground);
        // background-color: #324045;
        p {
          color: var(--buttonTextColor);
          // color: #f6e5ce;
        }
      }
      .text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
      &.active {
        background: var(--buttonBackground);
        // border-color: var(--buttonBackground);
        // background: var(--buttonBackground);
        .text {
          color: var(--buttonTextColor);
          font-weight: 900;
        }
      }
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }
}
#payContMobile {
  font-family: "OpenSans";
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #324045;
    text-align: center;
  }
}
#order {
  .hide {
    display: none;
  }
  position: relative;
  .left {
    min-width: 364 !important;
    width: 40% !important;
    padding: 0 10px 30px 10px;
    .field {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 260px;
      .label {
        font-family: bold;
        color: #777;
      }
    }
    .column {
      text-align: -webkit-center;
    }
  }

  .rigth {
    min-width: 59% !important;
    width: 59% !important;
    flex-direction: column !important;
  }

  .imagenVendor {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #e5edef;
    background-position: center;
    background-size: cover;
  }

  .w-flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: Poppins-SemiBold, Helvetica, Arial, sans-serif;
    padding: 10px 10px 20px;
    margin-bottom: 10px;
  }

  .nameVendor {
    margin-left: 16px;
    font-size: 28px;
    letter-spacing: -1px;
    &-bbt {
      border-bottom: 1px solid rgba(170, 170, 170, 0.5);
    }
  }
  .input-dec {
    border-radius: 6px;
    width: -webkit-fill-available;
    border: 1px solid #cfcdcd;
    padding: 5px 10px;
    font-weight: 500;
    color: rgb(76, 75, 75);
    font-size: 15px;
    min-width: 100px;
    height: 40px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: lightgray;
    }
  }
  .columns {
    flex-wrap: wrap;
  }
  .rigth {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: inherit !important;
    .products {
      height: 200px !important;
      .img-product {
        flex: 2 !important;
      }
    }
  }
  .div-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 10px;
    color: #000000;
  }
  .content-collapse {
    display: flex;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    border-radius: 5px;
    .card-header {
      background: #011f3f;
    }
  }
  .card {
    width: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    .card-content {
      display: flex;
      padding: 10px 10px 10px 10px;
      flex-flow: wrap;
      flex-direction: column;
      .cont-card {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }
    }
  }
  .imagenVendor {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  }
  #icon-input {
    position: absolute;
    left: 10px;
    top: 44px;
  }
  .text {
    &.width-input {
      width: 230px;
    }
  }
  .invalid-order {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .cont-alert {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    #alert {
      width: 70px;
      height: 70px;
      margin-right: 15px;
    }
  }
  #tdc-pay {
    position: absolute;
    left: 56px;
    top: 4px;
  }
  #icon-campana {
    position: absolute;
    left: 40px;
    top: 4px;
  }
  .car-detail {
    .img-food {
      height: 100px;
      width: 150px;
      border-radius: 10px;
    }
  }
  .modal-card-body {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 364px;
    .field {
      width: 100%;
    }
    .btn-add-tip {
      background: var(--buttonBackground);
      padding: 4px 10px;
      color: var(--buttonTextColor);
      border-radius: 5px;
      bottom: 10px;
      display: flex;
      width: 70%;
      max-width: 150px;
      justify-content: center;
      align-items: center;
      animation: fade 0.5s;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
      cursor: pointer;
      &:hover {
        transform: translateY(-1px);
      }
      &:active {
        transform: translateY(2px);
      }
      .iconColor {
        color: var(--buttonTextColor);
      }
    }
  }
  .alert-popup {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .cont-tip {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between !important;
  }
  .inputTip {
    width: 80px;
    border: inherit;
    border-bottom: 1px solid;
    animation: fade 0.5s ease-in-out;
    background: #ffffff;
  }
  .text {
    &.disabled {
      color: grey;
    }
  }
}
.methodPay {
  width: calc(100% - 100px);
  .hrCustom {
    margin: 0 0 25px 0;
    border-bottom: 3px solid rgba(170, 170, 170, 0.2);
  }
}
.newAddress {
  width: 100%;
  display: flex;
  .input-icons {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
  }
  .address-type {
    float: left;
    background: white;
    width: 100%;
    border-radius: 4px 0 0 4px;
  }
  .address-number {
    margin-left: 5px;
    float: left;
    background: white;
    width: 100%;
    border-radius: 4px 0 0 4px;
  }
  .number-location {
    margin-left: 5px;
    float: left;
    background: white;
    width: 100%;
    border-radius: 4px 0 0 4px;
  }
  .number-location-symbol {
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .number-location-2 {
    margin-left: 5px;
    float: left;
    background: white;
    width: 100%;
    border-radius: 4px 0 0 4px;
  }
}
.notAvailability {
  font-size: 16px;
  color: red;
  font-weight: bold;
}

.bottom-text,
.bottom-text-r {
  font-size: 15px;
  -webkit-box-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: flex;
  padding: 5px;
}
.bottom-text {
  -webkit-box-pack: start;
  justify-content: flex-start;
}
#cup {
  width: 100px !important;
}
.contCamp {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 4px #00000040;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 12px;
  animation: fade 1s ease-in-out;
  position: relative;
  width: 100%;
}
//new products
.contImgDescrip {
  display: flex;
  width: 100%;
  gap: 0 10px;
  .imgProduct {
    height: 83px;
    min-height: 83px;
    width: 84px;
    min-width: 84px;
  }
  .contDescript {
    display: grid;
    flex-direction: column;
    width: 100%;
  }
}
.contAdition {
  display: flex;
  justify-content: space-between;
}
.ProductCont {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(170, 170, 170, 0.4);
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.ProductContMyPayment {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 5px;
  .product {
    display: flex;
    flex-direction: row;
    align-items: center;
    .product-title {
      flex: 1;
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
.price-row {
  display: flex;
  .title {
    flex: 1;
  }
}
.divisor {
  height: 1px;
  border: 1px solid #c2c6c7;
  margin-bottom: 15px;
}
.observation {
  display: flex;
  flex-direction: column;
}
.contPrice {
  display: flex;
  justify-content: flex-end;
}
#detailProductCont {
  overflow-y: auto;
  max-height: 300px;
  &::-webkit-scrollbar-track {
    background: #e3e3e3 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cdd500 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: none !important;
  }
}
.payment-cont {
  margin: 20px 0;
  width: 100%;
}
.pay-cont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  // &:not(:last-child) {
  //   border-bottom: 1.5px solid rgba(207, 205, 205, 0.6);
  //   padding-bottom: 7px;
  // }
  &.paymentplatform {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
.CuponCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputcupNew {
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  height: 24px;
  animation: fade 0.5s ease-in-out;
  background: #ffffff;
  width: 120px !important;
  font-size: 12px;
  text-transform: uppercase;
  &::placeholder {
    font-size: 10px;
    text-decoration: none;
  }
}
.btnCup {
  background: #06111b 0% 0% no-repeat padding-box;
  border: 1px solid #b2b2b2;
  width: 59px;
  height: 24px;
  border-radius: 5px;
  color: #ffffff;
  padding: 1px;
  font-size: 12px;
  cursor: pointer;
}
.ContRadio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--buttonBackground);
  height: 50px;
  padding: 0 10px;
  margin: 2px 0;
}
.btnNext {
  height: 43px;
  width: 100%;
  max-width: 90%;
  background: rgba(16, 44, 58, 0.3843137255);
  // background: var(--buttonBackground);
  border-radius: 13px;
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: relative;
  bottom: 20px;
  &.readyToSubmit {
    background: var(--buttonBackground);
    cursor: pointer;
  }
  &.continue {
    margin-top: 0;
    position: inherit;
    bottom: 0;
  }
}
.btnNext2 {
  height: 43px;
  width: 100%;
  max-width: 90%;
  background: var(--buttonBackground);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  bottom: 20px;
  &.continue {
    margin-top: 0;
    position: inherit;
    bottom: 0;
  }
}
.btnClose {
  display: none;
  font-size: 16px;
  position: absolute;
  left: 20px;
  top: 29px;
  cursor: pointer;
  z-index: 1;
}
.btnBack {
  display: flex;
  font-size: 16px;
  position: absolute;
  left: 20px;
  top: 41px;
  cursor: pointer;
  z-index: 1;
}
#payContMobile {
  display: none;
  .contCamp {
    padding: unset;
  }
}
#successOrdertMobile {
  display: none;
  .contCamp {
    padding: unset;
  }
}
.ContDaysDelivery {
  display: flex;
  overflow-x: auto;
  align-items: center;
  padding: 5px;
  gap: 0 10px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .itemDayDilevery {
    box-shadow: 0px 0px 2px #0000007a;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    cursor: pointer;
    &.hora {
      height: 30px;
    }
    &.active {
      box-shadow: 0px 0px 8px var(--buttonBackground);
    }
  }
}
.contEditCoverage {
  cursor: pointer;
  margin-left: 5px;
}
.cardSelected {
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px black;
}
.info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #102c3a;
  border: 1px solid hsl(200, 10%, 57%);
  border-radius: 6px;
  height: 87px;
  width: 858px;
  margin: 0 auto;
  &-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
    white-space: nowrap;
    text-align: center;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  &-center {
    background: #87959c;
    border-radius: 6px;
    height: 2px;
    width: 100%;
    flex: 0.7;
  }
  &-amount {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
    @media screen and (max-width: 600px) {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 900px) {
    max-width: 688px;
  }
  @media screen and (max-width: 600px) {
    max-width: unset;
    width: 95%;
  }
}
@mixin imageSet($width, $height, $url) {
  background-image: url($url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: block;
}
.payInfo {
  @media (min-width: 1280px) {
    width: 860px;
    max-width: 860px;
  }
  @media (max-width: 600px) {
    width: 95%;
    max-width: unset;
  }
  justify-content: space-between;
  font-size: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  width: 860px;
  background-color: transparent;
  border-radius: 8px;
  font-size: 14px;
  @media (min-width: 768px) and (max-width: 1020px) {
    max-width: 688px;
  }
  @media (min-width: 1280px) {
    width: 860px;
  }
  @media (min-width: 1680px) {
    width: 46%;
    max-width: 40%;
  }
  &--cantUse {
    pointer-events: none;
    opacity: 0.8;
  }
}

.topContainer {
  z-index: 1000;
  position: relative;
}

.borderInside {
  width: 100%;
  min-height: 58px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 600px) {
    min-height: unset;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
.payInfo__tabs:nth-child(1) .borderInside {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.payInfo__tabs:not(.selected) .borderInside {
  border: solid black 1px;
  border-radius: 6px;
  padding: 3px;
  display: flex;
  opacity: 0.6;
  transform: translateY(-2px);
}
nav {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1px;
  @media screen and (min-width: 1600px) {
    min-width: 859px;
  }
  .payInfo__tabs {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 8px;
    width: 50%;
    border: solid 1px black;
    background-color: white;
    border-radius: unset;
    border-bottom-left-radius: 6px;
    min-height: 30px;
    max-height: 30px;
    gap: 10px;
    border-right: unset;
    border-top: unset;
    transform: translateY(5px) translateX(-1px);
    &:nth-child(1) {
      transform: translateY(5px) translateX(1px);
      border-radius: unset;
      border-bottom-right-radius: 6px;
      border-left: unset;
      border-right: black solid 1px;
    }
    @media screen and (max-width: 600px) {
      min-height: 61px;
      max-height: 61px;
      flex-direction: column;
      gap: 0;
    }
    h1 {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .PSEIcon {
      @include imageSet(21px, 19px, "../../../assets/images/PSEImage.svg");
    }
    .VisaIcon {
      @include imageSet(48px, 19px, "../../../assets/images/VisaImg.svg");
    }
    .masterCardIcon {
      @include imageSet(
        21px,
        19px,
        "../../../assets/images/mastercardImage.svg"
      );
    }
    .clubIcon {
      @include imageSet(21px, 19px, "../../../assets/images/clubImg.svg");
    }
    .americanExpressIcon {
      @include imageSet(
        21px,
        19px,
        "../../../assets/images/americanExpress.svg"
      );
    }
    .paymentsContainer {
      display: flex;
      flex-direction: row;
      width: 102%;
      justify-content: space-evenly;
      padding-bottom: 6px;
      padding-right: 1px;
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        h1 {
          flex: 1;
          min-width: 100%;
        }
      }
    }
  }
  .selected {
    border: solid 1px #102c3a;
    border-bottom: white solid 2px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    min-height: 33px;
    max-height: 33px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    transform: translateY(2px);
    opacity: unset;
    border-right: unset;
    border-left: 1px solid black;
    border-left: solid 1px black !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    &:nth-child(1) {
      border-right: unset;
      transform: translateX(0px);
      margin-top: 1px;
    }
    &:nth-child(2) {
      background-color: transparent;
      border-left: unset !important;
      border-right: 1px solid black;
      @media screen and (min-width: 700px) and (max-width: 1500px) {
        transform: translateX(-1px) translateY(1px);
      }
    }
    @media screen and (max-width: 1100px) {
      transform: translateY(2px);
      @media screen and (max-width: 600px) {
        min-height: 65px;
        max-height: 65px;
      }
    }
  }
}
</style>
