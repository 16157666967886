<template>
  <section :class="{habitation:habitation}" class="mainPayment">
    <form
      :class="{habitation:habitation}"
      @submit.prevent="postPSETransaction"
      class="payInfo__debitInformation"
      id="precompraPlayformVariant"
    >
      <div :class="{hide:habitation}" class="selectorContent--two">
        <Selector
          :options="banks"
          :placeHolder="'Selecciona tu banco'"
          :required="!this.habitation"
          @value="(e) => (bank = e, setProvider(e))"
        />
      </div>
      <input placeholder="Nombre" required type="text" v-model="name" />

      <div class="selectorContentDocoument">
        <Selector
          :options="typePersonOptions"
          :placeHolder="'Tipo de documento'"
          :required="true"
          @value="(e) => (typePerson = e)"
        />
      </div>

      <input placeholder="Numero de documento" required type="text" v-model="documentNumber" />
      <input placeholder="Correo" required type="email" v-model="email" />
      <div class="inputContainerC">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="inputNumberChange"
        />
      </div>
      <input
        class="room-class"
        placeholder="Código"
        required
        type="text"
        v-if="habitation"
        v-model="roomNumber"
      />
      <div class="selectorContentDocoument" style="width: 100%;">
        <Selector
          :options="deliveryTypes"
          :placeHolder="'Seleccionar el tipo de entrega'"
          :required="true"
          @value="(e) => (deliveryType = e)"
        />
      </div>
      <div :class="{hide:habitation}" class="switchesContainer">
        <div>
          <SwitchBool :value="personaNatural" @change="(e) => (personaNatural = e)" />
          <span>Persona Natural</span>
        </div>
        <div>
          <SwitchBool :value="personaJuridica" @change="(e) => (personaJuridica = e)" />
          <span>Persona Juridica</span>
        </div>
      </div>
      <section :class="{hide:habitation}" class="bottomPart">
        <div class="additionalInfo">
          <p>
            *Al hacer click en Pagar estás aceptando los terminos y
            condiciones y la política de privacidad de Precompro; al igual que
            las políticas de cancelación y/o modificación del restaurante.
          </p>
        </div>

        <div class="securePay" v-if="!habitation">
          <p>Pagos seguros a través de</p>
          <span :class="{ mercadoPagoImg: !payu, payuImg: payu }"></span>
        </div>
      </section>

      <button :class="{ readyToSubmit: submitReady }" type="submit" v-if="!habitation">Pagar</button>
      <div class="habitationsectionButton" v-if="habitation">
        <button
          :class="{ readyToSubmit: submitReady }"
          class="habitationSubmit"
          type="submit"
        >Realizar pedido</button>
      </div>
      <b-loading :can-cancel="true" :is-full-page="true" v-model="loading"></b-loading>
    </form>
  </section>
</template>
<script>
import _ from 'lodash';
import Selector from '@/components/GeneralSelector.vue';
import { mapGetters } from 'vuex';
import SwitchBool from '@/components/SwitchBool.vue';
import inputSelector from './NumberInput.vue';
// import { v4 as uuidv4 } from 'uuid';
import LocalStorage from 'store';
export default {
  name: 'precompraPlayformVariant',
  props: ['type', 'order'],
  components: {
    SwitchBool,
    Selector,
    inputSelector
  },
  data () {
    return {
      showtermsOne: false,
      showtermsTwo: false,
      loading: false,
      showPse: false,
      banks: [],
      bank: '',
      monthOptions: [],
      yearOptions: [],
      termsConditions: true,
      dataTreatment: true,
      mercadoPago: false,
      habitation: false,
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        provider: null,
        external_reference: null,
        payment_method_id: 'pse',
        payer: {
          email: null,
          entity_type: null,
          identification: {
            type: null,
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null,
        phone: null,
        name: null
      },
      psePAYU: {
        bank: null,
        provider: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        balance: 0,
        typePeople: null,
        url: null,
        referencia: null,
        description: null
      },
      typePersonOptions: [],
      deliveryTypes: [],
      typePerson: ' ',
      minDocumentnumbers: 9,
      maxDocumentnumbers: 9,
      documentNumber: '',
      roomNumber: '',
      deliveryType: null,
      email: '',
      name: '',
      client: {
        name: '',
        email: '',
        phone: '',
        identification: ''
      },
      payu: true,
      personaNatural: true,
      personaJuridica: false,
      phone: '',
      country: 'Colombia',
      bandera: 'https://api.precompro.com/banderas/co.png',
      indicativo: 57,
      balancePaid: 0,
      creditOPtionsTypePerson: [],
      provider: null,
      minLength: 7,
      maxLength: 12
    };
  },
  created () {
    this.payu = this.type === 'PAYU';
    this.mercadoPago = this.type === 'MP';
    this.habitation = this.type === 'habitation' || this.type === 'datosEntrega';
    this.deliveryTypes = this.getVendor.deliveryTypesSelected;
    if (!this.habitation) {
      this.getStatusPSE();
      this.getPaymentOptionsPSE();
    }

    this.getIdentification();
    this.balancePaid = this.amount;
  },
  methods: {
    getIdentification () {
      if (this.payu || this.habitation) {
        this.typePersonOptions = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
          { value: 'TI', name: 'TI - Tarjeta de Identidad' },
          { value: 'PP', name: 'PP - Pasaporte' },
          {
            value: 'CEL',
            name: 'CEL - En caso de identificarse a través de la línea del móvil'
          },
          { value: 'RC', name: 'RC - Registro civil de nacimiento' },
          { value: 'DE', name: 'DE - Documento de identificación extranjero' }
        ];
        return;
      } else if (this.mercadoPago) {
        this.pse.transaction_amount = this.order.totalPrice + this.order.delivery + this.order.tip;
        this.pse.net_amount = this.order.totalPrice + this.order.delivery + this.order.tip;
        const trans = this.$route.query;
        if (Object.keys(trans).length > 0) {
          if (trans.source_utm) {
            this.pse.callback_url = window.location.href;
          } else {
            this.pse.callback_url = window.location.href + '?source_utm=mp';
          }
        } else {
          this.pse.callback_url = window.location.href + '?source_utm=mp';
        }
        this.$http.get('marketplace/payment/identifications').then(({ data }) => {
          if (data.code === 200) {
            const dataResult = data.data;
            dataResult.forEach((element) => {
              this.typePersonOptions.push({
                name: element.name,
                value: element.id
              });
            });
          } else {
            this.typePersonOptions = [];
          }
        });
      }
      if (this.creditpaymentTypePAYU) {
        this.creditOPtionsTypePerson = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
          { value: 'TI', name: 'TI - Tarjeta de Identidad' },
          { value: 'PP', name: 'PP - Pasaporte' },
          {
            value: 'CEL',
            name: 'CEL - En caso de identificarse a través de la línea del móvil'
          },
          { value: 'RC', name: 'RC - Registro civil de nacimiento' },
          { value: 'DE', name: 'DE - Documento de identificación extranjero' }
        ];
      } else {
        this.$http.get('marketplace/payment/identifications').then(({ data }) => {
          if (data.code === 200) {
            const dataResult = data.data;
            dataResult.forEach((element) => {
              this.creditOPtionsTypePerson.push({
                name: element.name,
                value: element.id
              });
            });
          } else {
            this.typePersonOptions = [];
          }
        });
      }
    },
    inputNumberChange (value) {
      this.bandera = value.countrieInfo.bandera;
      this.indicativo = value.countrieInfo.indicativo;
      this.country = value.countrieInfo.pais;
      this.phone = value.inputValue;
    },
    minLenChange (value) {
      this.minLength = value;
    },
    maxLenChange (value) {
      this.maxLength = value;
    },
    getStatusPSE () {
      this.$http.get('marketplace/payment/banks/pse').then(({ data }) => {
        if (data.code === 200) {
          const pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    getPaymentOptionsPSE () {
      this.$http.get('marketplace/payment/banks/pse').then(({ data }) => {
        if (data.code === 200) {
          const info = _.find(data.data, { id: 'pse' });
          info.financial_institutions.forEach((bank) => {
            this.banks.push({ value: bank.id, name: bank.description });
          });
        } else {
          this.banks = [];
        }
      });
    },
    postPayuPSE () {
      this.$store.commit('setLoader', true);
      this.order.mp = 0;
      this.$http.post('marketplace/payment/pse', { order: this.order, pse: this.psePAYU }).then(response => {
        console.log('postPayuPSE', response.data);
        if (response.data.code === 200) {
          LocalStorage.set('processId', response.data.transactionsId);
          window.location.href = response.data.url;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Operación fallida <br><strong style='color: #ffffff;'>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
          this.$store.commit('setLoader', false);
        }
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    postmercadoPagoPSE () {
      this.$store.commit('setLoader', true);
      this.order.mp = 1;
      this.$http.post('marketplace/payment/pse', { order: this.order, pse: this.pse }).then(response => {
        console.log('postmercadoPagoPSE', response.data);
        if (response.data.code === 200) {
          LocalStorage.set('processId', response.data.data.id);
          window.location.href = response.data.url;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Operación fallida <br><strong style='color: #ffffff;'>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
          this.$store.commit('setLoader', false);
        }
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    setProvider (id) {
      const index = this.banks.findIndex((e) => parseInt(e.value) === parseInt(id));
      if (index > -1) {
        this.provider = this.banks[index].name;
      }
    },
    async postPSETransaction (e) {
      e.preventDefault();
      if (this.payu) {
        this.psePAYU.bank = this.bank;
        this.psePAYU.provider = this.provider;
        this.psePAYU.name = this.name;
        this.psePAYU.email = this.email;
        this.psePAYU.typeDocument = this.typePerson;
        this.psePAYU.identification = this.documentNumber;
        this.psePAYU.phone = this.phone;
        this.psePAYU.indicativo = this.indicativo;
        this.psePAYU.bandera = this.bandera;
        this.psePAYU.country = this.country;
        this.psePAYU.typePeople = this.personaNatural ? 'N' : 'J';
        this.psePAYU.balance = this.balancePaid;
        this.psePAYU.url = window.location.href;
        // this.psePAYU.referencia = 'contactless-' + this.vendor.displayName + '-' + this.name + '-' + uuidv4();
        this.psePAYU.numberPaid = this.numberPaid;
        this.postPayuPSE();
      } else if (this.mercadoPago) {
        this.pse.payer.email = this.email;
        this.pse.provider = this.provider;
        this.pse.payer.identification.type = this.typePerson;
        this.pse.payer.identification.number = this.documentNumber;
        this.pse.payer.entity_type = this.personaNatural
          ? 'individual'
          : 'association';
        this.pse.transaction_details.financial_institution = this.bank;
        this.pse.transaction_amount = this.balancePaid;
        this.pse.net_amount = this.balancePaid;
        this.pse.callback_url = window.location.href + '?source_utm=mp';
        // this.pse.external_reference = 'contactless-' + this.vendor.displayName + '-' + this.name + '-' + uuidv4();
        this.pse.name = this.name;
        this.pse.phone = this.phone;
        this.pse.indicativo = this.indicativo;
        this.pse.bandera = this.bandera;
        this.pse.numberPaid = this.numberPaid;
        this.pse.country = this.country;
        this.postmercadoPagoPSE();
      } else if (this.habitation && this.type !== 'datosEntrega') {
        this.$emit('setOrder', {
          name: this.name,
          email: this.email,
          typeDocument: this.typePerson,
          identification: this.documentNumber,
          phone: this.phone,
          indicativo: this.indicativo,
          bandera: this.bandera,
          country: this.country,
          roomNumber: this.roomNumber
        });
      } else if (this.habitation && this.type === 'datosEntrega') {
        this.$emit('setOrder', {
          name: this.name,
          email: this.email,
          typeDocument: this.typePerson,
          identification: this.documentNumber,
          phone: this.phone,
          indicativo: this.indicativo,
          bandera: this.bandera,
          country: this.country,
          roomNumber: this.roomNumber,
          deliveryType: this.deliveryType
        });
      }
    },
    submithabitation () {
      // aqui mira que hacer cris xd
    }
  },
  watch: {
    personaJuridica () {
      if (this.personaJuridica) {
        this.personaNatural = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaNatural = true;
      }
    },
    personaNatural (value) {
      if (this.personaNatural) {
        this.personaJuridica = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaJuridica = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      getVendor: 'getVendor'
    }),
    submitReady () {
      if (
        this.name &&
        this.deliveryType &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength && this.phone.length <= this.maxLength) &&
        this.typePerson && (this.habitation || this.bank) &&
        this.email &&
        ((this.habitation && this.roomNumber) || (!this.habitation))
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.room-class {
  @media screen and (max-width: 600px) {
    width: 45%;
  }
  @media screen and (max-width: 390px) {
    width: 43%;
  }
  @media screen and (max-width: 380px) {
    width: 42%;
  }
}
@mixin imageSet($width, $height, $url) {
  background-image: url($url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: block;
}
.cardSelected {
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px black;
}
.termsC {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 90%;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 8px;
  padding-bottom: unset;
  overflow: hidden;
  max-height: 70%;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.582);
}
@keyframes loader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
#precompraPlayformVariant {
  transform: translateX(-1px);
  border: solid #102c3a 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  &:not(.habitation) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: unset;
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 20px;
  gap: 16px;
  height: 1px;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: -1px;
  width: 100%;
  max-width: 858px;
  @media screen and (max-width: 1000px) {
    max-width: 685px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 10px;
    max-width: 100%;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @mixin imageSet($width, $height, $url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: block;
  }
  input {
    color: #444b57;
    font-size: 12px;
    border-radius: 5px;
    outline: unset;
    border: solid 1px #444b57;
    min-height: 30px;
    text-indent: 5px;
  }
  .switchesContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    &.hide {
      display: none;
    }
    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
    span {
      font-size: 12px;
    }
  }
  .selectorContent--two {
    width: 100%;
    &.hide {
      display: none;
    }
  }
  & input:nth-child(2) {
    width: 48%;
    @media screen and (max-width: 1000px) {
      min-width: 52%;
      flex: 1;
    }
    @media screen and (max-width: 600px) {
      min-width: 100%;
      flex: 1;
    }
  }
  & input:nth-child(4) {
    min-width: 20%;
    flex: 1;
    @media screen and (max-width: 1000px) {
      flex: unset;
      min-width: unset;
      width: 28%;
    }
    @media screen and (max-width: 600px) {
      min-width: 40%;
      flex: 1;
    }
  }
  & input:nth-child(5) {
    width: 28%;
    @media screen and (max-width: 1000px) {
      width: 33%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  & div:nth-child(3) {
    width: 25%;
    @media screen and (max-width: 1000px) {
      width: 30%;
    }
    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }
  & .inputContainerC {
    width: 22.5%;
    @media screen and (max-width: 1000px) {
      width: 30%;
    }
    @media screen and (max-width: 600px) {
      display: flex;
      width: 50%;
      // flex: 1;
    }
    @media screen and (max-width: 390px) {
      width: 51%;
    }
    @media screen and (max-width: 380px) {
      width: 52%;
    }
  }
  @media (min-width: 1020px) {
    overflow: hidden;
  }
  .generalInputContainer {
    width: 100%;
    @media (min-width: 768px) {
      width: 36%;
    }
    @media (min-width: 1024px) {
      width: 46%;
    }
  }
  font-family: "Source Sans Pro", sans-serif;
  .principalMain {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 1020px) {
      min-height: 86vh;
    }
    @media (min-width: 1024px) and (max-width: 1240px) {
      height: 82vh;
      align-items: start;
    }
    @media (min-width: 1224px) and (max-width: 1600px) {
      height: 82vh;
      align-items: flex-start;
    }
    @media (min-width: 1600px) {
      height: 82.8vh;
      align-items: flex-start;
    }

    @media (min-width: 1024px) {
      margin: unset;
      flex-direction: row;
      justify-content: space-evenly;
    }
    @media (min-width: 1280px) {
      justify-content: center;
      gap: 30px;
    }
    .payInfo--cantUse {
      pointer-events: none;
    }
    .information,
    .payInfo {
      margin-top: 10px;
      @media (min-width: 1024px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 463px;
        max-width: 463px;
      }
      @media (min-width: 1280px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 543px;
        max-width: 543px;
      }
      font-size: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 5px;
      width: 96%;
      background-color: white;
      @media (max-width: 600px) {
        min-height: 485px;
        max-height: 455px;
      }

      border-radius: 8px;
      font-size: 14px;
      @media (min-width: 768px) and (max-width: 1020px) {
        max-width: 688px;
      }
      button {
        width: 60%;
        min-height: 30px;
        font-weight: 600;
        font-size: 12px;
        outline: unset;
        border: unset;
        color: white;
        background-color: #444b57;
        border-radius: 6px;
        opacity: 0.6;
        @media (min-width: 768px) {
          max-width: 230px;
        }
      }
    }
    .information {
      @media (min-width: 1024px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 481px;
        max-width: 481px;
      }
      @media (min-width: 1280px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 607px;
        max-width: 607px;
      }
      @media (min-width: 1280px) {
        width: 46%;
        max-width: 46%;
        height: 466px;
      }
      position: relative;
      header {
        padding: 7px 0 7px 0;
        border-bottom: solid 1px black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        .header__leftSide {
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            margin-left: 5px;
            font-size: 14px;
          }
        }
        .header__rightSide {
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 16px;
            font-weight: 600;
          }
          min-width: 52%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (min-width: 768px) {
            min-width: 40%;
            justify-content: flex-end;
            gap: 15px;
          }
          p {
            color: #444b57;
            font-size: 10px;
            font-weight: 400;
            @media (min-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
      &__mainContent {
        width: 100%;
        @media (min-width: 768px) {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .mainContent__cardContainer {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 60%;
          }
          @media (min-width: 1020px) {
            width: 90%;
            padding-top: 28px;
          }
          @media (min-width: 1280px) {
            padding-top: 35px;
          }
          .cardContainer__card {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            border: solid 2px #eeefef;
            border-radius: 5px;
            width: 100px;
            height: 100px;
            h4 {
              font-size: 12px;
              font-weight: 400;
            }
            p {
              font-weight: 600;
            }
          }
        }
        .mainContent__detailsCardsContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: unset;
          @media (min-width: 768px) {
            width: 96%;
            justify-content: space-between;
          }
          .detailsCardsContainer__detail {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
            font-size: 12px;
            @media (min-width: 768px) {
              font-size: 14px;
            }
            span {
              margin-left: 5px;
              font-weight: 600;
            }
          }
        }
        .mainContent__smallLetter {
          font-size: 11px;
          font-weight: 300;
          margin: 1rem 0;
          color: #444b57;
          @media (min-width: 768px) {
            width: 100%;
            font-size: 12px;
            margin-left: 20px;
          }
        }
        .mainContent__clientData {
          padding: 0.4rem;
          border-radius: 8px;
          background-color: #f5f6f6;
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (min-width: 1024px) {
            margin-top: 20px;
            position: relative;
            bottom: 4px;
          }
          @media (min-width: 1224px) {
            padding-top: 25px;
          }
          @media (min-width: 1280px) {
            margin-top: 15px;
            padding-top: 5px;
          }
        }
      }
    }
    .payInfo {
      height: 464px;
      @media (min-width: 200px) and (max-width: 600px) {
        min-height: 515px;
      }
      @media (min-width: 720px) and (max-width: 1000px) {
        min-height: 450px;
        height: 455px;
      }
      @media (min-width: 1280px) {
        width: 46%;
        height: 466px;
      }
      @media (min-width: 1680px) {
        width: 46%;
        max-width: 40%;
        height: 466px;
      }
      &--cantUse {
        pointer-events: none;
        opacity: 0.8;
      }
      &__title {
        font-weight: 600;
        margin: 8px 0;
        font-family: "Source Sans Pro", sans-serif;
        @media (min-width: 768px) and (max-width: 1020px) {
        }
        @media (min-width: 1024px) {
          margin: 1px 0;
        }
      }
      button {
        cursor: not-allowed;
      }
      button:hover {
        filter: brightness(1.2);
      }
      .readyToSubmit {
        background-color: #bdc30e;
        color: white;
        cursor: pointer;
      }

      form {
        @media (min-width: 1024px) {
          padding-top: 12px;
        }
        padding: 15px 4px 0 4px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: center;
        padding-bottom: 1.8rem;
        padding: 20px 10px;
        .additionalInfo {
          & > p {
            font-size: 10px;
            font-weight: 300;
            width: 97%;
          }
        }
        .inputContainerC {
          width: 40%;
          z-index: 39;
          position: relative;
          input {
            width: 100%;
          }
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 28%;
          }
          input {
            // padding-left: 25px;
          }
        }
      }
    }
  }
}
.bottomPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
  & .additionalInfo {
    width: 50%;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }
    @media screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
  p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;

    color: #102c3a;
  }
}
button {
  background: #102c3a62;
  border-radius: 6px;
  width: 416px;
  color: white;
  height: 30px;
  margin: auto;
  border: unset;
  cursor: not-allowed;
}

.readyToSubmit {
  background: var(--buttonBackground);
  cursor: pointer;
}

.securePay {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  gap: 15px;
  padding-bottom: 10px;
  @media (min-width: 1024px) {
    padding-bottom: 3px;
  }
  .mercadoPagoImg {
    @include imageSet(40px, 28px, "../../assets/image/mercadoPago.svg");
  }
  .payuImg {
    @include imageSet(40px, 28px, "../../assets/image/PSE.png");
  }

  @media screen and (max-width: 600px) {
    min-width: 100%;
    justify-content: center;
    margin-top: 25px;
  }
}
#CardNumberInput {
  position: relative;
  &::after {
    position: absolute;
    background-color: red;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    content: attr(afterContent) "";
  }
}

.habitationSubmit {
  width: 100%;
  height: 40px;
  margin: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-family: "OpenSans";
  line-height: 19px;
  color: var(--buttonTextColor);
}
.habitationsectionButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 10px;
  position: absolute;
  left: 0;
  bottom: -25%;
}
.mainPayment {
  max-width: 100%;
}
</style>
