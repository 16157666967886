<template>
  <div id="restaurant" v-if="load">
    <CustomVar :vendor="config" v-if="load"></CustomVar>
    <b-message :active.sync="messDelete" id="messDelete">
      <div class="cont-info">
        <i
          class="icon-mks icon_delete_car red"
          style="margin-right: 20px; width: 32px; height: 32px;"
        ></i>
        <div class="add-pro">
          <span class="text size-14">Se actualizo el siguiente producto:</span>
          <span class="text bold size-13 center">{{messDeletePro.name}}</span>
        </div>
      </div>
    </b-message>
    <b-message :active.sync="messUpdate" auto-close id="messUpdate" v-model="isActive">
      <div class="cont-info">
        <i
          class="icon-mks icon_added_car add-color"
          style="margin-right: 20px; width: 32px; height: 32px;"
        ></i>
        <div class="add-pro">
          <span class="text size-14">Se actualizo el siguiente producto:</span>
          <p class="text bold size-13 center">{{messUpdatePro.name}} x {{messUpdatePro.quantity}}</p>
        </div>
      </div>
    </b-message>
    <b-message :active.sync="messCart" id="messCart">
      <div class="cont-info">
        <i
          class="icon-mks icon_added_car add-color"
          style="margin-right: 20px; width: 32px; height: 32px;"
        ></i>
        <div class="add-pro">
          <span class="text size-14">Agregaste al carrito el siguiente producto:</span>
          <p
            class="text bold size-13 center"
          >{{productShowMess.name}} x {{productShowMess.quantity}}</p>
        </div>
      </div>
    </b-message>
    <ModeCarta
      :categories="categories"
      :products="productsModeMenu"
      @showItemModal="showItem($event, getVendor)"
      v-if="getVendor.modeMenu == 1"
    ></ModeCarta>
    <div class="rigth" v-if="getVendor.modeMenu == 0">
      <div class="custom-carousel" v-if="Object.keys(carousels).length > 0">
        <VueSlickCarousel class="slickCarousel" v-bind="dataCarousel">
          <div
            :key="'item#carousel-' + item._id + '-' + f"
            :style="{'background-image': 'url(' + item.image + ')'}"
            class="img-carousel"
            v-for="(item, f) in carousels"
          ></div>
        </VueSlickCarousel>
      </div>
      <div @click="backIntermediate()" class="back-intermediate" v-if="intermediateId">
        <i class="fas fa-reply" style="cursor:pointer"></i>
        <span class="text" style="cursor:pointer">{{ intermediateName }}</span>
      </div>
      <div class="search-bar-mobile" v-if="(active !== null || activeMedium !== null)">
        <a class="button search-button-mobile">
          <div class="content_icon_mobile">
            <i class="icon-mks icon_search black mouse"></i>
          </div>
          <div class="content_input_mobile">
            <input
              @keydown.esc="query = ''"
              class="content_btn_mobile ffr"
              type="text"
              v-model="query"
            />
          </div>
        </a>
      </div>
      <CategoryInitial
        :active="active"
        :categories="categories"
        @selectCat="getProductCategories($event)"
        v-if="(categories.length > 0 && (active === null))"
      ></CategoryInitial>
      <categoryIntermediate
        :active="active"
        :categories="intermediate"
        @selectCat="getProductCategoriesIntermediate($event)"
        v-if="(intermediate.length > 0 && intermediateId == null && (active !== null))"
      ></categoryIntermediate>
      <categoryBasic
        :active="active"
        :categories="categories"
        @selectCat="getProductCategories($event)"
        v-if="(categories.length > 0 && (active !== null))"
      ></categoryBasic>
      <categoryMedium
        :active="activeMedium"
        :categories="medium"
        @selectCat="getProductCategoriesMedium($event)"
        v-if="(medium.length > 0 && (active !== null || activeMedium !== null))"
      ></categoryMedium>
      <div class="filters-container" v-if="false">
        <p class="text bold self-center small">
          <i class="fa fa-filter"></i>&nbsp;Filtrar por:
        </p>
        <div :class="{'btn-filter-active': filter == 'desc'}" class="btn-filters">
          <p @click="filters('desc')" class="text small center size-14">
            <i class="fa fa-sort-amount-down-alt"></i>&nbsp;Menor precio
          </p>
        </div>
        <div :class="{'btn-filter-active': filter  == 'asc'}" class="btn-filters">
          <p @click="filters('asc')" class="text small center size-14">
            <i class="fa fa-sort-amount-up"></i>&nbsp;Mayor precio
          </p>
        </div>
      </div>
      <div
        class="cont-products"
        v-if="(products.length !== 0 && (active !== null || activeMedium !== null))"
      >
        <div
          :class="{'disabled': p.inStock == 0}"
          :key="'prod#' + p._id + '-' + i"
          v-for="(p, i) in products"
        >
          <p
            :id="cateName(p.categoryId)"
            class="text size-14"
            style="font-weight: 600; padding-top: 6px; padding-left: 5px;"
            v-if="(medium.length > 0 && showSubCategory(i, (i-1)))"
          >&#8226;&nbsp;&nbsp;&nbsp;{{ cateName(p.categoryId) }}</p>
          <div class="products">
            <div class="img-product">
              <div
                :class="{'no-shadow': (p.image == undefined || p.image == null)}"
                :style="{'background-image': 'url(' + p.image + ')'}"
                class="imgProducto"
              ></div>
            </div>
            <div class="info-product">
              <div :class="{'noImage': p.image == undefined }" class="cont-name">
                <div class="text bold title-product size-14">
                  {{ p.name }}
                  <img
                    height="10"
                    src="/2x1.png"
                    style="float: right; right: 0px;"
                    v-show="isDeals(p.deal, '2x1')"
                    width="20"
                  />
                  <img
                    height="10"
                    src="/3x2.png"
                    style="float: right; right: 0px;"
                    v-show="isDeals(p.deal, '3x2')"
                    width="30"
                  />
                </div>
              </div>
              <div class="cont-info-descrip">
                <p class="category text size-9" style="margin-top:5px">{{ cateName(p.categoryId) }}</p>
                <p class="description" style="word-break: break-word;">{{ p.description }}</p>
                <div class="contPriceBtn">
                  <div
                    @click="showItem(p, getVendor)"
                    class="btn-add-hover"
                    v-if=" productInCar(p)"
                  >
                    <div class="contBtnPlus">
                      <i class="fas fa-plus iconColor"></i>
                    </div>
                    <p class="text size-12 white">Agregar</p>
                  </div>
                  <div class="btn-minus-plus" v-show=" !productInCar(p)">
                    <span
                      @click="deleteProductCart(p._id, i, p)"
                      id="minus"
                      v-show="productQuantity(p) == 1"
                    >
                      <i class="fa fa-minus" id="icon-menos"></i>
                    </span>
                    <span
                      @click="updateCart(p, 'min', i)"
                      id="minus"
                      v-show="productQuantity(p) > 1"
                    >
                      <i class="fa fa-minus" id="icon-menos"></i>
                    </span>
                    <span class="text semi-bold size-16" id="quantity">{{ productQuantity(p)}}</span>
                    <span @click="updateCart(p, 'max', i)" id="plus">
                      <i class="fa fa-plus" id="icon-mas"></i>
                    </span>
                  </div>
                  <div class="pricesCont">
                    <p
                      class="text bold priceComplete"
                    >{{ getPrice(p) }}</p>
                    <p
                      class="text bold discountText"
                      v-if="validateDescount(p)"
                    >{{ p.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
                    <p
                      class="text bold discountPercent"
                      v-if="validateDescount(p)"
                    >-{{p.percentOff}}%</p>
                  </div>
                  <p @click="openModalDetail(p)" class="showMore text size-13 white" v-if="p.specs || showViewAll(p.description)">Ver más</p>
                </div>
              </div>
            </div>
            <span class="text bold size-25 not-allowed" v-if="p.inStock == 0">No disponible</span>
          </div>
        </div>
        <div class="loadingProducts" v-if="loadingProducts">
          <i class="fa fa-spinner fa-spin"></i> Cargando
        </div>
      </div>
      <div
        class="notCategori"
        v-if="(products.length == 0 && !$parent.isLoadingGlobal) && intermediate.length == 0"
      >
        <div>
          <b-icon icon="shopping-cart" pack="fas" size="is-large"></b-icon>
        </div>
        <div>No hay productos</div>
      </div>
    </div>
    <b-modal :active.sync="PopUpOpen" id="modal-pop" :can-cancel="false">
      <PopUpGaira @closeModal="PopUpOpen= $event"></PopUpGaira>
    </b-modal>
    <b-modal :active.sync="popClosed" :can-cancel="false" id="modal-closed">
      <div class="cont">
        <i class="fas fa-tools" id="tools-icons"></i>
        <span class="text bold center margin-bot-10">Gracias por preferirnos</span>
        <span class="text center" v-if="getVendor.closeMessage != undefined">
          <div class="dv" v-html="getVendor.closeMessage"></div>
        </span>
        <span class="text center" v-if="getVendor.showScheduleInClose">
          <div class="dv" v-html="schedules(getVendor.dayTimes)"></div>
          <div style="margin-top: 10px;">
            <i class="fa fa-info-circle"></i>
            En este momento
            <b>estamos cerrados</b>
            <br />Puedes ver la carta pero
            <b>NO</b> realizar pedidos.
            <br />
            <b-button @click="popClosed = false" icon-left="eye" style="margin-top: 10px;">Continuar</b-button>
          </div>
        </span>
      </div>
    </b-modal>
    <b-modal
      :active.sync="modalProduct"
      :on-cancel="closeModalProduct"
      id="modal-closed"
      v-if="productSelected !== null"
    >
      <div class="detail-modal">
        <div class="img-modal">
          <div @click="closeModalProduct()" class="btnBackModal">
            <i class="fas fa-times"></i>
          </div>
          <div
            :style="{'background-image': 'url(' + productSelected.image + ')'}"
            class="imgProductoModal"
            v-if="(productSelected.image)"
          ></div>
          <div
            :class="{'box-shadow-class': (productSelected.image === undefined)}"
            class="imgProductoModal"
            v-else
          ></div>
          <div
            class="btn-minus-plus"
            style="position: absolute; bottom: 14px;"
            v-show=" !productInCar(productSelected)"
          >
            <span
              @click="deleteProductCart(productSelected._id, null, productSelected)"
              id="minus"
              v-show="productQuantity(productSelected) == 1"
            >
              <i class="fa fa-minus" id="icon-menos"></i>
            </span>
            <span
              @click="updateCart(productSelected, 'min', null)"
              id="minus"
              v-show="productQuantity(productSelected) > 1"
            >
              <i class="fa fa-minus" id="icon-menos"></i>
            </span>
            <span class="text semi-bold size-18" id="quantity">{{ productQuantity(productSelected)}}</span>
            <span @click="updateCart(productSelected, 'max', null)" id="plus">
              <i class="fa fa-plus" id="icon-mas"></i>
            </span>
          </div>
        </div>
        <div
          class="text bold title-product size-14"
          style="color: var(--buttonTextColor); padding-bottom: 20px; display: flex; justify-content: center; padding-top: 10px;"
        >
          <span class="title-modal">{{productSelected.name}}</span>
        </div>
        <div class="description-category">
          <p class="category text size-9">{{ cateName(productSelected.categoryId) }}</p>
          <p class="description" style="word-break: break-word;">{{ productSelected.description }}</p>
        </div>
        <div class="contPriceBtnModal">
          <div @click="showItem(productSelected, getVendor)" class="btn-add-hover-modal">
            <div class="contBtnPlus">
              <i class="fas fa-plus iconColor"></i>
            </div>
            <p class="text size-13 white">Agregar</p>
          </div>
          <div class="pricesContModal">
            <p
              class="text bold priceComplete"
              style="color: var(--textColorPriceCard);"
            >{{ getPrice(productSelected) }}</p>
            <p
              class="text bold discountText"
              style="color: var(--buttonTextColor);"
              v-if="validateDescount(productSelected)"
            >{{ p.price | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
            <p
              class="text bold discountPercent"
              v-if="validateDescount(productSelected)"
            >-{{productSelected.percentOff}}%</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomVar from '@/components/CustomVars';
import PopUpGaira from '@/components/PopUp/PopUpGaira';
import CategoryBasic from '@/components/categories/CategoryBasic';
import CategoryInitial from '@/components/categories/CategoryInitial';
import CategoryMedium from '@/components/categories/CategoryMedium';
import CategoryIntermediate from '@/components/categories/CategoryIntermediate';
import deliveryAddress from '@/components/templateFood/modals/deliveryAddress';
import ModeCarta from '@/components/modeCarta';
import _ from 'lodash';
import LocalStorage from 'store';
import Vue2Filters from 'vue2-filters';
import credentials from '@/helpers/pay.json';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  mixins: [Vue2Filters.mixin],
  props: ['searchProducts'],
  components: { CustomVar, PopUpGaira, CategoryBasic, CategoryMedium, CategoryIntermediate, VueSlickCarousel, ModeCarta, CategoryInitial },
  name: 'Restaurant',
  metaInfo () {
    return {
      title: this.getVendor.displayName,
      link: [
        { vmid: 'icon', rel: 'icon', href: this.getVendor.logo || '/favicon.ico' }
      ]
    };
  },
  data () {
    return {
      isActive: true,
      categories: [],
      basic: [],
      medium: [],
      intermediate: [],
      intermediateId: null,
      intermediateName: null,
      products: [],
      productsOld: [],
      active: null,
      activeMedium: null,
      filter: null,
      viewProduct: false,
      infoProduct: [],
      messCart: false,
      modalProduct: false,
      productSelected: null,
      productShowMess: [],
      productsModeMenu: [],
      query: '',
      load: false,
      config: {
        toolbarColor: '#151E26',
        footerColor: '#151E26',
        footerTextColor: '#ffffff',
        buttonColor: '#559A70',
        buttonTextColor: '#ffffff',
        textColorMap: '#808080',
        circleColorMap: '#132b2e'
      },
      PopUpOpen: false,
      categoryPosition: null,
      carousels: [],
      dataCarousel: {
        autoplay: true,
        autoplaySpeed: 5000,
        focusOnSelect: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
      },
      images: [{ image: 'https://firebasestorage.googleapis.com/v0/b/precompro-app.appspot.com/o/marketplace%2Fbanners%2F5eeb834fbb89b64b0b0e1936jpg?alt=media&token=08120de8-ae0b-41b7-9661-3f337974d8f4' }, { image: 'https://firebasestorage.googleapis.com/v0/b/precompro-app.appspot.com/o/marketplace%2Fbanners%2F5eeb86f1bb89b64b0b0e1937jpg?alt=media&token=ec48c97e-08cc-4057-9aed-12d1fa930719' }, { image: 'https://picsum.photos/200/300?grayscale' }, { image: 'https://firebasestorage.googleapis.com/v0/b/precompro-app.appspot.com/o/marketplace%2Fbanners%2F5eeb86f1bb89b64b0b0e1937jpg?alt=media&token=ec48c97e-08cc-4057-9aed-12d1fa930719' }],
      popClosed: false,
      pageLoad: 0,
      pageLoadCat: 0,
      pageLoadCatMed: 0,
      pageFull: 0,
      pageFullCat: 0,
      pageFullCatMed: 0,
      blockedLazy: null,
      loadingProducts: false,
      mergedProducts: true,
      messDeletePro: [],
      messDelete: false,
      messUpdatePro: [],
      messUpdate: false
    };
  },
  async mounted () {
    if (LocalStorage.get('couponCode') && LocalStorage.get('couponCodeData')) {
      LocalStorage.remove('couponCode');
      LocalStorage.remove('couponCodeData');
    }
    this.getInfoVendor(this.$route.params.id);
  },
  computed: {
    ...mapGetters([
      'getVendor',
      'getCart',
      'globalLoader'
    ])
  },
  sockets: {
    adminUpdateVendor (data) {
      if (data.vendorId) {
        if (this.$route.params.id === data.vendorId) {
          this.getInfoVendor(this.$route.params.id);
        }
      }
    },
    adminUpdateCategory (data) {
      if (data.vendorId) {
        if (this.$route.params.id === data.vendorId) {
          this.getCategories(this.$route.params.id, this.getVendor.template);
        }
      }
    },
    adminUpdateProduct (data) {
      if (data.vendorId) {
        if (this.$route.params.id === data.vendorId) {
          if (this.active == null) {
            if (this.pageLoad > 0) {
              const totalPage = this.pageLoad;
              for (var i = 0; i < totalPage; i++) {
                if (i === 0) {
                  this.mergedProducts = false;
                }
                this.pageLoad = i;
                this.getProducts(this.$route.params.id);
              }
            } else {
              this.getProducts(this.$route.params.id);
            }
          } else {
            const active = this.active;
            this.blockedLazy = active;
            this.active = null;
            if (this.pageLoadCat > 0) {
              const totalPage = this.pageLoadCat;
              for (var e = 0; e < totalPage; e++) {
                this.pageLoadCat = e;
                if (e > 0) {
                  this.active = null;
                }
                this.getProductCategories(active);
              }
            } else {
              this.getProductCategories(active);
            }
          }
        }
      }
    }
  },
  methods: {
    showViewAll (text) {
      if (text) {
        return (text.length >= 114);
      }
      return false;
    },
    showSubCategory (index, previousIndex) {
      if (index === 0) {
        return true;
      }
      const lastProduct = this.products[previousIndex];
      const currentProduct = this.products[index];
      if (lastProduct.categoryId !== currentProduct.categoryId) {
        return true;
      }
      if (currentProduct.categoryId === lastProduct.categoryId) {
        return false;
      }
    },
    openModalDetail (selectedProduct) {
      if (selectedProduct.specs) {
        this.showItem(selectedProduct, this.getVendor);
      } else {
        this.modalProduct = true;
        this.productSelected = selectedProduct;
      }
    },
    closeModalProduct () {
      this.modalProduct = false;
      this.productSelected = null;
    },
    schedules (times) {
      const ti = [];
      Object.values(times).map(time => {
        if (time.hasAttention) {
          ti.push({ day: time.dayName, open: time.open, close: time.close });
        }
      });
      let info = '';
      Object.values(ti).map(inf => {
        info += '<div style="width: 100%;"><div><b>' + inf.day + ': </b></div><div>' + this.$moment('1970-01-01 ' + inf.open).format('hh:mm a') + ' - ' + this.$moment('1970-01-01 ' + inf.close).format('hh:mm a') + '</div></div>';
      });
      return info;
    },
    lazyLoad () {
      if (this.getVendor.template !== 'intermediate') {
        const scrollY = document.getElementById('app').scrollTop;
        const visible = document.getElementById('app').clientHeight;
        const pageHeight = document.getElementById('restaurant').scrollHeight - 300;
        const bottomOfPage = visible + scrollY >= pageHeight;
        if (bottomOfPage || pageHeight < visible) {
          if (!this.loadingProducts) {
            if (this.active == null) {
              this.getProducts(this.$route.params.id);
            } else {
              const active = this.active;
              this.blockedLazy = active;
              this.active = null;
              this.getProductCategories(active);
            }
          }
        }
      }
    },
    isDeals (data, type) {
      let show = false;
      Object.values(data).map(item => {
        if (item.type === type) {
          show = true;
        }
      });
      return show;
    },
    getPrice (p) {
      if (p.specs) {
        return '$ --';
      } else {
        if (p.percentOff) {
          const descountPrice = p.price - (p.price * p.percentOff / 100);
          return Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(descountPrice);
        } else {
          return Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(p.price);
        }
      }
    },
    validateDescount (p) {
      if (p.percentOff && p.percentOff > 0) {
        return true;
      } else {
        return false;
      }
    },
    getInfoVendor (id) {
      this.$store.commit('setLoader', true);
      this.$http.get('/marketplace/client/vendor/' + id).then(({ data }) => {
        this.isAvail(data.data);
        this.$store.commit('setVendor', data.data);
        this.load = true;
        if (this.getVendor.showMessageInOpen === 1) {
          setTimeout(() => {
            this.PopUpOpen = true;
            this.$store.commit('setLoader', false);
          }, 1880);
        } else {
          this.$store.commit('setLoader', false);
        }
      }).catch(err => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      }).finally(() => {
        this.getCategories(id, this.getVendor.template);
        this.getBanners(this.getVendor._id);
        if (this.getVendor.template !== 'intermediate') {
          this.getProducts(id);
        }
        if (this.getVendor.closed === 1 || this.getVendor.isActive === 0 || this.getVendor.isOpen === 0) {
          this.popClosed = true;
        } else {
          this.popClosed = false;
        }
        // if (this.$route.params.id == "5eb561b7d00816458c58aa43" && !this.popClosed) {
        //   this.PopUpOpen = true
        // }
        if (this.getVendor.onlinePay !== undefined) {
          const mp = _.find(this.getVendor.onlinePay, { provider: 'mercadoPago' });
          if (mp !== undefined) {
            let accountId = 'precompro';
            if (this.getVendor.accountName !== undefined) {
              if (this.getVendor.accountName !== 0) {
                accountId = this.getVendor.accountName;
              }
            }
            window.Mercadopago.setPublishableKey(credentials[process.env.NODE_ENV][accountId]);
          }
        }
        if (this.getVendor.modeMenu === 0) {
          if (this.$route.path.split('/')[2] === 'restaurant') {
            document.getElementById('app').addEventListener('scroll', () => {
              this.lazyLoad();
            });
          }
        }
        const thes = this;
        if (thes.getVendor.disableMap && thes.getVendor.disableMap === 1) {
          if (thes.getVendor.deliveryType && thes.getVendor.deliveryType.length > 0) {
            const deliveryType = thes.getVendor.deliveryType.find((deliveryType) => deliveryType.type === 'Diligencia los datos de entrega');
            if (deliveryType !== undefined) {
              LocalStorage.set('showFields', true);
            } else {
              LocalStorage.remove('showFields');
            }
          }
          LocalStorage.set('tienda_Coverage_client', 'recogerTienda');
          return;
        }
        if ((LocalStorage.get('center_Coverage_client') && LocalStorage.get('address_Coverage_client')) || LocalStorage.get('tienda_Coverage_client')) {
          if (LocalStorage.get('tienda_Coverage_client')) {
            if (thes.getVendor && thes.getVendor.length === undefined) {
              if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' }) && LocalStorage.get('tienda_Coverage_client')) {
              } else {
                thes.openCoverage();
              }
            } else {
              setTimeout(function () {
                if (thes.getVendor && thes.getVendor.length === undefined) {
                  if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' }) && LocalStorage.get('tienda_Coverage_client')) {
                  } else {
                    thes.openCoverage();
                  }
                } else {
                  setTimeout(function () {
                    if (thes.getVendor && thes.getVendor.length === undefined) {
                      if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' }) && LocalStorage.get('tienda_Coverage_client')) {
                      } else {
                        thes.openCoverage();
                      }
                    } else {
                      setTimeout(function () {
                        if (thes.getVendor && thes.getVendor.length === undefined) {
                          if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' }) && LocalStorage.get('tienda_Coverage_client')) {
                          } else {
                            thes.openCoverage();
                          }
                        } else {
                          setTimeout(function () {
                            if (_.find(thes.getVendor.deliveryType, { type: 'Recoger en Restaurante' }) && LocalStorage.get('tienda_Coverage_client')) {
                            } else {
                              thes.openCoverage();
                            }
                          }, 200);
                        }
                      }, 200);
                    }
                  }, 200);
                }
              }, 200);
            }
          } else {
            this.queryAddress();
          }
        } else {
          this.openCoverage();
        }
      });
    },
    getBanners (id) {
      this.$store.commit('setLoader', true);
      const data = {};
      const image = [];
      data.vendorId = id;
      this.$http.post('/marketplace/client/listBanners', data).then(({ data }) => {
        Object.values(data.data).map((item) => {
          image.push({ image: item.image });
        });
        this.carousels = image;
        this.$store.commit('setLoader', false);
      }).catch(err => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    getCategories (id, type) {
      this.$store.commit('setLoader', true);
      this.$http.get('/marketplace/client/categories/' + id + '/' + type).then(({ data }) => {
        if (this.getVendor.template !== 'intermediate') {
          this.categories = data.data;
        } else {
          this.intermediate = data.data;
        }
      }).catch(err => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      }).finally(() => {
        this.$store.commit('setLoader', false);
      });
    },
    getProducts (id) {
      if (this.pageLoad < this.pageFull || this.pageLoad === 0) {
        this.loadingProducts = true;
        if (this.loadingProducts) {
          const paginate = this.pageLoad + 1;
          this.pageLoad = paginate;
          this.$http.get('/marketplace/client/products/' + id + '?page=' + paginate).then(({ data }) => {
            this.pageLoad = data.data.current_page;
            this.pageFull = data.data.last_page;
            if (!this.mergedProducts) {
              this.products = [];
              this.mergedProducts = true;
            }
            const restaurants = Array.isArray(data.data.data) ? data.data.data : [data.data.data];
            this.products.push(...restaurants);
            this.$store.commit('setLoader', false);
            this.loadingProducts = false;
          }).catch(err => {
            this.$store.commit('setLoader', false);
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(err.request.response, err.request.status),
              position: 'is-top',
              type: 'is-danger'
            });
          }).finally(() => {
            this.$store.commit('setLoader', false);
          });
        }
      }
    },
    getProductCategories (id) {
      if (this.active === id) {
        this.pageLoad = 0;
        this.pageFull = 0;
        this.pageLoadCat = 0;
        this.pageFullCat = 0;
        this.pageLoadCatMed = 0;
        this.pageFullCatMed = 0;
        this.active = null;
        this.activeMedium = null;
        this.getCategories(this.$route.params.id, this.getVendor.template);
        this.medium = [];
        const cat = _.find(this.categories, { _id: id });
        if (cat.subCategories !== undefined) {
          if (this.getVendor.template !== 'intermediate') {
            this.pageLoad = 0;
            this.mergedProducts = false;
            this.getProducts(this.$route.params.id);
          } else {
            this.getProductCategoriesIntermediate(cat.parentId);
          }
        } else {
          this.pageLoad = 0;
          this.mergedProducts = false;
          this.getProducts(this.$route.params.id);
        }
      } else {
        if (this.active !== id && this.blockedLazy !== id) {
          this.pageLoadCat = 0;
          this.pageFullCat = 0;
          this.blockedLazy = null;
        }
        this.active = id;
        const cat = _.find(this.categories, { _id: id });
        if (cat.subCategories !== undefined) {
          this.medium = cat.subCategories;
          if (this.pageLoadCat < this.pageFullCat || this.pageLoadCat === 0) {
            this.loadingProducts = true;
            if (this.pageLoadCat === 0) {
              this.mergedProducts = false;
            }
            if (this.loadingProducts) {
              const paginate = this.pageLoadCat + 1;
              this.pageLoadCat = paginate;
              this.$http.get('/marketplace/client/products/' + this.$route.params.id + '/child/' + id + '?page=' + paginate).then(({ data }) => {
                this.pageLoadCat = data.data.current_page;
                this.pageFullCat = data.data.last_page;
                if (!this.mergedProducts) {
                  this.products = [];
                  this.mergedProducts = true;
                }
                const restaurants = Array.isArray(data.data.data) ? data.data.data : [data.data.data];
                this.products.push(...restaurants);
                this.loadingProducts = false;
              }).catch(err => {
                this.$buefy.toast.open({
                  duration: 3000,
                  message: this.$parent.listErrors(err.request.response, err.request.status),
                  position: 'is-top',
                  type: 'is-danger'
                });
                this.medium = [];
              });
            }
          }
        } else {
          this.medium = [];
          if (this.pageLoadCat < this.pageFullCat || this.pageLoadCat === 0) {
            this.loadingProducts = true;
            if (this.pageLoadCat === 0) {
              this.mergedProducts = false;
            }
            if (this.loadingProducts) {
              const paginate = this.pageLoadCat + 1;
              this.pageLoadCat = paginate;
              this.$http.get('/marketplace/client/products/' + this.$route.params.id + '/categories/' + id + '?page=' + paginate).then(({ data }) => {
                this.pageLoadCat = data.data.current_page;
                this.pageFullCat = data.data.last_page;
                if (!this.mergedProducts) {
                  this.products = [];
                  this.mergedProducts = true;
                }
                const restaurants = Array.isArray(data.data.data) ? data.data.data : [data.data.data];
                this.products.push(...restaurants);
                this.loadingProducts = false;
                this.$store.commit('setLoader', false);
              }).catch(err => {
                this.$store.commit('setLoader', false);
                this.$buefy.toast.open({
                  duration: 3000,
                  message: this.$parent.listErrors(err.request.response, err.request.status),
                  position: 'is-top',
                  type: 'is-danger'
                });
                this.medium = [];
              });
            }
          }
        }
      }
    },
    getProductCategoriesMedium (id) {
      if (this.activeMedium === id) {
        this.activeMedium = null;
        this.pageLoadCatMed = 0;
        this.pageFullCatMed = 0;
        this.pageLoadCat = 0;
        this.pageFullCat = 0;
        if (this.getVendor.template !== 'intermediate') {
          const cat = _.find(this.medium, { _id: id });
          if (this.pageLoadCatMed < this.pageFullCatMed || this.pageLoadCatMed === 0) {
            this.loadingProducts = true;
            if (this.pageLoadCatMed === 0) {
              this.$store.commit('setLoader', true);
              this.mergedProducts = false;
            }
            if (this.loadingProducts) {
              const paginate = this.pageLoadCatMed + 1;
              this.pageLoadCatMed = paginate;
              this.$http.get('/marketplace/client/products/' + this.$route.params.id + '/child/' + cat.parentId).then(({ data }) => {
                this.pageLoadCatMed = data.data.current_page;
                this.pageFullCatMed = data.data.last_page;
                if (!this.mergedProducts) {
                  this.products = [];
                  this.mergedProducts = true;
                }
                const restaurants = Array.isArray(data.data.data) ? data.data.data : [data.data.data];
                this.products.push(...restaurants);
                this.loadingProducts = false;
              }).catch(err => {
                this.$buefy.toast.open({
                  duration: 3000,
                  message: this.$parent.listErrors(err.request.response, err.request.status),
                  position: 'is-top',
                  type: 'is-danger'
                });
                this.medium = [];
              }).finally(() => {
                this.$store.commit('setLoader', false);
              });
            }
          }
        } else {
          this.getProductCategoriesIntermediate(this.intermediateId);
        }
      } else {
        if (this.active !== id && this.blockedLazy !== id) {
          this.pageLoadCatMed = 0;
          this.pageFullCatMed = 0;
        }
        const name = this.cateName(id);
        const app = document.getElementById('app');
        const child = document.getElementById(name);
        this.scrollParentToChild(app, child);
      }
    },
    scrollParentToChild (parent, child) {
      var parentRect = parent.getBoundingClientRect();
      var childRect = child.getBoundingClientRect();
      const scrollLeft = childRect.top - parentRect.top;
      parent.scrollTop += (scrollLeft - 80);
      return scrollLeft;
    },
    getProductCategoriesIntermediate (id) {
      this.$store.commit('setLoader', true);
      this.intermediateId = id;
      const cat = _.find(this.intermediate, { _id: id });
      this.intermediateName = cat.name;
      if (cat.subCategories !== undefined) {
        this.categories = cat.subCategories;
      }
      this.$http.get('/marketplace/client/products/' + this.$route.params.id + '/all/' + id).then(({ data }) => {
        this.$store.commit('setLoader', false);
        this.products = data.data;
      }).catch(err => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
        this.medium = [];
      });
    },
    showItem (p, v) {
      if (p.specs || p.showInpopUp === 1) {
        const data = {};
        data.product = p;
        data.vendor = v;
        data.catName = this.cateName(p.categoryId);
        this.$store.commit('setProductInfo', data);
      } else {
        this.setProductToCart(p);
      }
    },
    setProductToCart (product) {
      if (this.validateProductPromo(product)) {
        this.productShowMess = product;
        const info = {};
        const data = {};
        const thes = this;
        data.products = [];
        data.extra = 0;
        data.observation = null;
        product.options = data;
        product.quantity = 1;
        info.sessionId = LocalStorage.get('domicompro-uid-user');
        info.productId = product._id;
        info.products = product;
        this.$store.commit('setLoader', true);
        this.$http.post('/marketplace/client/cart/add', info).then(({ data }) => {
          this.$store.commit('setLoader', false);
          this.messCart = true;
          this.$store.commit('setCart', data.data);
          setTimeout(function () {
            thes.messCart = false;
            this.productShowMess = [];
          }, 3000);
        }).catch(err => {
          this.$store.commit('setLoader', false);
          this.$buefy.toast.open({
            duration: 1000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No se pueden agregar más productos de promoción al carrito',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    validateProductPromo (product) {
      const cartProducts = this.getCart.products;
      let cartLength = 0;
      if (cartProducts !== undefined) {
        cartLength = cartProducts.length;
      }
      const maxPromo2x1 = this.getVendor.maxPromo2x1;
      const maxPromo3x2 = this.getVendor.maxPromo3x2;
      let promo2x1 = 0;
      let promo3x2 = 0;
      if (cartLength === 0 || product.deal.length === 0 || (maxPromo2x1 === undefined && product.deal[0].type === '2x1') || (maxPromo3x2 === undefined && product.deal[0].type === '3x2')) {
        return true;
      } else {
        _.forEach(cartProducts, function (pd) {
          if (Object.keys(pd.deal).length > 0) {
            if (pd.deal[0].type === '2x1') {
              promo2x1 = promo2x1 + pd.quantity;
            }
            if (pd.deal[0].type === '3x2') {
              promo3x2 = promo3x2 + pd.quantity;
            }
          }
        });
        const total2x1 = Math.floor(promo2x1 / 2);
        const total3x2 = Math.floor(promo3x2 / 3);
        if (total2x1 < maxPromo2x1 && product.deal[0].type === '2x1') {
          return true;
        }
        if (total3x2 < maxPromo3x2 && product.deal[0].type === '3x2') {
          return true;
        }
      }
      return false;
    },
    searchProductsQuery (string) {
      clearTimeout(this.timeout);
      const instance = this;
      this.timeout = setTimeout(function () {
        if (string !== '' && string.length >= 3) {
          instance.$parent.isLoadingGlobal = true;
          const data = {};
          data.vendorId = instance.$route.params.id;
          data.search = string;
          instance.$http.post('marketplace/client/search/product', data).then(({ data }) => {
            if (data.data != null) {
              instance.products = data.data;
            } else {
              instance.products = [];
            }
            instance.$parent.isLoadingGlobal = false;
          }).catch(err => {
            instance.$parent.isLoadingGlobal = false;
            instance.$buefy.toast.open({
              duration: 3000,
              message: this.$parent.listErrors(err.request.response, err.request.status),
              position: 'is-top',
              type: 'is-danger'
            });
          });
        } else if (string === '') {
          instance.$parent.isLoadingGlobal = true;
          instance.getProducts(instance.$route.params.id);
        }
      }, 500);
    },
    async filters (filter) {
      if (this.filter === filter) {
        this.filter = null;
        this.products = this.productsOld;
      } else {
        this.filter = filter;
        this.productsOld = this.products;
        this.$store.commit('setLoader', true);
        await Promise.all(_.orderBy(this.products, 'price', filter)).then((data) => {
          this.$store.commit('setLoader', false);
          this.products = data;
        });
      }
    },
    cateName (id) {
      const name = _.find(this.categories, { _id: id });
      if (name !== undefined) {
        if (name.name !== undefined) {
          return name.name;
        } else {
          return 'Sin categoria';
        }
      } else {
        return 'Sin categoria';
      }
    },
    backIntermediate () {
      this.products = [];
      this.categories = [];
      this.medium = [];
      this.intermediateId = null;
      this.intermediateName = null;
      this.getCategories(this.$route.params.id, this.getVendor.template);
    },
    findCategory (id) {
      const productFilter = [];
      this.products.map(item => {
        if (item.categoryId === id) {
          productFilter.push(item);
        }
      });
      return productFilter;
    },
    scrollPosition (id) {
      const move = document.getElementById('category-' + id).offsetTop;
      document.getElementById('app').scrollTop = move;
    },
    scrollReset () {
      document.getElementById('app').scrollTop = 0;
    },
    isAvail (times) {
      const dayOfWeek = parseInt(this.$moment().format('d'));
      const timeCurrent = this.$moment().format('HH:mm');
      if (times.dayTimes !== undefined) {
        const data = _.find(times.dayTimes, { day: dayOfWeek });
        if (data) {
          if (data.hasAttention === 1) {
            if (timeCurrent >= data.open && timeCurrent <= data.close) {
              times.closed = 0;
            } else {
              times.closed = 1;
              times.open = 'Abre a las ' + this.$moment(new Date('1970-01-01 ' + data.open)).format('HH:mm A');
              times.isProgramnig = 1;
            }
          } else {
            times.closed = 1;
            times.open = 'Hoy no abre';
            times.isProgramnig = 0;
          }
        }
      } else {
        times.closed = 1;
        times.open = 'Hoy no abre';
        times.isProgramnig = 0;
      }
      return times;
    },
    productInCar (pro) {
      const ver = _.find(this.getCart.products, { _id: pro._id });
      if (ver !== undefined) {
        return false;
      } else {
        return true;
      }
    },
    productQuantity (pro) {
      const ver = _.find(this.getCart.products, { _id: pro._id });
      if (ver !== undefined) {
        return ver.quantity;
      } else {
        return true;
      }
    },
    deleteProductCart (id, p, product) {
      this.loading = true;
      this.messDeletePro = product;
      const pos = _.findIndex(this.getCart.products, { _id: id });
      const thes = this;
      const data = {};
      data.index = pos;
      this.$http.put('marketplace/client/cart/' + LocalStorage.get('domicompro-uid-user') + '/item/' + id, data).then(({ data }) => {
        this.$store.commit('setCart', data.data);
        this.loading = false;
        this.messDelete = true;
        setTimeout(function () {
          thes.messDelete = false;
          thes.messDeletePro = [];
        }, 3000);
      }).catch(err => {
        this.loading = false;
        this.$buefy.toast.open({
          duration: 1000,
          message: this.$parent.listErrors(err.request.response, err.request.status),
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    updateCart (product, type, index) {
      this.messUpdatePro = product;
      const thes = this;
      const prodCart = _.find(this.getCart.products, { _id: product._id });
      const pos = _.findIndex(this.getCart.products, { _id: product._id });
      if (this.validateProductPromo(prodCart) || type === 'min') {
        this.loading = true;
        if (type === 'max') {
          prodCart.quantity += 1;
        }
        if (type === 'min') {
          prodCart.quantity -= 1;
        }
        prodCart.index = pos;
        this.$http.put('marketplace/client/cart/update/' + LocalStorage.get('domicompro-uid-user') + '/item/' + prodCart._id, prodCart).then(({ data }) => {
          this.$store.commit('setCart', data.data);
          this.messUpdate = true;
          setTimeout(function () {
            thes.messUpdate = false;
            thes.messUpdatePro = [];
          }, 3000);
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 1000,
            message: this.$parent.listErrors(err.request.response, err.request.status),
            position: 'is-top',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No se pueden agregar más productos de promoción al carrito',
          position: 'is-top',
          type: 'is-danger'
        });
      }
    },
    openCoverage () {
      const thes = this;
      this.$buefy.modal.open({
        parent: thes,
        component: deliveryAddress,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        customClass: 'modalCoverage',
        width: '370',
        props: {
          idV: thes.$route.params.id
        },
        events: {
          // setDataCoverage (event) {
          //   thes.restaurans = event
          // }
        }
      });
    },
    queryAddress () {
      const center = LocalStorage.get('center_Coverage_client');
      center.vendorId = this.$route.params.id;
      this.$http.post('marketplace/client/coverage/validate', center).then(({ data }) => {
        if (!data.coverageStatus) {
          this.openCoverage();
        }
      }).catch(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    searchProducts () {
      this.searchProductsQuery(this.searchProducts);
    },
    query () {
      this.$emit('query', this.query);
    },
    getVendor () {
      if (Object.keys(this.getVendor).length > 0) {
        if (this.getVendor.configuration !== undefined) {
          this.showCustom = false;
          console.log('====================================');
          console.log('config', this.getVendor.configuration);
          console.log('====================================');
          if (this.getVendor.configuration.toolbarColor !== undefined) {
            this.config.toolbarColor = this.getVendor.configuration.toolbarColor;
          }
          if (this.getVendor.configuration.backCardProduct !== undefined) {
            this.config.backCardProduct = this.getVendor.configuration.backCardProduct;
          }
          if (this.getVendor.configuration.textColorDescriptionCard !== undefined) {
            this.config.textColorDescriptionCard = this.getVendor.configuration.textColorDescriptionCard;
          }
          if (this.getVendor.configuration.textColorTitleCard !== undefined) {
            this.config.textColorTitleCard = this.getVendor.configuration.textColorTitleCard;
          }
          if (this.getVendor.configuration.textColorPriceCard !== undefined) {
            this.config.textColorPriceCard = this.getVendor.configuration.textColorPriceCard;
          }
          if (this.getVendor.configuration.footerColor !== undefined) {
            this.config.footerColor = this.getVendor.configuration.footerColor;
          }
          if (this.getVendor.configuration.footerTextColor !== undefined) {
            this.config.footerTextColor = this.getVendor.configuration.footerTextColor;
          }
          if (this.getVendor.configuration.buttonColor !== undefined) {
            this.config.buttonColor = this.getVendor.configuration.buttonColor;
          }
          if (this.getVendor.configuration.buttonTextColor !== undefined) {
            this.config.buttonTextColor = this.getVendor.configuration.buttonTextColor;
          }
          if (this.getVendor.configuration.textColorMap !== undefined) {
            this.config.textColorMap = this.getVendor.configuration.textColorMap;
          }
          if (this.getVendor.configuration.circleColorMap !== undefined) {
            this.config.circleColorMap = this.getVendor.configuration.circleColorMap;
          }
          this.showCustom = true;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.dv {
  margin-top: 10px;
}
.box-shadow-class {
  box-shadow: 4px 2px 14px rgba(0, 0, 0, 0.25);
}
.loadingProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  svg {
    margin-right: 5px;
  }
}
.detail-modal {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  background: var(--buttonBackground) !important;
  color: var(--buttonTextColor);
  border-radius: 10px;
  .img-modal {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    .btnBackModal {
      display: flex;
      font-size: 16px;
      position: absolute;
      right: 20px;
      top: 15px;
      cursor: pointer;
      z-index: 1;
      color: var(--buttonTextColor);
    }
    .imgProductoModal {
      width: 95%;
      height: 300px;
      margin: 10px;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
      // box-shadow: 4px 2px 14px rgba(0, 0, 0, 0.25);
    }
  }
  .btn-add-hover-modal {
    background: var(--buttonTextColor);
    padding: 1px 10px 2px 0px;
    color: var(--buttonBackground);
    bottom: 10px;
    display: flex;
    gap: 0.7rem;
    border-radius: 5px;
    max-width: 150px;
    width: 76%;
    justify-content: center;
    align-items: center;
    animation: fade 0.5s;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    p {
      color: var(--buttonBackground);
    }
    &:hover {
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(2px);
    }
    .contBtnPlus {
      .iconColor {
        color: var(--buttonBackground);
      }
    }
    .contBtnPlus {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--buttonBackground);
      // background: var(--buttonBackground);
      // box-shadow: 0px 3px 6px #00000082;
      .svg-inline--fa {
        font-size: 11px;
      }
    }
  }
  .contPriceBtnModal {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    gap: 2rem;

    .showMore {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pricesContModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 4px;
    .priceComplete {
      margin-right: 3px;
      font-size: 13px;
    }
    .discountText {
      font-size: 13px;
      margin-right: 3px;
      color: #9faab7;
      text-decoration: line-through;
    }
    .discountPercent {
      font-size: 11px;
      color: #fff;
      padding: 1px 3px;
      text-align: center;
      border-radius: 8px;
      font-size: 10px;
      background-color: #ff2425;
    }
  }
  .category {
    text-align: start;
    border: 1px solid var(--buttonTextColor);
    padding-left: 5px;
    padding-right: 5px;
    width: max-content;
    color: var(--buttonTextColor);
  }
  .description-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // gap: 1rem;
    padding-left: 16px;
    .category {
      margin-bottom: 23px;
    }
    .description {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      max-height: 112px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 3px!important;
        height: 112px!important;
      }
      &::-webkit-scrollbar-track {
        background: var(--buttonBackground)!important;
        border-radius: 2px!important;
      }
    }
  }
}
#restaurant {
  .search-bar-mobile {
    display: none;
    width: 100%;
    margin-top: 7px;
    padding-left: 12px;
    padding-right: 15px;
    background: #fff;
  }
  .search-button-mobile {
    flex: 1;
    width: 200px;
    height: 24px !important;
    background: #fff;
    border: 1px solid #a8adb5;
    border-radius: 8px;
    margin: 0 !important;
  }
  .content_icon_mobile {
    padding-right: 10px;
  }
  .content_input_mobile {
    flex: 1;
  }
  .content_btn_mobile {
    all: unset;
    text-align: start;
    font-size: 10px;
    color: #444b57;
    background: none;
    border: none;
    width: 100%;
    font-size: 13px;
  }
  @media (min-width: 636px) {
    #searchMenu {
      display: none;
    }
  }
  @media (max-width: 635px) {
    #searchMenu {
      display: flex;
    }
    .search-bar-mobile {
      display: flex;
      padding-left: 12px;
      padding-right: 15px;
      padding-top: 10px;
    }
  }

  .input-nav {
    font-family: "segoe";
    font-size: 13;
    color: inherit;
    background-color: #fff;
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 100px;
    width: 233px;
    max-width: 250px;
    transition: all 0.2s;
    height: 35px;
    box-shadow: 0px 3px 6px #00000045;
    &:focus {
      outline: none;
      width: 250px;
      background-color: #fff;
    }
  }
  .queryCont {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-4%, -17px);
    box-shadow: 0px 3px 6px #000000a3;
    background: #ffffff;
    border-radius: 100%;
    padding: 7px;
    cursor: pointer;
  }
  .left {
    .text {
      &.big {
        font-size: 25px;
      }
    }
    .btn {
      &.active {
        #fa-arrow {
          display: flex;
        }
      }
    }
    #fa-arrow {
      display: none;
      color: #7a7a7a;
      position: absolute;
      left: 40px;
      top: 17px;
    }
  }
  .notCategori {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .btn-filter-active {
    border: 2px solid var(--buttonBackground) !important;
    p {
      color: var(--buttonBackground) !important;
    }
  }
  .products {
    &.cursor {
      cursor: pointer;
    }
  }
  .pricesCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 4px;
    .priceComplete {
      color: var(--textColorPriceCard);
      margin-right: 3px;
      font-size: 11px;
    }
    .discountText {
      font-size: 13px;
      margin-right: 3px;
      color: #9faab7;
      text-decoration: line-through;
    }
    .discountPercent {
      font-size: 11px;
      color: #fff;
      padding: 1px 3px;
      text-align: center;
      border-radius: 8px;
      font-size: 10px;
      background-color: #ff2425;
    }
  }
  #messCart {
    position: fixed;
    z-index: 1;
    right: 10px;
    width: 95%;
    background: #fff;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
    animation: bottomToTop 1s;
    .cont-info {
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-left: 12px;
      .add-pro {
        span {
          color: var(--buttonBackground);
        }
        p {
          color: var(--buttonBackground);
        }
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
      }
    }
    .message-body {
      box-shadow: 0px 0px 6px #00000078;
      background: var(--buttonTextColor);
      color: var(--buttonBackground);
      // background: #ffffff;
      height: 90px;
      // width: 350px;
      border-color: inherit;
      border-radius: 0px 0px 7px 7px;
      border-style: inherit;
      border-width: 0 0 0 0;
      color: #4a4a4a;
      padding: 0 0;
      .media {
        height: 100%;
        width: 100%;
        .media-content {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
      }
    }
    .img-food {
      height: 90px;
      width: 90px;
      border-radius: 5px 0 0 5px;
    }
    #cart-plus {
      height: 50px;
      width: 50px;
      color: #3d9970;
      margin-left: 20px;
    }
  }
  #modal-pop {
    .animation-content {
      background-color: transparent !important;
    }
    .modal-content {
      max-height: calc(100vh - 66px);
      // .modal-close{
      //           display: flex !important;
      // }
    }
  }
  // .carousel{
  //   &.carousel-banner{
  //     width: 100%;
  //   }
  // }
  .custom-carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -13px;
    margin-bottom: 10px;
    .slick-slider {
      &.slickCarousel {
        width: 100%;
        // max-width: 1200px;
        max-height: 480px;
        .banner {
          min-height: 400px;
          width: 590px !important;
        }
        .img-carousel {
          width: 100%;
          height: 100px;
          max-height: 100px;
          background-position: center center;
        }
        .slick-track {
          top: 4px;
        }
      }
    }
  }
  #modal-closed {
    .animation-content {
      background-color: transparent !important;
    }
    .modal-content {
      max-height: 590px!important;
      .cont {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        #tools-icons {
          width: 30px;
          height: 30px;
          margin-bottom: 15px;
        }
      }
      // .modal-close{
      //display: flex !important;
      // }
    }
  }
  .notices .notification {
    box-shadow: 0px 0px 6px #00000078;
    background: #ffffff;
    height: 90px;
    width: 350px;
    .delete {
      display: none;
    }
  }
  .cont-info-descrip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    // padding: 5px 0;
    width: 100%;
    .category {
      color: var(--textColorDescriptionCard);
      border: 1px solid var(--textColorDescriptionCard);
      padding-left: 5px;
      padding-right: 5px;
    }
    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      -webkit-line-clamp: 3;
      font-size: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      padding-right: 10px;
      text-align: left;
    }
    p {
      color: var(--textColorDescriptionCard);
    }
  }
  .imgProducto {
    width: 140px;
    height: 132px;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    // box-shadow: 0px 9px 11px #00000099;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  }
  .contPriceBtn {
    display: flex;
    width: 100%;
    // gap: 0.4rem;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 3px;
    .showMore {
      padding-right: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .btn-minus-plus {
    width: 76px;
    display: flex;
    align-items: center;
    #minus {
      min-width: 30px;
      min-height: 26px;
      background: var(--buttonTextColor);
      border-radius: 10px 0 0 10px;
      position: relative;
      cursor: pointer;
      #icon-menos {
        height: 12px;
        width: 12px;
        position: absolute;
        color: var(--buttonBackground);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    #quantity {
      width: 20px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--buttonTextColor);
      position: relative;
    }
    #plus {
      min-width: 30px;
      min-height: 26px;
      background: var(--buttonTextColor);
      border-radius: 0 10px 10px 0;
      position: relative;
      cursor: pointer;
      #icon-mas {
        height: 12px;
        width: 12px;
        color: var(--buttonBackground);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
