<template>
  <div id="category-medium">
    <div class="category-cont" id="medium-cont" v-dragscroll.x="true">
      <div
        :class="{'active': active == cat._id , 'disabled': cat.isActive == 0}"
        :key="'catMed#' + cat.name + '-' + index"
        @click="(cat.isActive == 0) ? false : $emit('selectCat', cat._id) "
        class="sub-category"
        v-for="(cat, index) in list"
      >
        <p class="text bold center size-18 ellipsis-cat" style="width: 100px;">{{maxCharacters(cat.name)}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['categories', 'active'],
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.list = this.categories;
  },
  methods: {
    maxCharacters (cat) {
      if (cat.length >= 17) {
        return `${cat.substr(0, 16)}...`;
      }
      return cat;
    },
    scrolling (dir) {
      if (dir === 'left') {
        let content = document.getElementById('medium-cont').scrollLeft;
        const cont = content;
        const move = 200;
        content = cont - move;
        document.getElementById('medium-cont').scrollLeft = content;
      } else {
        let content = document.getElementById('medium-cont').scrollLeft;
        const cont = content;
        const move = 200;
        content = cont + move;
        document.getElementById('medium-cont').scrollLeft = content;
      }
    }
  },
  watch: {
    categories () {
      const order = _.orderBy(this.categories, 'order', 'asc');
      this.list = order;
    }
  }
};
</script>
<style lang="scss">
#category-medium {
  width: 100%;
  position: relative;
  // padding: 0 30px;
  .category-cont {
    display: flex;
    -webkit-box-pack: center;
    // padding-top: 22px;
    padding-bottom: 20px;
    // padding: 10px 0;
    margin: 0 10px;
    position: relative;
    overflow: auto;
    gap: 1rem;
    // scroll-behavior: smooth;
    animation: fadeSlow 0.5s;
    .sub-category {
      display: flex;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1607843137);
      background: #f5f5f6;
      border-radius: 40px;
      justify-content: center;
      align-items: center;
      padding: 5px 16px;
      width: 130px;
      height: 30px;
      // border: 1px solid #d3d3d3;
      cursor: pointer;
      .text {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
      &.active {
        background: var(--buttonBackground);
        // border-color: var(--buttonBackground);
        // background: var(--buttonBackground);
        .text {
          color: var(--buttonTextColor);
          font-weight: 900;
        }
      }
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }
  #arrow-left {
    color: var(--buttonBackground);
    position: absolute;
    cursor: pointer;
    left: 0;
    height: 35px;
    width: 35px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    padding: 6px;
    z-index: 1;
  }
  #arrow-right {
    color: var(--buttonBackground);
    position: absolute;
    cursor: pointer;
    right: 0;
    height: 35px;
    width: 35px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    padding: 6px;
    z-index: 1;
  }
}
</style>
