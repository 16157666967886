<template>
<div>
<CustomNavBar @query ="search = $event"></CustomNavBar>
<RestaurantPage @query ="search = $event" :searchProducts ="search"></RestaurantPage>
<Footer></Footer>
</div>
</template>
<script>
import RestaurantPage from '@/components/templateBasic/restaurantPage';
import CustomNavBar from '@/components/templateBasic/navBar';
import Footer from '@/components/templateBasic/Footer';
export default {
  components: { RestaurantPage, CustomNavBar, Footer },
  data () {
    return {
      search: null
    };
  }
};
</script>
<style lang="scss">
</style>
