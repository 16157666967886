<template>
  <div>
    <CustomNavBar></CustomNavBar>
    <OrderQueryPage></OrderQueryPage>
    <Footer></Footer>
  </div>
</template>
<script>
import OrderQueryPage from '@/components/templateBasic/orderQueryPage';
import CustomNavBar from '@/components/templateBasic/navBar';
import Footer from '@/components/templateBasic/Footer';
export default {
  components: { OrderQueryPage, CustomNavBar, Footer },
  data () {
    return {
    };
  }
};
</script>
<style lang="scss">
#queryOrder{
 #icon-logo-query{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 30px;
  }
 .cursor {
    cursor: pointer;
 }
 }
</style>
