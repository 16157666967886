<template>
  <div class="cont-test-cat" id="cont-cont-cat" style="padding-bottom: 25px;">
    <div class="test-cat" id="contenedor-categoria" v-dragscroll.x="true">
      <div
        :class="{ active: active == cat._id, disabled: cat.isActive == 0 }"
        :key="'catBas#' + cat.name + '-' + index"
        :style="[active == cat._id && {'margin-right': '15px', 'margin-left': '15px'}]"
        @click="cat.isActive == 0 ? false : $emit('selectCat', cat._id)"
        class="cat"
        v-for="(cat, index) in list"
        id="basic-category"
      >
        <div
          :style="{
          'background-image': 'url(' + cat.image + ')',
          'background-size': 'cover',
        }"
          class="logo"
        >
          <div class="margin"></div>
          <p
            :class="[cat.image && 'white']"
            class="text bold center size-14 ellipsis-cat"
          >{{ cat.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['categories', 'active'],
  data () {
    return {
      list: []
    };
  },
  mounted () {
    const order = _.orderBy(this.categories, 'order', 'asc');
    const filter = _.filter(order, (o) => {
      return !o.parentId;
    });
    this.list = filter;
  },
  methods: {
    scrolling (dir) {
      if (dir === 'left') {
        let content = document.getElementById('contenedor-categoria')
          .scrollLeft;
        const cont = content;
        const move = 200;
        content = cont - move;
        document.getElementById('contenedor-categoria').scrollLeft = content;
      } else {
        let content = document.getElementById('contenedor-categoria')
          .scrollLeft;
        const cont = content;
        const move = 200;
        content = cont + move;
        document.getElementById('contenedor-categoria').scrollLeft = content;
      }
    }
  },
  watch: {
    categories () {
      const order = _.orderBy(this.categories, 'order', 'asc');
      const filter = _.filter(order, (o) => {
        return !o.parentId;
      });
      this.list = filter;
    }
  }
};
</script>
