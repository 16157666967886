<template>
  <div class="popUP">
    <div class="img-cont">
      <div @click="$emit('closeModal', false)" class="close-modal-tunning">
        <i id="icon-close" class="fas fa-times"></i>
      </div>
      <div class="cont-data" v-html="this.getVendor.openMessage"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters([
      'getVendor'
    ])
  }
};
</script>
<style lang="scss">
.popUP{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  .img-cont{
    position: relative;
    .cont-data{
      width: 100%;
      max-width: 450px;
      max-height: 600px;
      background: white;
      border-radius: 10px;
      padding: 10px;
      overflow: auto;
      img{
        width: 100%;
        max-height: 100%;
      }
    }
    .img-popUP{
      width: 450px;
      max-height: 600px;
    }
    .close-modal-tunning{
      position: absolute;
      right: 0px;
      top: 0px;
      background: #000;
      height: 35px;
      width: 35px;
      border-radius: 100%;
      border: 3px solid #fff;
      cursor: pointer;
      #icon-close{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50% , -50%);
        height: 20px;
        width: 20px;
        color: #fff;
      }
    }
  }
  @media (max-height: 670px){
    .img-cont{
      .img-popUP{
        max-height: 500px;
      }
    }
  }
}
</style>
