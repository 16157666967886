<template>
  <div class="containerP">
    <b-loading :can-cancel="true" :is-full-page="true" v-model="loading"></b-loading>
    <form
      @click="canPay"
      @submit.prevent="postCreditCardTransaction()"
      class="payInfo__creditInformation"
      id="pay"
      name="pay"
    >
      <input
        data-checkout="cardholderName"
        id="cardholderName"
        placeholder="Nombre como aparece en la tarjeta"
        required
        type="text"
        v-model="nameLikeCard"
      />
      <div class="selectorContentDocoument">
        <GeneralSelector
          :checkout="'docType'"
          :id="'docType'"
          :options="creditOPtionsTypePerson"
          :placeHolder="'Tipo de documento'"
          :required="true"
          @value="(e) => (typePerson = e)"
        />
      </div>

      <input
        data-checkout="docNumber"
        id="docNumber"
        placeholder="Numero de documento"
        required
        type="text"
        v-model="documentNumber"
      />
      <input id="email" placeholder="Correo" required type="email" v-model="email" />
      <div class="phoneInput">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="inputNumberChange"
        />
      </div>

      <h1>Datos de la tarjeta</h1>
      <!-- Mercado pago V2-->
      <div class="mp_fields" v-if="!creditpaymentTypePAYU">
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w50 mr10"
          id="cardNumber"
          v-if="!creditpaymentTypePAYU"
        ></div>
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w20 mr10"
          id="securityCode"
          v-if="!creditpaymentTypePAYU"
        ></div>
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w20"
          id="expirationDate"
          v-if="!creditpaymentTypePAYU"
        ></div>
      </div>
      <input
        :class="{ cvvHidden: !showCVV }"
        :maxlength="maxAllowed"
        data-checkout="cardNumber"
        id="CardNumberInput"
        pattern="[0-9]{14,21}"
        placeholder="Numero de la tarjeta"
        required
        type="text"
        v-if="creditpaymentTypePAYU"
        v-model="cardNumber"
      />
      <div :class="{ cvvHidden: !showCVV }" class="NoCuotasContainer">
        <GeneralSelector
          :disable="!payu && !cuotesReady"
          :options="CoutesLimit"
          :placeHolder="'No.Cuotas'"
          :required="true"
          @value="(e) => (cuots = e)"
          v-if="cuotesReady"
        />
      </div>

      <div :class="{ cvvHidden: !showCVV }" class="anoContainer" v-if="creditpaymentTypePAYU">
        <GeneralSelector
          :checkout="'cardExpirationYear'"
          :id="'cardExpirationYear'"
          :options="yearOptions"
          :placeHolder="'Año'"
          :required="true"
          @value="(e) => (year = e)"
        />
      </div>
      <div :class="{ cvvHidden: !showCVV }" class="mesContainer" v-if="creditpaymentTypePAYU">
        <GeneralSelector
          :checkout="'cardExpirationMonth'"
          :id="'cardExpirationMonth'"
          :options="monthOptions"
          :placeHolder="'Mes'"
          :required="true"
          @value="(e) => (month = e)"
        />
      </div>
      <div class="securePay">
        <p>Pagos seguros a través de</p>
        <span
          :class="{
            mercadoPagoImg: !creditpaymentTypePAYU,
            payuImg: creditpaymentTypePAYU,
          }"
        ></span>
      </div>
      <input
        id="transactionAmount"
        name="transactionAmount"
        type="hidden"
        v-model="cardMercadoPago.net_amount"
      />
      <input id="saveCard" style="display: none" type="checkbox" value="false" />
      <button :class="{ readyToSubmit: submitReady }" type="submit">Pagar</button>
    </form>
  </div>
</template>

<script>
import GeneralSelector from '@/components/GeneralSelector';
import inputSelector from '../habitationpayment/NumberInput.vue';
// import { v4 as uuidv4 } from 'uuid';
import LocalStorage from 'store';
export default {
  name: 'CardComponent',
  components: {
    GeneralSelector,
    inputSelector
  },
  props: ['type', 'order'],
  data () {
    return {
      cvv: '',
      showPending: false,
      showCancel: false,
      reasonDeclined: null,
      monthOptions: [],
      yearOptions: [],
      termsConditions: true,
      dataTreatment: true,
      cardType: '',
      typePersonOptions: [],
      typePerson: ' ',
      minDocumentnumbers: 9,
      maxDocumentnumbers: 9,
      documentNumber: '',
      email: '',
      name: '',
      cardNumber: '',
      nameLikeCard: '',
      payu: true,
      phone: '',
      CoutesLimit: [],
      timer: '',
      cardMercadoPago: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        provider: null,
        external_reference: null,
        payment_method_id: null,
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        numberPaid: null,
        phone: null,
        country: 'Colombia',
        bandera: 'https://api.precompro.com/banderas/co.png',
        indicativo: 57,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: null,
        expiration: null,
        balance: 0,
        checked: true,
        numberPaid: null
      },
      balancePaid: 0,
      month: '',
      year: '',
      cuots: 0,
      cuotesReady: false,
      lock: false,
      loading: false,
      creditpaymentTypePAYU: false,
      creditOPtionsTypePerson: [],
      showCVV: true,
      submitDisabled: false,
      maxAllowed: 19,
      initBandera: '',
      serverTimeCurrent: null,
      mercadopago: null,
      country: 'Colombia',
      bandera: 'https://api.precompro.com/banderas/co.png',
      indicativo: 57,
      minLength: 7,
      maxLength: 10,
      cardDigits: null

    };
  },
  mounted () {
    this.card.transaction_amount = this.order.totalPrice + this.order.delivery + this.order.tip;
    this.card.net_amount = this.order.totalPrice + this.order.delivery + this.order.tip;
    this.cardMercadoPago.net_amount = this.order.totalPrice + this.order.delivery + this.order.tip;
    this.creditpaymentTypePAYU = this.type === 'PAYU';
    if (this.creditpaymentTypePAYU) {
      this.showCVV = false;
    }
    if (this.vendor !== undefined) {
      if (this.vendor.timezone) {
        if (this.vendor.timezone !== 'America/Bogota') {
          this.$store.dispatch('getHourTimezone').then((data) => {
            this.serverTimeCurrent = this.$moment(data.datetime);
          });
        } else {
          this.$store.dispatch('getServerTime').then(({ serverTime }) => {
            this.serverTimeCurrent = this.$moment(serverTime);
          });
        }
      }
    }
    // eslint-disable-next-line
    this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
    this.getIdentification();
    this.initYearOptions();
    this.initMonthOptions();
    this.initCoutes(36);
    if (!this.creditpaymentTypePAYU) {
      this.setConfigMercadoPago();
    }
  },
  computed: {
    submitReady () {
      if (
        !this.creditpaymentTypePAYU &&
        this.nameLikeCard &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength) &&
        this.typePerson &&
        this.cuots
      ) {
        return true;
      } else if (
        this.creditpaymentTypePAYU &&
        this.nameLikeCard &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength) &&
        this.typePerson &&
        this.cardNumber &&
        this.month &&
        this.year &&
        this.cuots
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    setConfigMercadoPago () {
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'Número de la tarjeta'
      }).mount('cardNumber');
      this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY'
      }).mount('expirationDate');
      this.mercadopago.fields.create('securityCode', {
        placeholder: 'CVV'
      }).mount('securityCode');
      cardNumberElement.on('binChange', this.getPaymentMethods);
    },
    getPaymentMethods (data) {
      const { bin } = data;
      if (bin != null && bin !== undefined) {
        this.mercadopago.getPaymentMethods({ bin }).then(({ results }) => {
          this.cardDigits = bin;
          this.setPaymentMethod(results);
          this.getInstallments(bin);
        });
      } else {
        this.cardMercadoPago.payment_method_id = null;
        this.cardDigits = null;
        this.maxNumber = 19;
        this.initCoutes(36);
      }
    },
    setPaymentMethod (response) {
      const paymentMethodId = response[0].id;
      this.cardMercadoPago.payment_method_id = paymentMethodId;
      this.$store.commit('setCardTypeInfo', paymentMethodId);
    },
    getInstallments (bin) {
      this.mercadopago.getInstallments({
        amount: document.getElementById('transactionAmount').value,
        bin,
        paymentTypeId: 'credit_card'
      }).then((installments) => {
        this.cuotesReady = false;
        const data = [];
        if (installments[0]) {
          installments[0].payer_costs.forEach(installment => {
            const opt = {
              name: installment.recommended_message,
              value: installment.installments
            };
            data.push(opt);
          });
          this.CoutesLimit = [];
          this.CoutesLimit = data;
          this.cuotesReady = true;
        } else {
          this.cuotesReady = false;
          this.initCoutes(36);
        }
      }).catch((e) => {
        console.error(e);
        this.cuotesReady = false;
        this.initCoutes(36);
      });
    },
    setCardTokenAndPay (token) {
      const form = document.getElementById('pay');
      const card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', token);
      form.appendChild(card);
      this.doSubmit = true;
      this.postMercadopagoCreditCard();
    },
    inputNumberChange (value) {
      this.bandera = value.countrieInfo.bandera;
      this.indicativo = value.countrieInfo.indicativo;
      this.country = value.countrieInfo.pais;
      this.phone = value.inputValue;
    },
    minLenChange (value) {
      this.minLength = value;
    },
    maxLenChange (value) {
      this.maxLength = value;
    },
    async postCreditCardTransaction () {
      if (LocalStorage.get('showFields') !== undefined && LocalStorage.get('showFields') === true) {
        this.order.hotelData = { roomNumber: this.order.roomNumber, deliveryType: this.order.deliveryTypeCustom };
      }
      if (this.creditpaymentTypePAYU) {
        let month = this.month;
        if (month < 10) {
          month = '0' + this.month;
        }
        this.card.name = this.nameLikeCard;
        this.card.typeDocument = this.typePerson;
        this.card.identification = this.documentNumber;
        this.card.phone = this.phone;
        this.card.email = this.email;
        this.card.mes = this.month;
        this.card.ano = this.year;
        this.card.expiration = this.year + '/' + month;
        this.card.cuota = this.cuots;
        this.card.card = this.cardNumber.split(/\s+/).join('');
        this.card.address = 'Bogotá Dc';
        this.card.city = 'Bogotá Dc';
        this.card.balance = this.amount;
        this.card.numberPaid = this.numberPaid;
        this.card.provider = 'TC';
        this.card.bandera = this.bandera;
        this.card.indicativo = this.indicativo;
        this.card.country = this.country;
        this.card.userId = 13744;
        this.postPayuCreditCard();
      }
      if (!this.creditpaymentTypePAYU) {
        this.cardMercadoPago.payer.first_name = this.nameLikeCard;
        this.cardMercadoPago.payer.identification.type = this.typePerson;
        this.cardMercadoPago.payer.identification.number = this.documentNumber;
        this.cardMercadoPago.payer.email = this.email;
        this.cardMercadoPago.transaction_amount = this.amount;
        this.cardMercadoPago.net_amount = this.amount;
        this.cardMercadoPago.installments = this.cuots;
        this.cardMercadoPago.numberPaid = this.numberPaid;
        this.cardMercadoPago.provider = 'TC';
        this.cardMercadoPago.phone = this.phone;
        this.cardMercadoPago.bandera = this.bandera;
        this.cardMercadoPago.indicativo = this.indicativo;
        this.cardMercadoPago.country = this.country;
        const token = await this.mercadopago.fields.createCardToken({
          cardholderName: document.getElementById('cardholderName').value,
          identificationType: document.getElementById('docType').value,
          identificationNumber: document.getElementById('docNumber').value
        });
        this.cardMercadoPago.token = token.id;
        this.setCardTokenAndPay(token.id);
      }
    },
    postPayuCreditCard () {
      this.submitDisabled = true;
      this.loading = true;
      this.$store.commit('setLoader', true);
      this.order.mp = 0;
      this.$http.post('marketplace/payment/card', { order: this.order, cardCredit: this.card }).then(response => {
        if (response.data.code === 200) {
          LocalStorage.set('processId', response.data.data.id);
          this.$emit('approvedTransactionCard', response.data.data.id);
        } else {
          this.$emit('cancelTransactionCard', true);
          this.$store.commit('setLoader', false);
        }
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    postMercadopagoCreditCard () {
      this.submitDisabled = true;
      this.loading = true;
      this.$store.commit('setLoader', true);
      this.order.mp = 1;
      this.$http.post('marketplace/payment/card', { order: this.order, cardCredit: this.cardMercadoPago }).then(response => {
        if (response.data.code === 705) {
          this.$buefy.dialog.alert({
            message: "<i class='fa fa-exclamation-circle alerticon'></i> Ocurrió un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            confirmText: 'Aceptar',
            onConfirm: () => {
              window.location.reload();
            }
          });
        } else if (response.data.code === 780) {
          this.$emit('pendingTransactionCard', true);
        } else if (response.data.code === 200) {
          this.$emit('approvedTransactionCard', response.data.orderId);
        } else {
          this.$emit('cancelTransactionCard', true);
        }
        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.$store.commit('setLoader', false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "<i class='fa fa-exclamation-circle alerticon'></i>&nbsp;Ocurrio un error al procesar la petición con la pasarela de pago <br><strong style='color: #ffffff;'>Intenta realizar el pago de nuevo</strong> ",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    canPay () {
      if (!this.dataTreatment && !this.termsConditions) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'debes aceptar los terminos y condiciones , al igual que el tratamiento de datos personales',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        });
        this.lock = true;
      }
    },
    initYearOptions () {
      const thisYear = parseInt(this.$moment().format('YYYY'));
      for (let year = thisYear; year < thisYear + 25; year++) {
        this.yearOptions.push({ name: year, value: year });
      }
    },
    initMonthOptions () {
      for (let month = 0; month < 12; month++) {
        this.monthOptions.push({ name: month + 1, value: month + 1 });
      }
    },
    initCoutes (installments) {
      this.CoutesLimit = [];
      for (let index = 1; index < installments + 1; index++) {
        this.CoutesLimit.push({ name: index, value: index });
      }
      this.cuotesReady = true;
    },
    getIdentification () {
      this.creditOPtionsTypePerson = [
        { value: 'CC', name: 'CC - Cédula de ciudadanía' },
        { value: 'CE', name: 'CE - Cédula de extranjería' },
        { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
        { value: 'TI', name: 'TI - Tarjeta de Identidad' },
        { value: 'PP', name: 'PP - Pasaporte' },
        {
          value: 'CEL',
          name: 'CEL - En caso de identificarse a través de la línea del móvil'
        },
        { value: 'RC', name: 'RC - Registro civil de nacimiento' },
        { value: 'DE', name: 'DE - Documento de identificación extranjero' }
      ];
    }
  },
  beforeDestroy () {
    this.$store.commit('setCardTypeInfo', null);
  }
};
</script>

<style lang="scss" scoped>
.containerP{
    width: 100%;
    margin: unset;
    transform: translateX(1px);
}
.mp_fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &-item {
    height: 29px;
    border: 1px solid transparent;
    background-color: white;
    border-color: #444b57;
    border-radius: 4px;
    position: relative;
    vertical-align: top;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: #363636;
    padding-bottom: calc(0.2em - 1px);
    padding-left: calc(0.5em - 1px);
    padding-right: calc(0.5em - 1px);
    padding-top: calc(0.2em - 1px);
  }
  .w50 {
    width: 50%;
  }
  .w20 {
    width: 20%;
  }
}
@mixin imageSet($width, $height, $url) {
  background-image: url($url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: block;
}
form {
  position: relative;
  top: -1px;
  border-top: unset;
  border: solid 1px #eaebed;
  width: 858px;
  max-width: 858px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
  }
  .securePay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
    @media (min-width: 1024px) {
      padding-bottom: 3px;
    }
    .mercadoPagoImg {
      @include imageSet(40px, 24px, "../../assets/images/mercadoPagoImg.svg");
    }
    .payuImg {
      @include imageSet(40px, 24px, "../../assets/images/payuIcon.svg");
    }
  }
}
.payInfo__creditInformation {
  border: solid #102c3a 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-top: unset;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 10px;
  gap: 16px;
  height: 1px;
  justify-content: flex-start;
  height: fit-content;
  border-top: unset;
  margin: 0px;
  width: 100%;
  @media (min-width: 768px) and (max-width: 1020px) {
    padding-bottom: 38px;
    max-width: 686px;
  }
  @media (min-width: 1024px) {
    gap: 12px;
    padding: 20px 26px;
    padding-top: 15px;
  }
  h1 {
    width: 100%;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    color: #102c3a;
    @media (min-width: 768px) and (max-width: 1020px) {
      padding: 5px 0;
    }
  }
  .inputContainer {
    width: 40%;
    z-index: 39;
    position: relative;
    @media (min-width: 768px) and (max-width: 1020px) {
      width: 30%;
    }
    @media (min-width: 1024px) {
      width: 41%;
    }
    @media (min-width: 1280px) {
      width: 39%;
    }
    & > span {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  input {
    width: 100%;
    font-size: 12px;
    border-radius: 5px;
    outline: unset;
    border: solid 1px #444b57;
    min-height: 30px;
    text-indent: 10px;
    &:nth-child(1):not(.phoneInput) {
      width: 100%;
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 65%;
      }
      @media (min-width: 1024px) {
        width: 50%;
      }
    }
    &:nth-child(3) {
      width: 54%;
      @media screen and (max-width: 600px) {
        width: 25%;
        flex: 1;
      }
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 25%;
      }
      @media (min-width: 1024px) and (max-width: 1279px) {
        width: 46%;
        flex: 1;
      }
      @media (min-width: 1280px) {
        flex: 1;
      }
    }
    &:nth-child(4) {
      width: 100%;
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 40%;
        flex: 0.92;
      }
      @media (min-width: 1024px) {
        width: 25%;
      }
      @media (min-width: 1254px) {
        width: 27%;
      }
    }
    &:nth-child(7) {
      width: 100%;
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 31%;
        flex: 1;
        &.cvvHidden {
          width: 48%;
        }
      }
      @media (min-width: 1024px) {
        width: 30%;
      }
      @media (min-width: 1280px) {
        width: 33%;
        &.cvvHidden {
          flex: 1;
        }
      }
      @media (min-width: 1600px) {
        width: 47.3%;
        &.cvvHidden {
          width: 66%;
        }
      }
    }
  }
  .cvvContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: space-between;
    input {
      text-indent: unset;
      text-align: center;
    }
    @media (max-width: 600px) {
      width: 14.5%;
    }
    @media (min-width: 1280px) {
      width: 10%;
    }
    @media (min-width: 900px) and (max-width: 1100px) {
      width: 8%;
    }
    #securityCode {
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1020px) {
      width: 10%;
    }

    @media (min-width: 1600px) {
      width: 8%;
    }
    p {
      color: #444b57;
      font-weight: 400;
      font-size: 12px;
      max-width: 25px;
    }
    input {
      width: 70%;
    }
  }
  .mesContainer {
    width: 21%;
    @media (min-width: 768px) and (max-width: 1020px) {
      width: 12%;
    }
    @media (min-width: 950px) and (max-width: 1100px) {
      width: 11%;
    }
    @media (min-width: 1280px) {
      width: 12%;
    }
    @media (min-width: 1600px) {
      width: 12%;
    }
  }
  .anoContainer {
    position: relative;
    width: 23%;
    @media (min-width: 768px) {
      right: unset;
    }
    @media (min-width: 768px) and (max-width: 1020px) {
      width: 12%;
    }
    @media (min-width: 968px) and (max-width: 1100px) {
      width: 11%;
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1280px) {
      width: 12%;
      right: 0;
    }
    &.cvvHidden {
      right: initial;
    }
  }
  .selectorContentDocoument {
    width: 40%;
    @media screen and (max-width: 600px) {
      width: 48%;
    }
    @media (min-width: 768px) and (max-width: 1020px) {
      width: 26%;
      flex: 1;
    }
    @media (min-width: 1024px) {
      width: 22%;
    }
    @media (min-width: 1224px) {
      width: 22%;
    }
  }
  .NoCuotasContainer {
    position: relative;
    width: 27.1%;
    justify-self: start;
    @media (max-width: 600px) {
      flex: 1;
    }
    @media (min-width: 768px) {
      right: unset;
    }
    @media (min-width: 768px) and (max-width: 1020px) {
      position: initial;
      width: 20%;
      right: unset;
    }
    @media (min-width: 1024px) {
      width: 17%;
      &.cvvHidden {
      }
    }
    @media (min-width: 1260px) {
      right: unset;
      width: 16.5%;
      &.cvvHidden {
        right: unset;
      }
    }
    @media (min-width: 1600px) {
      width: 14%;
    }
  }
}
button {
  background: #102c3a62;
  border-radius: 6px;
  width: 416px;
  color: white;
  height: 30px;
  margin: auto;
  border: unset;
  cursor: not-allowed;
}

.readyToSubmit {
  background: #102c3a;
  cursor: pointer;
}

.phoneInput {
  width: 26%;
  @media screen and (max-width: 1100px) {
    width: 24%;
  }
  @media screen and (max-width: 900px) {
    width: 29%;
  }
  @media screen and (max-width: 600px) {
    width: 53%;
  }
}

.securePay{
  p{
    font-size: 10px;
  }
}
</style>
